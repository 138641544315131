import type { DefaultTheme } from 'styled-components';
import { ThemedText } from 'theme/components';

import type { SubmittableTrade } from 'state/routing/types';
import { QuoteMethod } from 'state/routing/types';

export default function RouterLabel({
  trade,
  color
}: {
  trade: SubmittableTrade;
  color?: keyof DefaultTheme;
}) {
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return <ThemedText.BodySmall color={color}>Client</ThemedText.BodySmall>;
  }

  return <ThemedText.BodySmall color={color}>API</ThemedText.BodySmall>;
}

import type { ComponentProps } from 'react';

export const ChevronDiagonalIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='13'
    viewBox='0 0 12 13'
    fill='none'
    {...props}
  >
    <path
      d='M10.5 2H1.5V11'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import type { Activity } from './types';
import {
  getYear,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear
} from 'date-fns';

import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';

interface ActivityGroup {
  title: string;
  transactions: Array<Activity>;
}

const sortActivities = (a: Activity, b: Activity) => b.timestamp - a.timestamp;

export const createGroups = (activities?: Array<Activity>) => {
  if (!activities) return undefined;
  const now = Date.now();

  const pending: Array<Activity> = [];
  const today: Array<Activity> = [];
  const currentWeek: Array<Activity> = [];
  const last30Days: Array<Activity> = [];
  const currentYear: Array<Activity> = [];
  const yearMap: { [key: string]: Array<Activity> } = {};

  // TODO(cartcrom): create different time bucket system for activities to fall in based on design wants
  activities.forEach((activity) => {
    if (activity.status === TransactionStatus.Pending) {
      pending.push(activity);
      return;
    }
    const addedTime = activity.timestamp * 1000;

    if (isSameDay(now, addedTime)) {
      today.push(activity);
    } else if (isSameWeek(addedTime, now)) {
      currentWeek.push(activity);
    } else if (isSameMonth(addedTime, now)) {
      last30Days.push(activity);
    } else if (isSameYear(addedTime, now)) {
      currentYear.push(activity);
    } else {
      const year = getYear(addedTime);

      if (!yearMap[year]) {
        yearMap[year] = [activity];
      } else {
        yearMap[year].push(activity);
      }
    }
  });
  const sortedYears = Object.keys(yearMap)
    .sort((a, b) => parseInt(b) - parseInt(a))
    .map((year) => ({ title: year, transactions: yearMap[year] }));

  const transactionGroups: Array<ActivityGroup> = [
    { title: 'Pending', transactions: pending.sort(sortActivities) },
    { title: 'Today', transactions: today.sort(sortActivities) },
    { title: 'This week', transactions: currentWeek.sort(sortActivities) },
    { title: 'This month', transactions: last30Days.sort(sortActivities) },
    { title: 'This year', transactions: currentYear.sort(sortActivities) },
    ...sortedYears
  ];

  return transactionGroups.filter(
    (transactionInformation) => transactionInformation.transactions.length > 0
  );
};

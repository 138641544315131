import { useWeb3React } from './useWeb3React';
import { NEBY_STAKER_ADDRESSES } from '@uniswap/sdk-core';
import { DEFAULT_CHAIN_ID } from 'constants/chains';

export const useNebyStakerAddress = () => {
  const { chainId } = useWeb3React();

  const usableChainId = chainId || DEFAULT_CHAIN_ID;
  const nebyStakerAddress =
    NEBY_STAKER_ADDRESSES[usableChainId] ||
    NEBY_STAKER_ADDRESSES[DEFAULT_CHAIN_ID];

  return nebyStakerAddress;
};

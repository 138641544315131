import { cn } from 'utils/cn';

const Background = () => (
  <div
    className={cn(
      'bg-global bg-cover bg-center bg-no-repeat 2xl:bg-global',
      'fixed left-0 top-0 z-1 h-full w-full',
      'before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-stars',
      'before:bg-cover before:bg-center before:bg-no-repeat before:opacity-60 before:mix-blend-plus-lighter'
    )}
  />
);

export default Background;

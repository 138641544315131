import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAtomValue } from 'jotai/utils';
import { isSupportedChain } from 'utils/chains';
import { NumberType, useFormatter } from 'utils/formatNumbers';
import { splitHiddenTokens } from 'utils/splitHiddenTokens';

import type { PortfolioTokenBalancePartsFragment } from 'graphql/data/__generated__/types-and-hooks';

import { PrimaryLargeText, SecondaryLargeText } from 'components/ui/Text';

import { useToggleAccountDrawer } from 'components/AccountDrawer';
import { EmptyWalletModule } from 'components/AccountDrawer/MiniPortfolio/EmptyWalletModule';
import { ExpandoRow } from 'components/AccountDrawer/MiniPortfolio/ExpandoRow';
import {
  PortfolioLogo,
  PortfolioLogoSize
} from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo';
import PortfolioRow, {
  PortfolioSkeleton
} from 'components/AccountDrawer/MiniPortfolio/PortfolioRow';
import { hideSmallBalancesAtom } from 'components/AccountDrawer/SmallBalanceToggle';
import { hideSpamAtom } from 'components/AccountDrawer/SpamToggle';
import { useCachedPortfolioBalancesQuery } from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta';

import type { PortfolioToken } from 'graphql/data/portfolios';
import { getTokenDetailsURL, gqlToCurrency } from 'graphql/data/util';

const Tokens = ({ account }: { account: string }) => {
  const toggleWalletDrawer = useToggleAccountDrawer();
  const hideSmallBalances = useAtomValue(hideSmallBalancesAtom);
  const hideSpam = useAtomValue(hideSpamAtom);
  const [showHiddenTokens, setShowHiddenTokens] = useState(false);

  const { data } = useCachedPortfolioBalancesQuery({ account });

  const tokenBalances = data?.portfolios?.[0].tokenBalances;

  const supportedTokens = tokenBalances?.filter((tokenBalance) => {
    if (tokenBalance.token) {
      const currency = gqlToCurrency(tokenBalance.token);
      if (currency) {
        const isSupported = isSupportedChain(currency.chainId);
        return isSupported;
      }
    }

    return false;
  });

  const { visibleTokens, hiddenTokens } = useMemo(
    () =>
      splitHiddenTokens(supportedTokens ?? [], { hideSmallBalances, hideSpam }),
    [hideSmallBalances, supportedTokens, hideSpam]
  );

  if (!data) {
    return <PortfolioSkeleton />;
  }

  if (supportedTokens?.length === 0) {
    return (
      <EmptyWalletModule type='token' onNavigateClick={toggleWalletDrawer} />
    );
  }

  const toggleHiddenTokens = () =>
    setShowHiddenTokens((showHiddenTokens) => !showHiddenTokens);

  return (
    <div className='animate-fade-in transition duration-300'>
      {visibleTokens.map(
        (tokenBalance) =>
          tokenBalance.token && (
            <TokenRow
              key={tokenBalance.id}
              {...tokenBalance}
              token={tokenBalance.token}
            />
          )
      )}
      <ExpandoRow
        isExpanded={showHiddenTokens}
        toggle={toggleHiddenTokens}
        numItems={hiddenTokens.length}
      >
        {hiddenTokens.map(
          (tokenBalance) =>
            tokenBalance.token && (
              <TokenRow
                key={tokenBalance.id}
                {...tokenBalance}
                token={tokenBalance.token}
              />
            )
        )}
      </ExpandoRow>
    </div>
  );
};

export default Tokens;

const TokenRow = ({
  token,
  quantity,
  denominatedValue,
  tokenProjectMarket
}: PortfolioTokenBalancePartsFragment & { token: PortfolioToken }) => {
  const { formatDelta } = useFormatter();
  const percentChange = tokenProjectMarket?.pricePercentChange?.value ?? 0;

  const navigate = useNavigate();
  const toggleWalletDrawer = useToggleAccountDrawer();

  const navigateToTokenDetails = useCallback(async () => {
    navigate(getTokenDetailsURL({ ...token }));
    toggleWalletDrawer();
  }, [navigate, token, toggleWalletDrawer]);
  const { formatNumber } = useFormatter();

  const currency = gqlToCurrency(token);
  if (!currency) {
    return null;
  }

  return (
    <PortfolioRow
      left={
        <PortfolioLogo
          chainId={currency.chainId}
          currencies={[currency]}
          size={PortfolioLogoSize.xl}
        />
      }
      title={
        <PrimaryLargeText className='overflow-hidden text-ellipsis whitespace-nowrap'>
          {token?.name}
        </PrimaryLargeText>
      }
      descriptor={
        <SecondaryLargeText className='overflow-hidden text-ellipsis whitespace-nowrap'>
          {formatNumber({
            input: quantity,
            type: NumberType.TokenNonTx
          })}{' '}
          {token?.symbol}
        </SecondaryLargeText>
      }
      onClick={navigateToTokenDetails}
      right={
        denominatedValue && (
          <>
            <PrimaryLargeText>
              {formatNumber({
                input: denominatedValue?.value,
                type: NumberType.PortfolioBalance
              })}
            </PrimaryLargeText>
            <div className='flex w-full items-center justify-end'>
              <DeltaArrow delta={percentChange} />
              <SecondaryLargeText>
                {formatDelta(percentChange)}
              </SecondaryLargeText>
            </div>
          </>
        )
      }
    />
  );
};

import { cn } from 'utils/cn';

import { PrimaryLargeText } from 'components/ui/Text';

import ArrowIcon from 'components/Icons/ArrowIcon';

export const SlideOutMenu = ({
  children,
  onClose,
  title
}: {
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  onClear?: () => void;
}) => (
  <div className='custom-scrollbar flex h-full w-full flex-col gap-4 overflow-auto p-4'>
    <div className='relative flex w-full justify-between text-gray-600'>
      <ArrowIcon
        onClick={onClose}
        className={cn(
          'size-6 rotate-180 fill-gray-600',
          'transition-colors duration-300',
          'cursor-pointer hover:fill-gray-600/80'
        )}
      />

      <span className='absolute left-1/2 -translate-x-1/2'>
        <PrimaryLargeText>{title}</PrimaryLargeText>
      </span>
    </div>

    {children}
  </div>
);

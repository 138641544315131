import type { ComponentProps } from 'react';

export const TransactionFailedIcon = (props: ComponentProps<'svg'>) => (
  <svg
    viewBox='0 0 52 52'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.1211 2H2.12109V11'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M41.1211 2H50.1211V11'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.1211 50H2.12109V41'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M41.1211 50H50.1211V41'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M36.1211 39V36L26.1211 26L16.1211 36V39'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M36.1211 13V16L26.1211 26L16.1211 16V13'
      stroke='#FF6E75'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

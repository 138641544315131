import type { ComponentProps } from 'react';

export const SwapIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6 1V13M6 13L1 7.31579V6M6 13L11 7.31579V6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import type { ComponentProps } from 'react';

export const MinusIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='3'
    viewBox='0 0 14 3'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.857117 1.14294C0.857117 0.59066 1.30483 0.142944 1.85712 0.142944H12.1429C12.6952 0.142944 13.1429 0.59066 13.1429 1.14294C13.1429 1.69523 12.6952 2.14294 12.1429 2.14294H1.85712C1.30483 2.14294 0.857117 1.69523 0.857117 1.14294Z'
    />
  </svg>
);

import { GitVersionRow } from './GitVersionRow';
import { SlideOutMenu } from './SlideOutMenu';
import { SmallBalanceToggle } from './SmallBalanceToggle';
import { SpamToggle } from './SpamToggle';
import { TestnetsToggle } from './TestnetsToggle';

const SettingsMenu = ({ onClose }: { onClose: () => void }) => (
  <SlideOutMenu title='Settings' onClose={onClose}>
    <div className='flex h-full flex-col justify-between'>
      <div className='flex flex-col gap-4'>
        <span className='text-base font-medium text-gray-600'>Preferences</span>

        <div className='flex flex-col gap-4'>
          <SmallBalanceToggle />
          <SpamToggle />
          <TestnetsToggle />
        </div>
      </div>

      <GitVersionRow />
    </div>
  </SlideOutMenu>
);
SettingsMenu.displayName = 'SettingsMenu';

export default SettingsMenu;

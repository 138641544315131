import { ActivationStatus, useActivationState } from 'connection/activate';
import type { Connection } from 'connection/types';

import { cn } from 'utils/cn';

import { useWeb3React } from 'hooks/useWeb3React';

import { Badge } from 'components/ui/Badge';
import { PrimaryLargeText } from 'components/ui/Text';

import { useToggleAccountDrawer } from 'components/AccountDrawer';
import Spinner from 'components/Icons/Spinner';

type OptionProps = {
  connection: Connection;
  isRecent?: boolean;
};

const Option = ({ connection, isRecent }: OptionProps) => {
  const { activationState, tryActivation } = useActivationState();
  const toggleAccountDrawer = useToggleAccountDrawer();
  const { chainId } = useWeb3React();

  const { name, icon } = connection.getProviderInfo();

  const isSomeOptionPending =
    activationState.status === ActivationStatus.PENDING;
  const isCurrentOptionPending =
    isSomeOptionPending && activationState.connection === connection;

  const rightSideDetail = isCurrentOptionPending ? (
    <Spinner className='size-6 stroke-blue-200 duration-1000' />
  ) : isRecent ? (
    <Badge>Recent</Badge>
  ) : null;

  const allowOptionClick = !isSomeOptionPending || isCurrentOptionPending;

  return (
    <div
      className={cn(
        'relative flex w-full flex-row items-stretch justify-between bg-blue-800',
        {
          'cursor-pointer hover:bg-blue-800/70 focus:bg-blue-800/90':
            !isSomeOptionPending
        }
      )}
    >
      <div
        className={cn(
          'flex flex-auto cursor-pointer flex-row items-center justify-between p-4',
          {
            'opacity-50': !allowOptionClick,
            'opacity-100': allowOptionClick
          }
        )}
        onClick={() => {
          if (allowOptionClick) {
            tryActivation(connection, toggleAccountDrawer, chainId);
          }
        }}
      >
        <div className='flex flex-row items-center'>
          <div className='flex flex-row items-center justify-center'>
            <img src={icon} alt={name} className='size-10 rounded-xl' />
          </div>

          <PrimaryLargeText className='flex flex-row items-center justify-center px-2 py-0 font-bold'>
            {name}
          </PrimaryLargeText>
        </div>
        {rightSideDetail}
      </div>
    </div>
  );
};

export default Option;

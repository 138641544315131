import { useWeb3React } from './useWeb3React';
import { STAKER_ADDRESSES } from '@uniswap/sdk-core';
import { DEFAULT_CHAIN_ID } from 'constants/chains';

export const useStakerAddress = () => {
  const { chainId } = useWeb3React();

  const usableChainId = chainId || DEFAULT_CHAIN_ID;
  const stakerAddress =
    STAKER_ADDRESSES[usableChainId] || STAKER_ADDRESSES[DEFAULT_CHAIN_ID];

  return stakerAddress;
};

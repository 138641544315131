import type { ChainId, SupportedChainsType } from '@uniswap/sdk-core';
import type { Connector } from '@web3-react/types';
import {
  deprecatedNetworkConnection,
  networkConnection,
  uniwalletWCV2ConnectConnection,
  walletConnectV2Connection
} from 'connection';
import { getChainInfo } from 'constants/chainInfo';
import { RPC_URLS } from 'constants/networks';
import { useCallback } from 'react';

import { isSupportedChain } from 'utils/chains';

import { useAppDispatch } from 'state/hooks';

import { endSwitchingChain, startSwitchingChain } from 'state/wallets/reducer';

const getRpcUrl = (chainId: SupportedChainsType): string =>
  RPC_URLS[chainId][0];

export const useSwitchChain = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (connector: Connector, chainId: ChainId) => {
      if (!isSupportedChain(chainId)) {
        throw new Error(
          `Chain ${chainId} not supported for connector (${typeof connector})`
        );
      } else {
        dispatch(startSwitchingChain(chainId));
        try {
          if (
            [
              walletConnectV2Connection.connector,
              uniwalletWCV2ConnectConnection.connector,
              networkConnection.connector,
              deprecatedNetworkConnection.connector
            ].includes(connector)
          ) {
            await connector.activate(chainId);
          } else {
            const info = getChainInfo(chainId);
            const addChainParameter = {
              chainId,
              chainName: info.label,
              rpcUrls: [getRpcUrl(chainId)],
              nativeCurrency: info.nativeCurrency,
              blockExplorerUrls: [info.explorer]
            };
            await connector.activate(addChainParameter);
          }
        } catch (error) {
          // In activating a new chain, the connector passes through a deactivated state.
          // If we fail to switch chains, it may remain in this state, and no longer be usable.
          // We defensively re-activate the connector to ensure the user does not notice any change.
          try {
            await connector.activate();
          } catch (error) {
            console.error('Failed to re-activate connector', error);
          }
          throw error;
        } finally {
          dispatch(endSwitchingChain());
        }
      }
    },
    [dispatch]
  );
};

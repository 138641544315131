import { CancelledTransactionTitleTable, getActivityTitle } from '../constants';
import type { Activity, ActivityMap } from './types';
import { BigNumber } from '@ethersproject/bignumber';
import {
  type ChainId,
  type Currency,
  CurrencyAmount,
  type Token,
  TradeType
} from '@uniswap/sdk-core';
import { nativeOnChain } from 'constants/tokens';
import { useMemo } from 'react';

import { NumberType, useFormatter } from 'utils/formatNumbers';

import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';
import { type ChainTokenMap, useAllTokensMultichain } from 'hooks/Tokens';
import { useMultichainTransactions } from 'state/transactions/hooks';

import {
  type AddLiquidityTransactionInfo,
  type ApproveTransactionInfo,
  type CollectFeesTransactionInfo,
  type ExactInputSwapTransactionInfo,
  type ExactOutputSwapTransactionInfo,
  type RemoveLiquidityTransactionInfo,
  type StakeTransactionInfo,
  type TransactionDetails,
  TransactionType,
  type WrapTransactionInfo
} from 'state/transactions/types';

type FormatNumberFunctionType = ReturnType<typeof useFormatter>['formatNumber'];

const getCurrency = (
  currencyId: string,
  chainId: ChainId,
  tokens: ChainTokenMap
): Currency | undefined =>
  currencyId === 'ETH' ? nativeOnChain(chainId) : tokens[chainId]?.[currencyId];

const buildCurrencyDescriptor = (
  currencyA: Currency | undefined,
  amtA: string,
  currencyB: Currency | undefined,
  amtB: string,
  formatNumber: FormatNumberFunctionType,
  delimiter = 'for'
) => {
  const formattedA = currencyA
    ? formatNumber({
        input: parseFloat(
          CurrencyAmount.fromRawAmount(currencyA, amtA).toSignificant()
        ),
        type: NumberType.TokenNonTx
      })
    : 'Unknown';
  const symbolA = currencyA?.symbol ?? '';
  const formattedB = currencyB
    ? formatNumber({
        input: parseFloat(
          CurrencyAmount.fromRawAmount(currencyB, amtB).toSignificant()
        ),
        type: NumberType.TokenNonTx
      })
    : 'Unknown';
  const symbolB = currencyB?.symbol ?? '';
  return [formattedA, symbolA, delimiter, formattedB, symbolB]
    .filter(Boolean)
    .join(' ');
};

const parseSwap = (
  swap: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo,
  chainId: ChainId,
  tokens: ChainTokenMap,
  formatNumber: FormatNumberFunctionType
): Partial<Activity> => {
  const tokenIn = getCurrency(swap.inputCurrencyId, chainId, tokens);
  const tokenOut = getCurrency(swap.outputCurrencyId, chainId, tokens);
  const [inputRaw, outputRaw] =
    swap.tradeType === TradeType.EXACT_INPUT
      ? [
          swap.inputCurrencyAmountRaw,
          swap.settledOutputCurrencyAmountRaw ??
            swap.expectedOutputCurrencyAmountRaw
        ]
      : [swap.expectedInputCurrencyAmountRaw, swap.outputCurrencyAmountRaw];

  return {
    descriptor: buildCurrencyDescriptor(
      tokenIn,
      inputRaw,
      tokenOut,
      outputRaw,
      formatNumber,
      undefined
    ),
    currencies: [tokenIn, tokenOut],
    prefixIconSrc: undefined
  };
};

const parseWrap = (
  wrap: WrapTransactionInfo,
  chainId: ChainId,
  status: TransactionStatus,
  formatNumber: FormatNumberFunctionType
): Partial<Activity> => {
  const native = nativeOnChain(chainId);
  const wrapped = native.wrapped;
  const [input, output] = wrap.unwrapped
    ? [wrapped, native]
    : [native, wrapped];

  const descriptor = buildCurrencyDescriptor(
    input,
    wrap.currencyAmountRaw,
    output,
    wrap.currencyAmountRaw,
    formatNumber
  );
  const title = getActivityTitle(TransactionType.WRAP, status, wrap.unwrapped);
  const currencies = wrap.unwrapped ? [wrapped, native] : [native, wrapped];

  return { title, descriptor, currencies };
};

const parseApproval = (
  approval: ApproveTransactionInfo,
  chainId: ChainId,
  tokens: ChainTokenMap,
  status: TransactionStatus
): Partial<Activity> => {
  const currency = getCurrency(approval.tokenAddress, chainId, tokens);
  const descriptor = currency?.symbol ?? currency?.name ?? 'Unknown';
  return {
    title: getActivityTitle(
      TransactionType.APPROVAL,
      status,
      BigNumber.from(approval.amount).eq(0) /* use alternate if it's a revoke */
    ),
    descriptor,
    currencies: [currency]
  };
};

const parseStake = (
  info: StakeTransactionInfo,
  tokens: ChainTokenMap,
  status: TransactionStatus,
  platformToken: Token
): Partial<Activity> => {
  const currency = getCurrency(
    info.tokenAddress,
    platformToken.chainId,
    tokens
  );
  const descriptor = currency?.symbol ?? currency?.name ?? 'Unknown';

  return {
    title: getActivityTitle(
      TransactionType.STAKE,
      status,
      BigNumber.from(info.amount).eq(0)
    ),
    descriptor,
    currencies: [currency]
  };
};

type GenericLPInfo = Omit<
  AddLiquidityTransactionInfo | RemoveLiquidityTransactionInfo,
  'type'
>;
const parseLP = (
  lp: GenericLPInfo,
  chainId: ChainId,
  tokens: ChainTokenMap,
  formatNumber: FormatNumberFunctionType
): Partial<Activity> => {
  const baseCurrency = getCurrency(lp.baseCurrencyId, chainId, tokens);
  const quoteCurrency = getCurrency(lp.quoteCurrencyId, chainId, tokens);
  const [baseRaw, quoteRaw] = [
    lp.expectedAmountBaseRaw,
    lp.expectedAmountQuoteRaw
  ];
  const descriptor = buildCurrencyDescriptor(
    baseCurrency,
    baseRaw,
    quoteCurrency,
    quoteRaw,
    formatNumber,
    'and'
  );

  return { descriptor, currencies: [baseCurrency, quoteCurrency] };
};

const parseCollectFees = (
  collect: CollectFeesTransactionInfo,
  chainId: ChainId,
  tokens: ChainTokenMap,
  formatNumber: FormatNumberFunctionType
): Partial<Activity> => {
  // Adapts CollectFeesTransactionInfo to generic LP type
  const {
    currencyId0: baseCurrencyId,
    currencyId1: quoteCurrencyId,
    expectedCurrencyOwed0: expectedAmountBaseRaw,
    expectedCurrencyOwed1: expectedAmountQuoteRaw
  } = collect;
  return parseLP(
    {
      baseCurrencyId,
      quoteCurrencyId,
      expectedAmountBaseRaw,
      expectedAmountQuoteRaw
    },
    chainId,
    tokens,
    formatNumber
  );
};

export const getTransactionStatus = (
  details: TransactionDetails
): TransactionStatus =>
  !details.receipt
    ? TransactionStatus.Pending
    : details.receipt.status === 1 || details.receipt?.status === undefined
      ? TransactionStatus.Confirmed
      : TransactionStatus.Failed;

export const transactionToActivity = (
  details: TransactionDetails,
  chainId: ChainId,
  tokens: ChainTokenMap,
  formatNumber: FormatNumberFunctionType,
  platformToken?: Token
): Activity | undefined => {
  try {
    const status = getTransactionStatus(details);

    const defaultFields = {
      hash: details.hash,
      chainId,
      title: getActivityTitle(details.info.type, status),
      status,
      timestamp: (details.confirmedTime ?? details.addedTime) / 1000,
      from: details.from,
      nonce: details.nonce,
      cancelled: details.cancelled
    };

    let additionalFields: Partial<Activity> = {};
    const info = details.info;
    if (info.type === TransactionType.SWAP) {
      additionalFields = parseSwap(info, chainId, tokens, formatNumber);
    } else if (info.type === TransactionType.STAKE && platformToken) {
      additionalFields = parseStake(info, tokens, status, platformToken);
    } else if (info.type === TransactionType.APPROVAL) {
      additionalFields = parseApproval(info, chainId, tokens, status);
    } else if (info.type === TransactionType.WRAP) {
      additionalFields = parseWrap(info, chainId, status, formatNumber);
    } else if (
      info.type === TransactionType.ADD_LIQUIDITY ||
      info.type === TransactionType.REMOVE_LIQUIDITY
    ) {
      additionalFields = parseLP(info, chainId, tokens, formatNumber);
    } else if (info.type === TransactionType.COLLECT_FEES) {
      additionalFields = parseCollectFees(info, chainId, tokens, formatNumber);
    }

    const activity = { ...defaultFields, ...additionalFields };

    if (details.cancelled) {
      activity.title = CancelledTransactionTitleTable[details.info.type];
      activity.status = TransactionStatus.Confirmed;
    }

    return activity;
  } catch (error) {
    console.debug(`Failed to parse transaction ${details.hash}`, error);
    return undefined;
  }
};

export const useLocalActivities = (account: string): ActivityMap => {
  const allTransactions = useMultichainTransactions();
  const tokens = useAllTokensMultichain();
  const { formatNumber } = useFormatter();

  return useMemo(() => {
    const activityMap: ActivityMap = {};
    for (const [transaction, chainId] of allTransactions) {
      if (transaction.from !== account) continue;

      const activity = transactionToActivity(
        transaction,
        chainId,
        tokens,
        formatNumber
      );
      if (activity) activityMap[transaction.hash] = activity;
    }

    return activityMap;
  }, [account, allTransactions, formatNumber, tokens]);
};

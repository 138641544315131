import {
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import type { Currency } from '@uniswap/sdk-core';
import {
  ChainId,
  Ether,
  NativeCurrency,
  Token,
  WETH9
} from '@uniswap/sdk-core';
import NEBY_TOKEN_LOGO from 'assets/images/neby-token-logo.png';
import invariant from 'tiny-invariant';

export const NATIVE_CHAIN_ID = 'NATIVE';

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18;

export const USDC_MAINNET = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
);
export const USDC_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x6C514B8B9667ff486245c0f5a2b7EBabd5226A6F',
  6,
  'USDC',
  'USD//C'
);
export const USDC_OASIS_SAPPHIRE_TESTNET = new Token(
  ChainId.OASIS_SAPPHIRE_TESTNET,
  '0x3b00685d919C515A7BC2A6909a85e877cD217Cd1',
  6,
  'USDC',
  'USD//C'
);
export const USDC_OASIS_SAPPHIRE_MAINNET = new Token(
  ChainId.OASIS_SAPPHIRE_MAINNET,
  '0x97eec1c29f745dC7c267F90292AA663d997a601D',
  6,
  'USDC',
  'USD//C'
);

export const USDT = new Token(
  ChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
);

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } =
  {
    ...(WETH9 as Record<ChainId, Token>),
    [ChainId.SEPOLIA]: new Token(
      ChainId.SEPOLIA,
      Sepolia.weth9.address,
      Sepolia.weth9.decimals,
      Sepolia.weth9.symbol,
      Sepolia.weth9.name
    ),
    [ChainId.OASIS_SAPPHIRE_MAINNET]: new Token(
      ChainId.OASIS_SAPPHIRE_MAINNET,
      OasisSapphireMainnet.weth9.address,
      OasisSapphireMainnet.weth9.decimals,
      OasisSapphireMainnet.weth9.symbol,
      OasisSapphireMainnet.weth9.name
    ),
    [ChainId.OASIS_SAPPHIRE_TESTNET]: new Token(
      ChainId.OASIS_SAPPHIRE_TESTNET,
      OasisSapphireTestnet.weth9.address,
      OasisSapphireTestnet.weth9.decimals,
      OasisSapphireTestnet.weth9.symbol,
      OasisSapphireTestnet.weth9.name
    )
  };

export function isOasisNetwork(
  chainId: number
): chainId is ChainId.OASIS_SAPPHIRE_MAINNET | ChainId.OASIS_SAPPHIRE_TESTNET {
  return (
    chainId === ChainId.OASIS_SAPPHIRE_MAINNET ||
    chainId === ChainId.OASIS_SAPPHIRE_TESTNET
  );
}

class OasisNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  get wrapped(): Token {
    if (!isOasisNetwork(this.chainId)) throw new Error('Not oasis');
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    invariant(wrapped instanceof Token);
    return wrapped;
  }

  public constructor(chainId: number) {
    if (!isOasisNetwork(chainId)) throw new Error('Not oasis');
    super(chainId, 18, 'ROSE', 'Oasis Network');
  }
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    if (wrapped) return wrapped;
    throw new Error(`Unsupported chain ID: ${this.chainId}`);
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } =
    {};

  public static onChain(chainId: number): ExtendedEther {
    return (
      this._cachedExtendedEther[chainId] ??
      (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
    );
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {};
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId];
  let nativeCurrency: NativeCurrency | Token;
  if (isOasisNetwork(chainId)) {
    nativeCurrency = new OasisNativeCurrency(chainId);
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId);
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency);
}

export const TOKEN_SHORTHANDS: {
  [shorthand: string]: { [chainId in ChainId]?: string };
} = {
  USDC: {
    [ChainId.OASIS_SAPPHIRE_MAINNET]: USDC_OASIS_SAPPHIRE_MAINNET.address,
    [ChainId.OASIS_SAPPHIRE_TESTNET]: USDC_OASIS_SAPPHIRE_TESTNET.address,
    [ChainId.MAINNET]: USDC_MAINNET.address,
    [ChainId.SEPOLIA]: USDC_SEPOLIA.address
  }
};

export const tokenSymbolMap: {
  [chainId: number]: { [symbol: string]: string };
} = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: {
    WROSE: OasisSapphireMainnet.weth9.symbol
  },
  [ChainId.OASIS_SAPPHIRE_TESTNET]: {
    WROSE: OasisSapphireTestnet.weth9.symbol
  }
};

export const getTokenSymbol = (
  chainId: number,
  symbol: string
): string | undefined => {
  const normalizedSymbol = symbol.toUpperCase();
  return tokenSymbolMap[chainId]?.[normalizedSymbol];
};

export const platformTokens = {
  testnet: new Token(
    ChainId.OASIS_SAPPHIRE_TESTNET,
    '0x4cc8c97ece381B6fD56B891Fc1f4b67b04A8D7F4',
    18,
    'Neby',
    'NEBY'
  ),
  mainnet: new Token(
    ChainId.OASIS_SAPPHIRE_MAINNET,
    '0x932Dd32885eeE8dCCDB8E0475Cf2e9bfc8527f9D',
    18,
    'Neby',
    'NEBY'
  )
};

export const platformTokenLogo = NEBY_TOKEN_LOGO;

import { useWeb3React } from './useWeb3React';
import { HARVESTER_ADDRESSES } from '@uniswap/sdk-core';
import { DEFAULT_CHAIN_ID } from 'constants/chains';

export const useHarvesterAddress = () => {
  const { chainId } = useWeb3React();

  const usableChainId = chainId || DEFAULT_CHAIN_ID;
  const harvesterAddress =
    HARVESTER_ADDRESSES[usableChainId] || HARVESTER_ADDRESSES[DEFAULT_CHAIN_ID];

  return harvesterAddress;
};

import type { SlopeShapeProps } from './SlopeShape.types';

import { Container } from 'components/composed/ContainerShapes/constants';

const SlopeShape = ({
  className,
  container,
  size,
  ...props
}: SlopeShapeProps) => {
  if (size === 'sm') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='10'
        viewBox='0 0 20 10'
        fill='none'
        className={className}
        {...props}
      >
        <path d='M19.1421 0H0V10L0.85791 9.99988C3.51025 9.99988 6.05371 8.94641 7.92871 7.07104L12.0713 2.92896C13.9463 1.05359 16.4897 0 19.1421 0Z' />
      </svg>
    );
  }

  if (container === Container.INNER) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='35'
        height='27'
        viewBox='0 0 35 27'
        fill='none'
        className={className}
        {...props}
      >
        <path d='M0 0V27H35C33.4117 26.9978 31.889 26.3659 30.7657 25.2426L7.28043 1.75736C6.15522 0.632144 4.62909 0 3.03779 0H0Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='38'
      height='27'
      viewBox='0 0 38 27'
      fill='none'
      className={className}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 27H38C35.8955 27 33.8775 26.1571 32.3893 24.6569L11.4206 3.5148C9.18855 1.26431 6.16105 3.05176e-05 3.00449 9.15527e-05L0 0V27Z'
      />
    </svg>
  );
};

export default SlopeShape;

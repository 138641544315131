import { ChainConfig, NetworkType } from './chain.type';

export const Sepolia: ChainConfig = {
  chainId: 11155111,
  network: 'sepolia',
  label: 'Sepolia',
  docs: 'https://docs.etherscan.io/sepolia-etherscan',
  infoLink: '',
  bridge: '',
  status: 'https://status.base.org',
  type: NetworkType.L1,
  explorer: 'https://sepolia.etherscan.io',
  rpcs: [
    'https://rpc.sepolia.dev/',
    'https://rpc.sepolia.org/',
    'https://rpc2.sepolia.org/',
    'https://rpc.sepolia.online/',
    'https://www.sepoliarpc.space/',
    'https://rpc-sepolia.rockx.com/',
    'https://rpc.bordel.wtf/sepolia',
  ],
  subgraph: '',
  blockSubgraph: '',
  harvesterSubgraph: '',
  stakerSubgraph: '',
  nebyStakerSubgraph: '',
  deployedUnixTimestamp: 0,
  feeRecipient: '',
  feePercent: 15,
  addresses: {
    v3CoreFactory: '',
    multicall: '',
    quoter: '',
    v3Migrator: '',
    nonfungiblePositionManager: '',
    tickLens: '',
    swapRouter02: '',
    universalRouter: '',
    permit2: '',
    harvester: '',
    liquidityPositionStaker: '',
    nebyStaker: '',
  },
  nativeCurrency: { name: 'Sepolia ETH', symbol: 'ETH', decimals: 18 },
  weth9: {
    address: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
  },
};

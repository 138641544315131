import { useAtom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';

import SettingsToggle from 'components/composed/SettingsToggle';

export const hideSmallBalancesAtom = atomWithStorage<boolean>(
  'hideSmallBalances',
  true
);

export const SmallBalanceToggle = () => {
  const [hideSmallBalances, updateHideSmallBalances] = useAtom(
    hideSmallBalancesAtom
  );

  return (
    <SettingsToggle
      title='Hide small balances'
      isActive={hideSmallBalances}
      toggle={() => void updateHideSmallBalances((value) => !value)}
    />
  );
};

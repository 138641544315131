import {
  EthereumMainnet,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(
    'REACT_APP_INFURA_KEY must be a defined environment variable'
  );
}

export const RPC_URLS: Record<number, string[]> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: OasisSapphireMainnet.rpcs,
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.rpcs,
  [ChainId.MAINNET]: [
    `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    ...EthereumMainnet.rpcs
  ],
  [ChainId.SEPOLIA]: [
    `https://sepolia.infura.io/v3/${INFURA_KEY}`,
    ...Sepolia.rpcs
  ]
};

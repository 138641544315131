import { CurrencyItem } from './CurrencyItem';
import type { ExtendedCurrency } from './types';
import type { Currency } from '@uniswap/sdk-core';

import Spinner from 'components/Icons/Spinner';

type CurrencyListProps = {
  isLoading: boolean;
  currencies: ExtendedCurrency[];
  onCurrencySelect: (currency: Currency, hasWarning: boolean) => void;
};

export const CurrencyList = ({
  isLoading,
  currencies,
  onCurrencySelect
}: CurrencyListProps) => {
  if (isLoading) {
    return <Spinner className='h-10 w-10 stroke-gray-100' />;
  }

  if (!currencies || !currencies.length) {
    return (
      <span className='text-center text-base font-medium text-white'>
        No results found.
      </span>
    );
  }

  return (
    <div className='max-h-115 h-115 custom-scrollbar flex flex-col gap-4 overflow-y-auto'>
      {currencies.map((currency, index) => (
        <CurrencyItem
          key={index}
          currency={currency}
          onSelect={onCurrencySelect}
        />
      ))}
    </div>
  );
};

import { useAtom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';

import SettingsToggle from 'components/composed/SettingsToggle';

export const showTestnetsAtom = atomWithStorage<boolean>('showTestnets', false);

export const TestnetsToggle = () => {
  const [showTestnets, updateShowTestnets] = useAtom(showTestnetsAtom);

  return (
    <SettingsToggle
      title='Show testnets'
      isActive={showTestnets}
      toggle={() => void updateShowTestnets((value) => !value)}
    />
  );
};

import { LoadingBubble } from 'components/Tokens/loading';

const PortfolioRow = ({
  left,
  title,
  descriptor,
  right,
  onClick
}: {
  left: React.ReactNode;
  title: React.ReactNode;
  descriptor?: React.ReactNode;
  right?: React.ReactNode;
  setIsHover?: (b: boolean) => void;
  onClick?: () => void;
}) => (
  <div
    className='h-17 flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-0 transition-colors duration-300'
    onClick={onClick}
  >
    {left}
    <div className='grid flex-grow auto-rows-auto'>
      {title}
      {descriptor}
    </div>
    {right && (
      <div className='flex flex-col items-end justify-start'>{right}</div>
    )}
  </div>
);

export default PortfolioRow;

const PortfolioSkeletonRow = ({ shrinkRight }: { shrinkRight?: boolean }) => (
  <div className='h-17 flex w-full items-center justify-start gap-3 px-4 py-0 transition-colors duration-300'>
    <LoadingBubble height='40px' width='40px' round />
    <div className='grid flex-grow auto-rows-auto gap-1'>
      <LoadingBubble height='16px' width='60px' delay='300ms' />
      <LoadingBubble height='10px' width='90px' delay='300ms' />
    </div>

    <div className='flex flex-col items-end justify-start gap-1'>
      {shrinkRight ? (
        <LoadingBubble height='12px' width='20px' delay='600ms' />
      ) : (
        <>
          <LoadingBubble height='14px' width='70px' delay='600ms' />
          <LoadingBubble height='14px' width='50px' delay='600ms' />
        </>
      )}
    </div>
  </div>
);

export const PortfolioSkeleton = ({
  shrinkRight = false
}: {
  shrinkRight?: boolean;
}) => (
  <>
    {Array.from({ length: 5 }).map((_, i) => (
      <PortfolioSkeletonRow
        shrinkRight={shrinkRight}
        key={`portfolio loading row${i}`}
      />
    ))}
  </>
);

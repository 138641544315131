import type { ComponentProps } from 'react';

const SlopeSteepShape = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 27 24'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0.00614707V24H27L5.34314 2.34315C3.91697 0.916962 2.00773 0.0848701 0 0.00614707Z'
    />
  </svg>
);

export default SlopeSteepShape;

import type { ComponentProps } from 'react';

export const CloseIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    {...props}
  >
    <path d='M1 1L13 13M13 1L1 13' strokeWidth='2' strokeLinecap='round' />
  </svg>
);

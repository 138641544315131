import {
  addSerializedToken,
  updateUserRouterPreference,
  updateUserSlippageTolerance
} from './reducer';
import { type SerializedToken, SlippageTolerance } from './types';
import { Percent, Token } from '@uniswap/sdk-core';
import JSBI from 'jsbi';
import { useCallback, useMemo } from 'react';
import { UserAddedToken } from 'types/tokens';

import { useWeb3React } from 'hooks/useWeb3React';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import type { RouterPreference } from 'state/routing/types';

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name
  };
}

export function deserializeToken(
  serializedToken: SerializedToken,
  Class: typeof Token = Token
): Token {
  return new Class(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  );
}

export function useRouterPreference(): [
  RouterPreference,
  (routerPreference: RouterPreference) => void
] {
  const dispatch = useAppDispatch();

  const routerPreference = useAppSelector(
    (state) => state.user.userRouterPreference
  );

  const setRouterPreference = useCallback(
    (newRouterPreference: RouterPreference) => {
      dispatch(
        updateUserRouterPreference({
          userRouterPreference: newRouterPreference
        })
      );
    },
    [dispatch]
  );

  return [routerPreference, setRouterPreference];
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): [
  Percent | SlippageTolerance.Auto,
  (slippageTolerance: Percent | SlippageTolerance.Auto) => void
] {
  const userSlippageToleranceRaw = useAppSelector(
    (state) => state.user.userSlippageTolerance
  );

  // TODO(WEB-1985): Keep `userSlippageTolerance` as Percent in Redux store and remove this conversion
  const userSlippageTolerance = useMemo(
    () =>
      userSlippageToleranceRaw === SlippageTolerance.Auto
        ? SlippageTolerance.Auto
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  );

  const dispatch = useAppDispatch();
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance: Percent | SlippageTolerance.Auto) => {
      let value: SlippageTolerance.Auto | number;
      try {
        value =
          userSlippageTolerance === SlippageTolerance.Auto
            ? SlippageTolerance.Auto
            : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient);
      } catch (error) {
        value = SlippageTolerance.Auto;
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value
        })
      );
    },
    [dispatch]
  );

  return [userSlippageTolerance, setUserSlippageTolerance];
}

/**
 *Returns user slippage tolerance, replacing the auto with a default value
 * @param defaultSlippageTolerance the value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(
  defaultSlippageTolerance: Percent
): Percent {
  const [allowedSlippage] = useUserSlippageTolerance();
  return allowedSlippage === SlippageTolerance.Auto
    ? defaultSlippageTolerance
    : allowedSlippage;
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch]
  );
}

function useUserAddedTokensOnChain(
  chainId: number | undefined | null
): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    if (!chainId) return [];
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map((value) =>
          deserializeToken(value, UserAddedToken)
        )
      : [];
    return tokenMap;
  }, [serializedTokensMap, chainId]);
}

export function useUserAddedTokens(): Token[] {
  return useUserAddedTokensOnChain(useWeb3React().chainId);
}

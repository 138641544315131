import type { ComponentProps } from 'react';

export const LockIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M5.33301 7.33333V4.66667C5.33301 3.95942 5.61396 3.28115 6.11406 2.78105C6.61415 2.28095 7.29243 2 7.99967 2C8.70692 2 9.3852 2.28095 9.88529 2.78105C10.3854 3.28115 10.6663 3.95942 10.6663 4.66667V7.33333M3.33301 8.66667C3.33301 8.31304 3.47348 7.97391 3.72353 7.72386C3.97358 7.47381 4.31272 7.33333 4.66634 7.33333H11.333C11.6866 7.33333 12.0258 7.47381 12.2758 7.72386C12.5259 7.97391 12.6663 8.31304 12.6663 8.66667V12.6667C12.6663 13.0203 12.5259 13.3594 12.2758 13.6095C12.0258 13.8595 11.6866 14 11.333 14H4.66634C4.31272 14 3.97358 13.8595 3.72353 13.6095C3.47348 13.3594 3.33301 13.0203 3.33301 12.6667V8.66667ZM7.33301 10.6667C7.33301 10.8435 7.40325 11.013 7.52827 11.1381C7.65329 11.2631 7.82286 11.3333 7.99967 11.3333C8.17649 11.3333 8.34605 11.2631 8.47108 11.1381C8.5961 11.013 8.66634 10.8435 8.66634 10.6667C8.66634 10.4899 8.5961 10.3203 8.47108 10.1953C8.34605 10.0702 8.17649 10 7.99967 10C7.82286 10 7.65329 10.0702 7.52827 10.1953C7.40325 10.3203 7.33301 10.4899 7.33301 10.6667Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import type { ComponentProps } from 'react';

export const DestinationVectorIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='30'
    viewBox='0 0 14 30'
    fill='none'
    {...props}
  >
    <path d='M1 1V23C1 26.3137 3.68629 29 7 29H13' strokeLinecap='square' />
  </svg>
);

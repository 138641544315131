import { CloseIcon } from 'components/Icons';

export const ConfirmationModalContent = ({
  title,
  bottomContent,
  onDismiss,
  topContent,
  headerContent,
  closeIcon = true
}: {
  title: React.ReactNode;
  onDismiss: () => void;
  topContent: () => React.ReactNode;
  bottomContent?: () => React.ReactNode;
  headerContent?: () => React.ReactNode;
  closeIcon?: boolean;
}) => (
  <div className='relative p-4'>
    {closeIcon && (
      <div className='absolute -top-4 right-4 stroke-gray-600'>
        <CloseIcon onClick={onDismiss} />
      </div>
    )}

    <div className='flex flex-col'>
      <div className='flex flex-col gap-1'>
        {headerContent?.()}

        <div className='flex flex-row justify-start text-base font-medium text-white'>
          <span>{title}</span>
        </div>
      </div>

      {topContent()}
    </div>

    {bottomContent && <div>{bottomContent()}</div>}
  </div>
);

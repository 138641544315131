import { Field, resetIncreaseLiquidityState, typeInput } from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface IncreaseLiquidityState {
  readonly independentField: Field;
  readonly typedValue: string;
  readonly leftRangeTypedValue: string;
  readonly rightRangeTypedValue: string;
}

export const initialState: IncreaseLiquidityState = {
  independentField: Field.CURRENCY_A,
  typedValue: '',
  leftRangeTypedValue: '',
  rightRangeTypedValue: ''
};

export default createReducer<IncreaseLiquidityState>(initialState, (builder) =>
  builder
    .addCase(resetIncreaseLiquidityState, () => initialState)
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => ({
      ...state,
      independentField: field,
      typedValue
    }))
);

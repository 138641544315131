import type { DesignSizeProps } from './Container.types';
import { Container } from './constants';

import { cn } from 'utils/cn';

import { CornerShape, SlopeShape } from 'components/ui/shapes';

const OuterInwardDesign = ({ className, size }: DesignSizeProps) => (
  <div className={cn('flex flex-row', className)}>
    <CornerShape container={Container.OUTER} className='fill-blue-800' />

    <div className='h-6.75 flex flex-1 bg-blue-800' />

    <SlopeShape container={Container.OUTER} className='fill-blue-800' />

    <div className={cn('flex', size)} />

    <SlopeShape
      container={Container.OUTER}
      className='scale-x-mirror fill-blue-800'
    />

    <div className='h-6.75 flex flex-1 bg-blue-800' />

    <CornerShape
      container={Container.OUTER}
      className='scale-x-mirror fill-blue-800'
    />
  </div>
);

export default OuterInwardDesign;

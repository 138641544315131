import { ReactComponent as TooltipTriangle } from '../../assets/svg/tooltip_triangle.svg';
import React, {
  type HTMLProps,
  type PropsWithChildren,
  type ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { CheckCircle, Copy, type Icon, X } from 'react-feather';
import { Z_INDEX } from 'theme/zIndex';

import { useCopyClipboard } from 'hooks/useCopyClipboard';

import styled, { css } from 'styled-components';

// TODO: Break this file into a components folder

export { ThemedText } from './text';

export const CloseIcon = styled(X)<{ onClick: () => void }>`
  color: ${({ theme }) => theme.neutral1};
  cursor: pointer;
`;

export const ButtonText = styled.button`
  outline: none;
  border: none;
  font-size: inherit;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};
  transition-timing-function: ease-in-out;
  transition-property: opacity, color, background-color;

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }

  :focus {
    text-decoration: underline;
  }
`;

export const EllipsisStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableStyle = css`
  text-decoration: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`;

const LinkStyle = css`
  color: #aac1ff;
  stroke: #aac1ff;
  font-weight: 500;
`;

const IconStyle = css`
  height: 16px;
  width: 18px;
  margin-left: 10px;
`;

const CopyIcon = styled(Copy)`
  ${IconStyle}
  ${ClickableStyle}
  ${LinkStyle}
  stroke: #AAC1FF;
`;

function handleClickExternalLink(event: React.MouseEvent<HTMLAnchorElement>) {
  const { target } = event.currentTarget;

  if (target === '_blank' || event.ctrlKey || event.metaKey) {
    // Do nothing
  } else {
    event.preventDefault();
  }
}

const StyledLink = styled.a`
  ${ClickableStyle}
  ${LinkStyle}
`;

/**
 * Outbound link that handles firing google analytics events
 */
export function ExternalLink({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & {
  href: string;
}) {
  return (
    <StyledLink
      target={target}
      rel={rel}
      href={href}
      onClick={handleClickExternalLink}
      {...rest}
    />
  );
}

const TOOLTIP_WIDTH = 60;

const ToolTipWrapper = styled.div<{
  isCopyContractTooltip?: boolean;
  tooltipX?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: ${({ isCopyContractTooltip, tooltipX }) =>
    isCopyContractTooltip &&
    (tooltipX ? `${tooltipX - TOOLTIP_WIDTH / 2}px` : '50%')};
  transform: translate(5px, 32px);
  z-index: ${Z_INDEX.tooltip};
`;

const StyledTooltipTriangle = styled(TooltipTriangle)`
  path {
    fill: ${({ theme }) => theme.black};
  }
`;

const CopiedTooltip = styled.div<{ isCopyContractTooltip?: boolean }>`
  background-color: ${({ theme }) => theme.black};
  text-align: center;
  justify-content: center;
  width: ${({ isCopyContractTooltip }) =>
    !isCopyContractTooltip && `${TOOLTIP_WIDTH}px`};
  height: ${({ isCopyContractTooltip }) => !isCopyContractTooltip && '32px'};
  line-height: ${({ isCopyContractTooltip }) =>
    !isCopyContractTooltip && '32px'};

  padding: ${({ isCopyContractTooltip }) => isCopyContractTooltip && '8px'};
  border-radius: 8px;

  color: ${({ theme }) => theme.white};
  font-size: 12px;
`;

function Tooltip({
  isCopyContractTooltip,
  tooltipX
}: {
  isCopyContractTooltip: boolean;
  tooltipX?: number;
}) {
  return (
    <ToolTipWrapper
      isCopyContractTooltip={isCopyContractTooltip}
      tooltipX={tooltipX}
    >
      <StyledTooltipTriangle />
      <CopiedTooltip isCopyContractTooltip={isCopyContractTooltip}>
        Copied!
      </CopiedTooltip>
    </ToolTipWrapper>
  );
}

const CopyIconWrapper = styled.div`
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

function CopyToClipboard({
  toCopy,
  children
}: PropsWithChildren<{ toCopy: string }>) {
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(toCopy);
  }, [toCopy, setCopied]);
  return (
    <CopyIconWrapper onClick={copy}>
      {children}
      {isCopied && <Tooltip isCopyContractTooltip={false} />}
    </CopyIconWrapper>
  );
}

export function CopyLinkIcon({ toCopy }: { toCopy: string }) {
  return (
    <CopyToClipboard toCopy={toCopy}>
      <CopyIcon />
    </CopyToClipboard>
  );
}

const CopyHelperContainer = styled.div<{
  clicked: boolean;
  color?: string;
  gap: number;
}>`
  ${ClickableStyle}
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap + 'px'};
  align-items: center;
  color: ${({ color }) => color ?? 'inherit'};
`;

const CopyHelperText = styled.div<{ fontSize?: number; offset: number }>`
  ${EllipsisStyle}
  ${({ fontSize }) => (fontSize ? 'font-size: ' + fontSize + 'px' : 'inherit')};
  max-width: calc(100% - ${({ offset }) => offset + 'px'});
`;

const StyledCheckCircle = styled(CheckCircle)`
  color: ${({ theme }) => theme.success};
  stroke-width: 1.5px;
`;

function isEllipsisActive(element: HTMLDivElement | null) {
  return Boolean(element && element.offsetWidth < element.scrollWidth);
}

interface CopyHelperProps {
  InitialIcon?: Icon | null;
  CopiedIcon?: Icon;
  toCopy: string;
  color?: string;
  fontSize?: number;
  iconSize?: number;
  gap?: number;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  children: ReactNode;
}

type CopyHelperRefType = { forceCopy: () => void };
export const CopyHelper = forwardRef<CopyHelperRefType, CopyHelperProps>(
  (
    {
      InitialIcon = Copy,
      CopiedIcon = StyledCheckCircle,
      toCopy,
      color,
      fontSize,
      iconSize = 20,
      gap = 4,
      iconPosition = 'left',
      iconColor = 'currentColor',
      children
    }: CopyHelperProps,
    ref
  ) => {
    const [isCopied, setCopied] = useCopyClipboard();
    const copy = useCallback(() => {
      setCopied(toCopy);
    }, [toCopy, setCopied]);

    useImperativeHandle(ref, () => ({
      forceCopy() {
        copy();
      }
    }));

    // Detects is text is ellipsing in order to shorten gap caused by extra space browsers add after ... chars
    const textRef = useRef<HTMLDivElement>(null);
    const isEllipsis = isEllipsisActive(textRef.current);
    const displayGap = isEllipsis ? gap - 4 : gap;

    const [isHover, setIsHover] = useState(false);
    const onHover = useCallback(() => setIsHover(true), []);
    const offHover = useCallback(() => setIsHover(false), []);

    // Copy-helpers w/ left icon always show icon & display "Copied!" in copied state
    // Copy-helpers w/ right icon show icon on hover & do not change text
    const showIcon = Boolean(iconPosition === 'left' || isHover || isCopied);
    const Icon = isCopied ? CopiedIcon : showIcon ? InitialIcon : null;
    const offset = showIcon ? gap + iconSize : 0;
    return (
      <CopyHelperContainer
        onClick={copy}
        color={color}
        clicked={isCopied}
        gap={displayGap}
        onMouseEnter={onHover}
        onMouseLeave={offHover}
      >
        {iconPosition === 'left' && Icon && (
          <Icon size={iconSize} strokeWidth={1.5} color={iconColor} />
        )}
        <CopyHelperText ref={textRef} fontSize={fontSize} offset={offset}>
          {isCopied && iconPosition === 'left' ? 'Copied!' : children}
        </CopyHelperText>
        <div style={{ clear: 'both' }} />
        {iconPosition === 'right' && Icon && (
          <Icon size={iconSize} strokeWidth={1.5} color={iconColor} />
        )}
      </CopyHelperContainer>
    );
  }
);
CopyHelper.displayName = 'CopyHelper';

import AuthenticatedHeader from './AuthenticatedHeader';
import SettingsMenu from './SettingsMenu';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWeb3React } from 'hooks/useWeb3React';

import WalletModal from 'components/WalletModal';

enum MenuState {
  DEFAULT,
  SETTINGS
}

const DefaultMenu = ({ drawerOpen }: { drawerOpen: boolean }) => {
  const { account } = useWeb3React();
  const isAuthenticated = !!account;

  const [menu, setMenu] = useState<MenuState>(MenuState.DEFAULT);
  const openSettings = useCallback(() => setMenu(MenuState.SETTINGS), []);
  const closeSettings = useCallback(() => setMenu(MenuState.DEFAULT), []);

  useEffect(() => {
    if (!drawerOpen && menu !== MenuState.DEFAULT) {
      // wait for the drawer to close before resetting the menu
      const timer = setTimeout(() => {
        closeSettings();
      }, 250);
      return () => clearTimeout(timer);
    }
    return;
  }, [drawerOpen, menu, closeSettings]);

  const SubMenu = useMemo(() => {
    switch (menu) {
      case MenuState.DEFAULT:
        return isAuthenticated ? (
          <AuthenticatedHeader account={account} openSettings={openSettings} />
        ) : (
          <WalletModal openSettings={openSettings} />
        );
      case MenuState.SETTINGS:
        return <SettingsMenu onClose={closeSettings} />;
    }
  }, [account, closeSettings, isAuthenticated, menu, openSettings]);

  return (
    <div className='flex h-full w-full flex-col justify-start'>{SubMenu}</div>
  );
};

export default DefaultMenu;

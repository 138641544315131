import { ReactComponent as ethereum } from './ChainSymbols/ethereum.svg';
import { ReactComponent as oasis } from './ChainSymbols/oasis.svg';
import { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';
import type { CSSProperties, FunctionComponent } from 'react';

import { isSupportedChain } from 'utils/chains';

type SVG = FunctionComponent<React.SVGProps<SVGSVGElement>>;

const getSymbol = (chainId: ChainId): SVG | undefined => {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.SEPOLIA:
      return ethereum;

    case ChainId.OASIS_SAPPHIRE_MAINNET:
    case ChainId.OASIS_SAPPHIRE_TESTNET:
      return oasis;
    default:
      return undefined;
  }
};

export const getDefaultBorderRadius = (size: number) => size / 2 - 4;

type ChainLogoProps = {
  chainId: ChainId;
  className?: string;
  size?: number;
  borderRadius?: number;
  style?: CSSProperties;
  ignoreUnsupported?: boolean;
};
export const ChainLogo = ({
  chainId,
  className,
  style,
  size = 16,
  ignoreUnsupported = false
}: ChainLogoProps) => {
  if (!ignoreUnsupported && !isSupportedChain(chainId)) {
    return null;
  }

  const { label } = getChainInfo(chainId);

  const Symbol = getSymbol(chainId);

  if (!Symbol) {
    return null;
  }

  return (
    <svg
      width={size}
      height={size}
      className={className}
      style={style}
      aria-labelledby='titleID'
    >
      <title id='titleID'>{`${label} logo`}</title>
      <Symbol width={size} height={size} />
    </svg>
  );
};

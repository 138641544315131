import { RPC_URLS } from './networks';
import { ChainId, type SupportedChainsType } from '@uniswap/sdk-core';
import AppRpcProvider from 'rpc/AppRpcProvider';
import AppStaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider';
import type StaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider';

const providerFactory = (chainId: SupportedChainsType, i = 0) =>
  new AppStaticJsonRpcProvider(chainId, RPC_URLS[chainId][i]);

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: Record<number, StaticJsonRpcProvider> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_MAINNET
  ),
  [ChainId.OASIS_SAPPHIRE_TESTNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_TESTNET
  ),
  [ChainId.MAINNET]: new AppRpcProvider(ChainId.MAINNET, [
    providerFactory(ChainId.MAINNET),
    providerFactory(ChainId.MAINNET, 1)
  ]),
  [ChainId.SEPOLIA]: providerFactory(ChainId.SEPOLIA)
};

export const DEPRECATED_RPC_PROVIDERS: Record<number, StaticJsonRpcProvider> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_MAINNET
  ),
  [ChainId.OASIS_SAPPHIRE_TESTNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_TESTNET
  ),
  [ChainId.MAINNET]: providerFactory(ChainId.MAINNET),
  [ChainId.SEPOLIA]: providerFactory(ChainId.SEPOLIA)
};

import type { ComponentProps } from 'react';

export const StarIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    {...props}
  >
    <path
      d='M6.14131 1.72679C6.28803 1.4295 6.71197 1.4295 6.85869 1.72679L8.17029 4.38437C8.22855 4.50243 8.34118 4.58426 8.47146 4.60319L11.4043 5.02935C11.7324 5.07702 11.8634 5.48021 11.626 5.71163L9.50376 7.78026C9.40948 7.87216 9.36646 8.00456 9.38872 8.13432L9.8897 11.0553C9.94575 11.382 9.60277 11.6312 9.30932 11.4769L6.68614 10.0979C6.56961 10.0366 6.43039 10.0366 6.31386 10.0979L3.69068 11.4769C3.39723 11.6312 3.05425 11.382 3.1103 11.0553L3.61128 8.13432C3.63354 8.00456 3.59052 7.87216 3.49624 7.78026L1.37404 5.71163C1.13663 5.48021 1.26764 5.07702 1.59573 5.02935L4.52854 4.60319C4.65882 4.58426 4.77145 4.50243 4.82971 4.38437L6.14131 1.72679Z'
      strokeWidth='1.5'
    />
  </svg>
);

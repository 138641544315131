import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from 'utils/cn';

const textVariants = cva('', {
  variants: {
    variant: {
      default: '',
      transition: 'transition-colors duration-300'
    },
    color: {
      white: 'text-white',
      blue: 'text-blue-200',
      'dark-blue': 'text-blue-800',
      gray: 'text-gray-600',
      red: 'text-red'
    },
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl'
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      bold: 'font-bold'
    }
  },
  defaultVariants: {
    variant: 'default',
    color: 'blue',
    size: 'sm',
    weight: 'normal'
  }
});

type TextPropsOriginal = {
  className?: string;
  children: React.ReactNode;
};

interface TextProps
  extends TextPropsOriginal,
    VariantProps<typeof textVariants> {}

export const Text = ({
  className,
  variant,
  color,
  size,
  weight,
  ...props
}: TextProps) => (
  <div
    className={cn(textVariants({ color, size, variant, weight }), className)}
    {...props}
  />
);

export const PrimaryText = (props: TextProps) => (
  <Text size='sm' color='white' weight={props.weight ?? 'medium'} {...props} />
);

export const PrimaryLargeText = (props: TextProps) => (
  <Text size='md' color='white' weight={props.weight ?? 'medium'} {...props} />
);

export const PrimaryXLText = (props: TextProps) => (
  <Text size='xl' color='white' weight={props.weight ?? 'medium'} {...props} />
);

export const Primary4XLText = (props: TextProps) => (
  <Text size='4xl' color='white' weight={props.weight ?? 'bold'} {...props} />
);

export const SecondaryText = (props: TextProps) => (
  <Text size='sm' color='gray' weight={props.weight ?? 'medium'} {...props} />
);

export const SecondaryLargeText = (props: TextProps) => (
  <Text size='md' color='gray' weight={props.weight ?? 'medium'} {...props} />
);

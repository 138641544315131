import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'utils/cn';

const alertVariants = cva(
  'relative flex flex-row gap-1.5 w-full font-medium rounded-lg px-1 py-3 text-sm bg-blue-800/32 backdrop-blur-xs',
  {
    variants: {
      variant: {
        default: 'text-foreground bg-blue-200/32',
        success: 'text-green [&>svg]:stroke-green bg-green/32',
        error: 'text-red [&>svg]:stroke-red bg-red/32',
        warning: 'text-yellow [&>svg]:stroke-yellow bg-yellow/32',
        info: 'text-blue-200 [&>svg]:stroke-blue-200 bg-blue-200/32'
      },
      position: {
        default: 'items-start justify-center',
        center: 'items-center justify-center'
      },
      type: {
        default: '',
        responsive: 'md:bg-blue-800/32'
      }
    },
    defaultVariants: {
      variant: 'default',
      position: 'default',
      type: 'default'
    }
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, position, type, ...props }, ref) => (
  <div
    ref={ref}
    role='alert'
    className={cn(alertVariants({ variant, position, type }), className)}
    {...props}
  />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('font-medium leading-none tracking-tight', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-sm leading-6', className)} {...props} />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };

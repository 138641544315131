export function isDevelopmentEnv(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function isStagingEnv(): boolean {
  // This is set in vercel builds and deploys from web/staging.
  return Boolean(process.env.REACT_APP_STAGING);
}

export function isProductionEnv(): boolean {
  return process.env.NODE_ENV === 'production' && !isStagingEnv();
}

export function getEnvName(): 'production' | 'staging' | 'development' {
  if (isStagingEnv()) {
    return 'staging';
  }
  if (isProductionEnv()) {
    return 'production';
  }
  return 'development';
}

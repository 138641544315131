import type { ComponentProps } from 'react';

export const DestinationLongVectorIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='163'
    viewBox='0 0 14 163'
    fill='none'
    {...props}
  >
    <path d='M1 1V156C1 159.314 3.68629 162 7 162H13' strokeLinecap='square' />
  </svg>
);

import { useAtom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';

import SettingsToggle from 'components/composed/SettingsToggle';

export const hideSpamAtom = atomWithStorage<boolean>('hideSmallBalances', true);

export const SpamToggle = () => {
  const [hideSpam, updateHideSpam] = useAtom(hideSpamAtom);

  return (
    <SettingsToggle
      title='Hide unknown tokens'
      isActive={hideSpam}
      toggle={() => void updateHideSpam((value) => !value)}
    />
  );
};

import type { ReactNode } from 'react';

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type AlertState = {
  show: boolean;
  message: string | ReactNode;
  type: AlertType;
};

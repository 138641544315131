import { MenuDropdown } from '../../NavBar/MenuDropdown';
import { type ReactNode, useState } from 'react';
import { NavLink, type NavLinkProps, useLocation } from 'react-router-dom';

import { cn } from 'utils/cn';
import { routes } from 'utils/routes';

import { useIsPoolsPage } from 'hooks/useIsPoolsPage';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

interface MenuItemProps {
  id?: NavLinkProps['id'];
  href: string;
  disabled?: boolean;
  comingSoon?: boolean;
  isActive?: boolean;
  children: ReactNode;
}

const MenuItem = ({
  href,
  id,
  disabled,
  comingSoon,
  isActive,
  children
}: MenuItemProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  if (disabled) {
    return (
      <div className='cursor-default p-2 font-bold text-gray-700/50'>
        {children}
      </div>
    );
  }

  if (comingSoon) {
    return (
      <Tooltip
        delayDuration={0}
        open={isTooltipOpen}
        onOpenChange={setIsTooltipOpen}
      >
        <TooltipTrigger className='cursor-default' asChild>
          <div
            className='cursor-default p-2 font-bold text-gray-700/50'
            onClick={handleTooltipToggle}
          >
            {children}
          </div>
        </TooltipTrigger>
        <TooltipContent className='bg-blue-700'>Coming soon…</TooltipContent>
      </Tooltip>
    );
  }

  return (
    <NavLink
      to={href}
      className={cn(
        'p-2 font-bold no-underline transition-colors duration-300 hover:text-blue-900',
        {
          'text-gray-600': !isActive,
          'text-blue-900': isActive
        }
      )}
      id={id}
    >
      {children}
    </NavLink>
  );
};

const NavBar = () => {
  const { pathname } = useLocation();
  const isPoolActive = useIsPoolsPage();

  return (
    <nav className='absolute hidden w-full flex-row items-center justify-center gap-4 md:flex'>
      <MenuItem href={routes.swap} isActive={pathname.startsWith('/swap')}>
        Swap
      </MenuItem>
      <MenuItem href={routes.pools} isActive={isPoolActive}>
        Pools
      </MenuItem>
      <MenuItem href={routes.sale} isActive={pathname.startsWith('/sale')}>
        Sale
      </MenuItem>
      <MenuItem href={routes.bridge} isActive={pathname.startsWith('/bridge')}>
        Bridge
      </MenuItem>
      <MenuItem href={routes.stake} isActive={pathname.startsWith('/stake')}>
        Stake
      </MenuItem>

      <MenuDropdown />
    </nav>
  );
};

export default NavBar;

import type { Percent } from '@uniswap/sdk-core';
import { useMemo } from 'react';

import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';
import { warningSeverity } from 'utils/prices';

import { WarningIcon } from 'components/Icons';
import { LoadingBubble } from 'components/Tokens/loading';
import { MouseoverTooltip } from 'components/Tooltip';
import styled from 'styled-components';

const FiatLoadingBubble = styled(LoadingBubble)`
  border-radius: 4px;
  width: 4rem;
  height: 1rem;
`;

export const FiatValue = ({
  fiatValue,
  priceImpact,
  disabled = false
}: {
  fiatValue: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
  disabled?: boolean;
}) => {
  const { formatNumber, formatPercent } = useFormatter();

  const priceImpactColor = useMemo(() => {
    if (!priceImpact) return undefined;
    if (priceImpact.lessThan('0')) return 'text-green';
    const severity = warningSeverity(priceImpact);
    if (severity < 1) return 'text-gray-600';
    if (severity < 3) return 'text-yellow';
    return 'text-red';
  }, [priceImpact]);

  if (fiatValue.isLoading) {
    return <FiatLoadingBubble />;
  }

  return (
    <div className='flex w-full flex-row items-center justify-start gap-2'>
      <span className='text-sm font-medium text-gray-600'>
        {fiatValue.data ? (
          formatNumber({
            input: fiatValue.data,
            type: NumberType.FiatTokenPrice
          })
        ) : (
          <MouseoverTooltip
            text='Not enough liquidity to show accurate USD value.'
            disabled={disabled}
          >
            <WarningIcon className='stroke-yellow' />
          </MouseoverTooltip>
        )}
      </span>

      {priceImpact && (
        <span className={cn('text-sm font-medium', priceImpactColor)}>
          <MouseoverTooltip
            text='The estimated difference between the USD values of input and output amounts.'
            disabled={disabled}
          >
            ({formatPercent(priceImpact.multiply(-1))})
          </MouseoverTooltip>
        </span>
      )}
    </div>
  );
};

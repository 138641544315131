import type { ChainId, Currency, Token } from '@uniswap/sdk-core';
import type { TokenInfo } from '@uniswap/token-lists/src/types';
import { DEFAULT_LIST_OF_LISTS } from 'constants/lists';
import { useMemo } from 'react';

import type { TokenAddressMap } from 'lib/hooks/useTokenList/utils';
import { useBridgeSupportedChains } from 'utils/chains';

import { useWeb3React } from 'hooks/useWeb3React';
import {
  useCurrencyFromMap,
  useTokenFromMapOrNetwork
} from 'lib/hooks/useCurrency';
import { useAppSelector } from 'state/hooks';
import { useUnsupportedTokenList } from 'state/lists/hooks';
import {
  useCombinedActiveList,
  useCombinedTokenMapFromUrls
} from 'state/lists/hooks';
import { deserializeToken, useUserAddedTokens } from 'state/user/hooks';

type Maybe<T> = T | null | undefined;

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
export function useTokensFromMap(
  tokenMap: TokenAddressMap,
  chainId: Maybe<ChainId>
): { [address: string]: Token } {
  return useMemo(() => {
    if (!chainId) return {};

    // reduce to just tokens
    return Object.keys(tokenMap[chainId] ?? {}).reduce<{
      [address: string]: Token;
    }>((newMap, address) => {
      newMap[address.toUpperCase()] = tokenMap[chainId][address].token;
      return newMap;
    }, {});
  }, [chainId, tokenMap]);
}

// TODO(WEB-2347): after disallowing unchecked index access, refactor ChainTokenMap to not use ?'s
export type ChainTokenMap = {
  [chainId in number]?: { [address in string]?: Token };
};
/** Returns tokens from all token lists on all chains, combined with user added tokens */
export function useAllTokensMultichain(): ChainTokenMap {
  const allTokensFromLists = useCombinedTokenMapFromUrls(DEFAULT_LIST_OF_LISTS);
  const userAddedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    const chainTokenMap: ChainTokenMap = {};

    if (userAddedTokensMap) {
      Object.keys(userAddedTokensMap).forEach((key) => {
        const chainId = Number(key);
        const tokenMap = {} as { [address in string]?: Token };
        Object.values(userAddedTokensMap[chainId]).forEach(
          (serializedToken) => {
            tokenMap[serializedToken.address] =
              deserializeToken(serializedToken);
          }
        );
        chainTokenMap[chainId] = tokenMap;
      });
    }

    Object.keys(allTokensFromLists).forEach((key) => {
      const chainId = Number(key);
      const tokenMap = chainTokenMap[chainId] ?? {};
      Object.values(allTokensFromLists[chainId]).forEach(({ token }) => {
        tokenMap[token.address] = token;
      });
      chainTokenMap[chainId] = tokenMap;
    });

    return chainTokenMap;
  }, [allTokensFromLists, userAddedTokensMap]);
}

/** Returns all tokens from the default list + user added tokens */
export function useDefaultActiveTokens(chainId: Maybe<ChainId>): {
  [address: string]: Token;
} {
  const defaultListTokens = useCombinedActiveList();
  const tokensFromMap = useTokensFromMap(defaultListTokens, chainId);
  const userAddedTokens = useUserAddedTokens();
  return useMemo(
    () =>
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: Token }>(
          (tokenMap, token) => {
            tokenMap[token.address] = token;
            return tokenMap;
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          { ...tokensFromMap }
        ),
    [tokensFromMap, userAddedTokens]
  );
}

export function useUnsupportedTokens(): { [address: string]: Token } {
  const { chainId } = useWeb3React();
  const unsupportedTokensMap = useUnsupportedTokenList();
  const unsupportedTokens = useTokensFromMap(unsupportedTokensMap, chainId);

  return { ...unsupportedTokens };
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useToken(
  tokenAddress?: string | null
): Token | null | undefined {
  const { chainId } = useWeb3React();
  const tokens = useDefaultActiveTokens(chainId);
  return useTokenFromMapOrNetwork(tokens, tokenAddress);
}

export function useCurrency(
  currencyId: Maybe<string>,
  chainId?: ChainId
): Currency | undefined {
  const { chainId: connectedChainId } = useWeb3React();
  const tokens = useDefaultActiveTokens(chainId ?? connectedChainId);
  return useCurrencyFromMap(tokens, chainId ?? connectedChainId, currencyId);
}

export const useBridgeTokens = () => {
  const defaultListTokens = useAllTokensMultichain();
  return useGetAllBridgeSupportedNetworks(defaultListTokens);
};

export type BridgeToken = Token & {
  tokenInfo: TokenInfo;
};

// Returns a list of all chains that are supported by the bridge
export const useBridgeNetworks = (): ChainId[] => {
  const supportedChains = useBridgeTokens();

  return Object.keys(supportedChains).map((chainId) => Number(chainId));
};

// Returns a map of all tokens that are supported by the bridge on requested chain
export const useBridgeTokensOnChain = (chainId: ChainId): Token[] => {
  const defaultListTokens = useBridgeTokens();

  const tokensOnChain =
    useGetAllBridgeSupportedNetworks(defaultListTokens)?.[chainId] ?? {};

  return Object.keys(tokensOnChain)
    .filter((address) => Boolean(tokensOnChain[address]))
    .map((address) => tokensOnChain[address] as Token);
};

type TokenWithBridgeInfo = Token & {
  tokenInfo: TokenInfo;
};

// Returns a map of all tokens that are supported by the bridge on each chain
export const useGetAllBridgeSupportedNetworks = (
  tokenMap: ChainTokenMap
): ChainTokenMap => {
  const supportedChains = useBridgeSupportedChains();

  const supportedTokensByChain = Object.keys(tokenMap).filter((chainId) =>
    supportedChains.includes(Number(chainId))
  );

  return useMemo(
    () =>
      Object.keys(tokenMap).reduce<{
        [chainId: string]: { [address: string]: Token };
      }>((chainMap, chainId) => {
        if (!supportedTokensByChain.includes(chainId)) return chainMap;

        const bridgeTokenAddresses = Object.keys(tokenMap?.[+chainId] ?? {});
        const tokensOnChain = bridgeTokenAddresses.reduce<{
          [address: string]: Token;
        }>((chainTokens, address) => {
          const token = tokenMap[+chainId]?.[address] as
            | TokenWithBridgeInfo
            | undefined;

          if (token?.tokenInfo?.extensions?.bridgeInfo) {
            chainTokens[address] = token;
          }

          return chainTokens;
        }, {});
        if (Object.keys(tokensOnChain).length) {
          chainMap[chainId] = tokensOnChain;
        }

        return chainMap;
      }, {}),
    [supportedTokensByChain, tokenMap]
  );
};

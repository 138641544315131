import { checkedTransaction, finalizeTransaction } from './reducer';
import {
  type SerializableTransactionReceipt,
  type TransactionDetails
} from './types';
import type { TransactionReceipt } from '@ethersproject/abstract-provider';
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc';
import { useCallback, useMemo } from 'react';

import { useAddPopup } from '../application/hooks';
import { isPendingTx } from './hooks';
import { useWeb3React } from 'hooks/useWeb3React';
import LibUpdater from 'lib/hooks/transactions/updater';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import { PopupType } from 'state/application/reducer';

export function toSerializableReceipt(
  receipt: TransactionReceipt
): SerializableTransactionReceipt {
  return {
    blockHash: receipt.blockHash,
    blockNumber: receipt.blockNumber,
    contractAddress: receipt.contractAddress,
    from: receipt.from,
    status: receipt.status,
    to: receipt.to,
    transactionHash: receipt.transactionHash,
    transactionIndex: receipt.transactionIndex
  };
}

export default function Updater() {
  const { chainId } = useWeb3React();
  const addPopup = useAddPopup();
  const transactions = useAppSelector((state) => state.transactions);
  const pendingTransactions = useMemo(() => {
    if (!chainId || !transactions[chainId]) return {};
    return Object.values(transactions[chainId]).reduce(
      (acc, tx) => {
        if (isPendingTx(tx)) acc[tx.hash] = tx;
        return acc;
      },
      {} as Record<string, TransactionDetails>
    );
  }, [chainId, transactions]);

  const dispatch = useAppDispatch();
  const onCheck = useCallback(
    ({
      chainId,
      hash,
      blockNumber
    }: {
      chainId: number;
      hash: string;
      blockNumber: number;
    }) => dispatch(checkedTransaction({ chainId, hash, blockNumber })),
    [dispatch]
  );
  const onReceipt = useCallback(
    ({
      chainId,
      hash,
      receipt
    }: {
      chainId: number;
      hash: string;
      receipt: TransactionReceipt;
    }) => {
      dispatch(
        finalizeTransaction({
          chainId,
          hash,
          receipt: toSerializableReceipt(receipt)
        })
      );

      addPopup(
        {
          type: PopupType.Transaction,
          hash
        },
        hash,
        DEFAULT_TXN_DISMISS_MS
      );
    },
    [addPopup, dispatch]
  );

  return (
    <LibUpdater
      pendingTransactions={pendingTransactions}
      onCheck={onCheck}
      onReceipt={onReceipt}
    />
  );
}

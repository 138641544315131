import {
  type ComponentProps,
  type ReactNode,
  useReducer,
  useRef,
  useState
} from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';

import { cn } from 'utils/cn';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

import {
  DocumentationIcon,
  EllipsisIcon,
  IncentivizeIcon,
  SateliteIcon
} from 'components/Icons';
import { ExternalLink } from 'components/composed/ExternalLink';

type MenuItemContentType = {
  icon: (props: ComponentProps<'svg'>) => JSX.Element;
  text: ReactNode;
  disabled?: boolean;
  disableHighlight?: boolean;
};

const MenuItemContent = ({
  icon: Icon,
  text,
  disabled,
  disableHighlight
}: MenuItemContentType) => (
  <div
    className={cn(
      'flex flex-row items-center gap-2 rounded-md bg-transparent p-2',
      'transition-colors duration-300',
      {
        'text-gray-600': !disabled,
        'cursor-not-allowed text-gray-700': disabled,
        'hover:bg-blue-800 hover:text-blue-200': !disabled && !disableHighlight
      }
    )}
  >
    <Icon className='size-5' />

    <span className='text-xs font-medium'>{text}</span>
  </div>
);

type MenuItemProps = {
  icon: (props: ComponentProps<'svg'>) => JSX.Element;
  text: ReactNode;
  disabled?: boolean;
  href?: string;
  to?: NavLinkProps['to'];
  disableHighlight?: boolean;
  close?: () => void;
};

const MenuItem = ({
  icon,
  text,
  disabled,
  href,
  to,
  disableHighlight,
  close
}: MenuItemProps) => {
  if (to) {
    return (
      <NavLink to={to} onClick={close}>
        <MenuItemContent
          icon={icon}
          text={text}
          disabled={disabled}
          disableHighlight={disableHighlight}
        />
      </NavLink>
    );
  }

  if (href) {
    return (
      <ExternalLink href={href}>
        <MenuItemContent
          icon={icon}
          text={text}
          disabled={disabled}
          disableHighlight={disableHighlight}
        />
      </ExternalLink>
    );
  }

  return (
    <MenuItemContent
      icon={icon}
      text={text}
      disabled={disabled}
      disableHighlight={disableHighlight}
    />
  );
};

type MenuItemWrapperProps = {
  children: ReactNode;
  comingSoon?: boolean;
};

const MenuItemWrapper = ({ children, comingSoon }: MenuItemWrapperProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  if (comingSoon) {
    return (
      <Tooltip
        delayDuration={0}
        open={isTooltipOpen}
        onOpenChange={setIsTooltipOpen}
      >
        <TooltipTrigger className='cursor-default' asChild>
          <div className='cursor-default' onClick={handleTooltipToggle}>
            {children}
          </div>
        </TooltipTrigger>

        <TooltipContent className='bg-blue-700' side='left'>
          Coming soon…
        </TooltipContent>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

export const MenuDropdown = () => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined);

  return (
    <div className='relative' ref={ref}>
      <div
        className={cn(
          'cursor-pointer rounded-full bg-transparent hover:bg-blue-900/10 md:p-2',
          'transition-colors duration-300',
          'text-gray-600 hover:text-blue-200 md:hover:text-blue-900',
          {
            'text-blue-200 md:text-blue-900': isOpen
          }
        )}
        onClick={toggleOpen}
      >
        <EllipsisIcon viewBox='0 0 20 20' className='size-3.75 md:size-6' />
      </div>

      {isOpen && (
        <div
          className={cn(
            'flex flex-col p-1',
            'rounded-lg bg-blue-900',
            'border-none shadow-lg',
            'fixed right-2 w-47.5 md:left-0 md:right-0',
            'md:absolute md:h-fit',
            'z-1000',
            'md:top-14',
            'md:bottom-12',
            'bottom-19'
          )}
        >
          <MenuItem
            icon={DocumentationIcon}
            text='Documentation'
            href='https://neby.gitbook.io/neby-documentation'
            close={toggleOpen}
          />

          <MenuItemWrapper comingSoon>
            <MenuItem
              icon={IncentivizeIcon}
              text='Incentivize'
              disableHighlight
            />
          </MenuItemWrapper>

          <MenuItemWrapper comingSoon>
            <MenuItem icon={SateliteIcon} text='Cosmic' disableHighlight />
          </MenuItemWrapper>
        </div>
      )}
    </div>
  );
};

import { useCopyClipboard } from 'hooks/useCopyClipboard';

import { SecondaryText } from 'components/ui/Text';

import Tooltip from 'components/Tooltip';

export const GitVersionRow = () => {
  const [isCopied, staticCopy] = useCopyClipboard();

  return process.env.REACT_APP_GIT_COMMIT_HASH ? (
    <div
      className='w-full cursor-pointer'
      onClick={() => {
        staticCopy(process.env.REACT_APP_GIT_COMMIT_HASH as string);
      }}
    >
      <Tooltip text='Copied' show={isCopied}>
        <SecondaryText>
          {`Version: ${process.env.REACT_APP_GIT_COMMIT_HASH.substring(0, 6)}`}
        </SecondaryText>
      </Tooltip>
    </div>
  ) : null;
};

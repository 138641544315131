import type { PropsWithChildren } from 'react';

import { cn } from 'utils/cn';

import { SecondaryLargeText, SecondaryText } from 'components/ui/Text';

import { ChevronIcon } from 'components/Icons';

type ExpandoRowProps = PropsWithChildren<{
  title?: string;
  numItems: number;
  isExpanded: boolean;
  toggle: () => void;
}>;
export const ExpandoRow = ({
  title = 'Hidden',
  numItems,
  isExpanded,
  toggle,
  children
}: ExpandoRowProps) => {
  if (numItems === 0) {
    return null;
  }

  return (
    <>
      <div className='flex w-full items-center justify-between p-4'>
        <SecondaryLargeText>{`${title} (${numItems})`}</SecondaryLargeText>
        <div
          className='flex h-full w-fit cursor-pointer items-center justify-start rounded-xl py-1 pl-3 pr-2 hover:opacity-80'
          onClick={toggle}
        >
          <SecondaryText>{isExpanded ? 'Hide' : 'Show'}</SecondaryText>

          <ChevronIcon
            className={cn(
              'stroke-gray-600',
              'transition-transform duration-300',
              {
                'rotate-180': isExpanded
              }
            )}
          />
        </div>
      </div>

      <div
        className='flex flex-col justify-start overflow-hidden transition-all duration-300 ease-in-out'
        style={{ height: isExpanded ? numItems * 68 + 'px' : 0 }}
      >
        {children}
      </div>
    </>
  );
};

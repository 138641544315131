import type { Activity } from './types';
import {
  ChainId,
  type Currency,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  UNI_ADDRESSES
} from '@uniswap/sdk-core';
import { nativeOnChain } from 'constants/tokens';

import { isAddress } from 'utils';
import { isSameAddress } from 'utils/addresses';
import { NumberType, type useFormatter } from 'utils/formatNumbers';

import {
  type AssetActivityPartsFragment,
  type TokenApprovalPartsFragment,
  type TokenAssetPartsFragment,
  type TokenTransferPartsFragment,
  type TransactionDetailsPartsFragment,
  TransactionType
} from 'graphql/data/__generated__/types-and-hooks';

import { gqlToCurrency, supportedChainIdFromGQLChain } from 'graphql/data/util';

type TransactionChanges = {
  TokenTransfer: TokenTransferPartsFragment[];
  TokenApproval: TokenApprovalPartsFragment[];
};

type FormatNumberOrStringFunctionType = ReturnType<
  typeof useFormatter
>['formatNumberOrString'];

// TODO: Move common contract metadata to a backend service
const UNI_IMG =
  'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png';

const ENS_IMG =
  'https://464911102-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/collections%2F2TjMAeHSzwlQgcOdL48E%2Ficon%2FKWP0gk2C6bdRPliWIA6o%2Fens%20transparent%20background.png?alt=media&token=bd28b063-5a75-4971-890c-97becea09076';

const COMMON_CONTRACTS: { [key: string]: Partial<Activity> | undefined } = {
  [UNI_ADDRESSES[ChainId.MAINNET].toLowerCase()]: {
    title: 'UNI Governance',
    descriptor: 'Contract Interaction',
    logos: [UNI_IMG]
  },
  // TODO(cartcrom): Add permit2-specific logo
  '0x000000000022d473030f116ddee9f6b43ac78ba3': {
    title: 'Permit2',
    descriptor: 'Uniswap Protocol',
    logos: [UNI_IMG]
  },
  '0x4976fb03c32e5b8cfe2b6ccb31c09ba78ebaba41': {
    title: 'Ethereum Name Service',
    descriptor: 'Public Resolver',
    logos: [ENS_IMG]
  },
  '0x58774bb8acd458a640af0b88238369a167546ef2': {
    title: 'Ethereum Name Service',
    descriptor: 'DNS Registrar',
    logos: [ENS_IMG]
  },
  '0x084b1c3c81545d370f3634392de611caabff8148': {
    title: 'Ethereum Name Service',
    descriptor: 'Reverse Registrar',
    logos: [ENS_IMG]
  },
  '0x283af0b28c62c092c9727f1ee09c02ca627eb7f5': {
    title: 'Ethereum Name Service',
    descriptor: 'ETH Registrar Controller',
    logos: [ENS_IMG]
  }
};

const callsPositionManagerContract = (assetActivity: TransactionActivity) => {
  const supportedChain = supportedChainIdFromGQLChain(assetActivity.chain);
  if (!supportedChain) return false;
  return isSameAddress(
    assetActivity.details.to,
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[supportedChain]
  );
};

const getSwapTitle = (
  sent: TokenTransferPartsFragment,
  received: TokenTransferPartsFragment
): string | undefined => {
  const supportedSentChain = supportedChainIdFromGQLChain(sent.asset.chain);
  const supportedReceivedChain = supportedChainIdFromGQLChain(
    received.asset.chain
  );
  if (!supportedSentChain || !supportedReceivedChain) {
    return undefined;
  }
  if (
    sent.tokenStandard === 'NATIVE' &&
    isSameAddress(
      nativeOnChain(supportedSentChain).wrapped.address,
      received.asset.address
    )
  )
    return 'Wrapped';
  else if (
    received.tokenStandard === 'NATIVE' &&
    isSameAddress(
      nativeOnChain(supportedReceivedChain).wrapped.address,
      received.asset.address
    )
  ) {
    return 'Unwrapped';
  } else {
    return 'Swapped';
  }
};

const getSwapDescriptor = ({
  tokenIn,
  inputAmount,
  tokenOut,
  outputAmount
}: {
  tokenIn: TokenAssetPartsFragment;
  outputAmount: string;
  tokenOut: TokenAssetPartsFragment;
  inputAmount: string;
}) => `${inputAmount} ${tokenIn.symbol} for ${outputAmount} ${tokenOut.symbol}`;

// exported for testing
const parseSwapAmounts = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType
):
  | {
      inputAmount: string;
      inputCurrencyId: string;
      outputAmount: string;
      outputCurrencyId: string;
      sent: TokenTransferPartsFragment;
      received: TokenTransferPartsFragment;
    }
  | undefined => {
  const sent = changes.TokenTransfer.find((t) => t.direction === 'OUT');
  // Any leftover native token is refunded on exact_out swaps where the input token is native
  const refund = changes.TokenTransfer.find(
    (t) =>
      t.direction === 'IN' &&
      t.asset.id === sent?.asset.id &&
      t.asset.standard === 'NATIVE'
  );
  const received = changes.TokenTransfer.find(
    (t) => t.direction === 'IN' && t !== refund
  );
  if (!sent || !received) return undefined;
  const inputCurrencyId = sent.asset.id;
  const outputCurrencyId = received.asset.id;
  const adjustedInput =
    parseFloat(sent.quantity) - parseFloat(refund?.quantity ?? '0');
  const inputAmount = formatNumberOrString({
    input: adjustedInput,
    type: NumberType.TokenNonTx
  });
  const outputAmount = formatNumberOrString({
    input: received.quantity,
    type: NumberType.TokenNonTx
  });
  return {
    sent,
    received,
    inputAmount,
    outputAmount,
    inputCurrencyId,
    outputCurrencyId
  };
};

const parseSwap = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType
) => {
  if (changes.TokenTransfer.length >= 2) {
    const swapAmounts = parseSwapAmounts(changes, formatNumberOrString);

    if (swapAmounts) {
      const { sent, received, inputAmount, outputAmount } = swapAmounts;
      return {
        title: getSwapTitle(sent, received),
        descriptor: getSwapDescriptor({
          tokenIn: sent.asset,
          inputAmount,
          tokenOut: received.asset,
          outputAmount
        }),
        currencies: [gqlToCurrency(sent.asset), gqlToCurrency(received.asset)]
      };
    }
  }
  return { title: 'Unknown Swap' };
};

/**
 * Wrap/unwrap transactions are labelled as lend transactions on the backend.
 * This function parses the transaction changes to determine if the transaction is a wrap/unwrap transaction.
 */
const parseLend = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType
) => {
  const native = changes.TokenTransfer.find(
    (t) => t.tokenStandard === 'NATIVE'
  )?.asset;
  const erc20 = changes.TokenTransfer.find(
    (t) => t.tokenStandard === 'ERC20'
  )?.asset;
  if (
    native &&
    erc20 &&
    gqlToCurrency(native)?.wrapped.address ===
      gqlToCurrency(erc20)?.wrapped.address
  ) {
    return parseSwap(changes, formatNumberOrString);
  }
  return { title: 'Unknown Lend' };
};

const parseApprove = (changes: TransactionChanges) => {
  if (changes.TokenApproval.length === 1) {
    const title =
      parseInt(changes.TokenApproval[0].quantity) === 0
        ? 'Revoked Approval'
        : 'Approved';
    const descriptor = `${changes.TokenApproval[0].asset.symbol}`;
    const currencies = [gqlToCurrency(changes.TokenApproval[0].asset)];
    return { title, descriptor, currencies };
  }
  return { title: 'Unknown Approval' };
};

const parseLPTransfers = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType
) => {
  const poolTokenA = changes.TokenTransfer[0];
  const poolTokenB = changes.TokenTransfer[1];

  const tokenAQuanitity = formatNumberOrString({
    input: poolTokenA.quantity,
    type: NumberType.TokenNonTx
  });
  const tokenBQuantity = formatNumberOrString({
    input: poolTokenB.quantity,
    type: NumberType.TokenNonTx
  });

  return {
    descriptor: `${tokenAQuanitity} ${poolTokenA.asset.symbol} and ${tokenBQuantity} ${poolTokenB.asset.symbol}`,
    logos: [
      poolTokenA.asset.project?.logo?.url,
      poolTokenB.asset.project?.logo?.url
    ],
    currencies: [
      gqlToCurrency(poolTokenA.asset),
      gqlToCurrency(poolTokenB.asset)
    ]
  };
};

type TransactionActivity = AssetActivityPartsFragment & {
  details: TransactionDetailsPartsFragment;
};

const parseSendReceive = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType,
  assetActivity: TransactionActivity
) => {
  // TODO(cartcrom): remove edge cases after backend implements
  // Edge case: Receiving two token transfers in interaction w/ V3 manager === removing liquidity. These edge cases should potentially be moved to backend
  if (
    changes.TokenTransfer.length === 2 &&
    callsPositionManagerContract(assetActivity)
  ) {
    return {
      title: 'Removed Liquidity',
      ...parseLPTransfers(changes, formatNumberOrString)
    };
  }

  let transfer: TokenTransferPartsFragment | undefined;
  let assetName: string | undefined;
  let amount: string | undefined;
  let currencies: (Currency | undefined)[] | undefined;
  if (changes.TokenTransfer.length === 1) {
    transfer = changes.TokenTransfer[0];
    assetName = transfer.asset.symbol;
    amount = formatNumberOrString({
      input: transfer.quantity,
      type: NumberType.TokenNonTx
    });
    currencies = [gqlToCurrency(transfer.asset)];
  }

  if (transfer && assetName && amount) {
    if (transfer.direction === 'IN') {
      return {
        title: 'Received',
        descriptor: `${amount} ${assetName} from `,
        otherAccount: isAddress(transfer.sender) || undefined,
        currencies
      };
    } else {
      return {
        title: 'Sent',
        descriptor: `${amount} ${assetName} to `,
        otherAccount: isAddress(transfer.recipient) || undefined,
        currencies
      };
    }
  }
  return { title: 'Unknown Send' };
};

const parseUnknown = (
  _changes: TransactionChanges,
  _formatNumberOrString: FormatNumberOrStringFunctionType,
  assetActivity: TransactionActivity
) => ({
  title: 'Contract Interaction',
  ...COMMON_CONTRACTS[assetActivity.details.to.toLowerCase()]
});

type TransactionTypeParser = (
  changes: TransactionChanges,
  formatNumberOrString: FormatNumberOrStringFunctionType,
  assetActivity: TransactionActivity
) => Partial<Activity>;
const ActivityParserByType: {
  [key: string]: TransactionTypeParser | undefined;
} = {
  [TransactionType.Swap]: parseSwap,
  [TransactionType.Lend]: parseLend,
  [TransactionType.Approve]: parseApprove,
  [TransactionType.Send]: parseSendReceive,
  [TransactionType.Receive]: parseSendReceive,
  [TransactionType.Unknown]: parseUnknown
};

const getLogoSrcs = (
  changes: TransactionChanges
): Array<string | undefined> => {
  const logoSet = new Set<string | undefined>();
  changes.TokenTransfer.forEach((tokenChange) =>
    logoSet.add(tokenChange.asset.project?.logo?.url)
  );
  changes.TokenApproval.forEach((tokenChange) =>
    logoSet.add(tokenChange.asset.project?.logo?.url)
  );

  return Array.from(logoSet);
};

const parseRemoteActivity = (
  assetActivity: AssetActivityPartsFragment,
  account: string,
  formatNumberOrString: FormatNumberOrStringFunctionType
): Activity | undefined => {
  try {
    // IMPORTANT!!!
    // The following ts-ignore WAS added to remove a pop-up error in dev mode
    // The following functionality is not used
    // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
    const changes = assetActivity.details['assetChanges'].reduce(
      (acc: TransactionChanges, assetChange: any) => {
        if (assetChange.__typename === 'TokenTransfer')
          acc.TokenTransfer.push(assetChange);
        else if (assetChange.__typename === 'TokenApproval')
          acc.TokenApproval.push(assetChange);

        return acc;
      },
      {
        TokenTransfer: [],
        TokenApproval: []
      }
    );

    const supportedChain = supportedChainIdFromGQLChain(assetActivity.chain);
    if (!supportedChain) {
      return undefined;
    }

    // IMPORTANT!!!
    // The following ts-ignores were added to remove a pop-up error in dev mode
    // The following functionality is not used
    const defaultFields = {
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      hash: assetActivity.details['hash'],
      chainId: supportedChain,
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      status: assetActivity.details['status'],
      timestamp: assetActivity.timestamp,
      logos: getLogoSrcs(changes),
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      title: assetActivity.details['type'],
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      descriptor: assetActivity.details['to'],
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      from: assetActivity.details['from'],
      // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
      nonce: assetActivity.details['nonce']
    };
    // @ts-expect-error - This is a hack to get around the fact that the backend is not returning the correct type
    const parsedFields = ActivityParserByType[assetActivity.details['type']]?.(
      changes,
      formatNumberOrString,
      assetActivity as TransactionActivity
    );
    return { ...defaultFields, ...parsedFields };
  } catch (e) {
    console.error('Failed to parse activity', e, assetActivity);
    return undefined;
  }
};

export const parseRemoteActivities = (
  assetActivities: readonly AssetActivityPartsFragment[] | undefined,
  account: string,
  formatNumberOrString: FormatNumberOrStringFunctionType
) =>
  assetActivities?.reduce(
    (acc: { [hash: string]: Activity }, assetActivity) => {
      const activity = parseRemoteActivity(
        assetActivity,
        account,
        formatNumberOrString
      );
      if (activity) acc[activity.hash] = activity;
      return acc;
    },
    {}
  );

import { type PreviewTrade, QuoteState, TradeState } from './types';
import { skipToken } from '@reduxjs/toolkit/query/react';
import type { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core';
import { useMemo } from 'react';

import useIsWindowVisible from 'hooks/useIsWindowVisible';

import {
  useGetQuickRouteQuery,
  useGetQuickRouteQueryState
} from './quickRouteSlice';

const TRADE_NOT_FOUND = {
  state: TradeState.NO_ROUTE_FOUND,
  trade: undefined
} as const;
const TRADE_LOADING = { state: TradeState.LOADING, trade: undefined } as const;

export function usePreviewTrade(
  skipFetch = false,
  tradeType: TradeType,
  amountSpecified: CurrencyAmount<Currency> | undefined,
  otherCurrency: Currency | undefined
): {
  state: TradeState;
  trade?: PreviewTrade;
  currentTrade?: PreviewTrade;
  swapQuoteLatency?: number;
} {
  const queryArgs: typeof skipToken = skipToken;
  const isWindowVisible = useIsWindowVisible();

  const {
    isError,
    data: tradeResult,
    error,
    currentData
  } = useGetQuickRouteQueryState(queryArgs);
  useGetQuickRouteQuery(skipFetch || !isWindowVisible ? skipToken : queryArgs, {
    // If latest quote from cache was fetched > 2m ago, instantly repoll for another instead of waiting for next poll period
    refetchOnMountOrArgChange: 2 * 60
  });

  const isFetching = currentData !== tradeResult || !currentData;

  return useMemo(() => {
    if (amountSpecified && otherCurrency && queryArgs === skipToken) {
      return {
        state: TradeState.STALE,
        trade: tradeResult?.trade,
        currentTrade: currentData?.trade,
        swapQuoteLatency: tradeResult?.latencyMs
      };
    } else if (!amountSpecified || isError || queryArgs === skipToken) {
      return {
        state: TradeState.INVALID,
        trade: undefined,
        currentTrade: currentData?.trade,
        error: JSON.stringify(error)
      };
    } else if (tradeResult?.state === QuoteState.NOT_FOUND && !isFetching) {
      return TRADE_NOT_FOUND;
    } else if (!tradeResult?.trade) {
      return TRADE_LOADING;
    } else {
      return {
        state: isFetching ? TradeState.LOADING : TradeState.VALID,
        trade: tradeResult.trade,
        currentTrade: currentData?.trade,
        swapQuoteLatency: tradeResult.latencyMs
      };
    }
  }, [
    amountSpecified,
    error,
    isError,
    isFetching,
    queryArgs,
    tradeResult?.latencyMs,
    tradeResult?.state,
    tradeResult?.trade,
    currentData?.trade,
    otherCurrency
  ]);
}

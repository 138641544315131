import { getChainInfo } from 'constants/chainInfo';
import type { SupportedL2ChainId } from 'constants/chains';

import { cn } from 'utils/cn';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';

import {
  useIsTransactionConfirmed,
  useTransaction
} from 'state/transactions/hooks';

import Button from 'components/ui/Button';

import { TransactionSummary } from 'components/AccountDetails/TransactionSummary';
import { CloseIcon } from 'components/Icons';
import { ChainLogo } from 'components/Logo/ChainLogo';
import { ExternalLink } from 'components/composed/ExternalLink';

type L2Content = {
  onDismiss: () => void;
  hash?: string;
  chainId: SupportedL2ChainId;
  pendingText: React.ReactNode;
};

export const L2Content = ({
  onDismiss,
  chainId,
  hash,
  pendingText
}: L2Content) => {
  const transaction = useTransaction(hash);
  const confirmed = useIsTransactionConfirmed(hash);
  const transactionSuccess = transaction?.receipt?.status === 1;

  const secondsToConfirm = transaction?.confirmedTime
    ? (transaction.confirmedTime - transaction.addedTime) / 1000
    : undefined;

  const info = getChainInfo(chainId);

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row items-center justify-between gap-2'>
        <div>
          <ChainLogo chainId={chainId} />

          {info.label}
        </div>

        <CloseIcon onClick={onDismiss} />
      </div>

      <div>
        {confirmed ? (
          transactionSuccess ? (
            <div>Confirm icon</div>
          ) : (
            <div>Error icon</div>
          )
        ) : (
          <div>Loader</div>
        )}
      </div>

      <div className='flex flex-col gap-8'>
        <span>
          {!hash
            ? 'Confirm transaction in wallet'
            : !confirmed
              ? 'Transaction submitted'
              : transactionSuccess
                ? 'Success'
                : 'Error'}
        </span>

        <span>
          {transaction ? (
            <TransactionSummary info={transaction.info} />
          ) : (
            pendingText
          )}
        </span>

        {chainId && hash && (
          <ExternalLink
            href={getExplorerLink({
              chainId,
              data: hash,
              type: ExplorerDataType.TRANSACTION
            })}
            className={cn(
              'flex cursor-pointer items-center justify-center transition-colors duration-300',
              'text-blue-200',
              'hover:text-blue-200/80'
            )}
          >
            View on Explorer
          </ExternalLink>
        )}

        <span>
          {secondsToConfirm && (
            <div>
              Transaction completed in{' '}
              <span>{secondsToConfirm} seconds 🎉</span>
            </div>
          )}
        </span>

        <Button variant='secondary' onClick={onDismiss}>
          Close
        </Button>
      </div>
    </div>
  );
};

import type { ComponentProps } from 'react';

export const CheckIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path d='M3.8637 7.46969C3.57081 7.1768 3.09594 7.1768 2.80304 7.46969C2.51015 7.76258 2.51015 8.23746 2.80304 8.53035L3.8637 7.46969ZM6.66671 11.3334L6.13638 11.8637C6.42927 12.1566 6.90414 12.1566 7.19704 11.8637L6.66671 11.3334ZM13.8637 5.19702C14.1566 4.90412 14.1566 4.42925 13.8637 4.13636C13.5708 3.84346 13.0959 3.84346 12.803 4.13636L13.8637 5.19702ZM2.80304 8.53035L6.13638 11.8637L7.19704 10.803L3.8637 7.46969L2.80304 8.53035ZM7.19704 11.8637L13.8637 5.19702L12.803 4.13636L6.13638 10.803L7.19704 11.8637Z' />
  </svg>
);

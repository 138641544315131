import type { ComponentProps } from 'react';

export const HourglassIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    stroke='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g>
      <path
        d='M6.50004 12.3335H10.5M4.90004 1.3335H12.1C12.4734 1.3335 12.6601 1.3335 12.8027 1.40616C12.9281 1.47007 13.0301 1.57206 13.094 1.6975C13.1667 1.84011 13.1667 2.02679 13.1667 2.40016V3.78317C13.1667 4.10929 13.1667 4.27235 13.1299 4.4258C13.0972 4.56185 13.0433 4.69191 12.9702 4.81121C12.8878 4.94576 12.7725 5.06107 12.5419 5.29167L10.5876 7.24592C10.3236 7.50993 10.1916 7.64193 10.1421 7.79415C10.0986 7.92805 10.0986 8.07228 10.1421 8.20617C10.1916 8.35839 10.3236 8.4904 10.5876 8.75441L12.5419 10.7087C12.7725 10.9393 12.8878 11.0546 12.9702 11.1891C13.0433 11.3084 13.0972 11.4385 13.1299 11.5745C13.1667 11.728 13.1667 11.891 13.1667 12.2172V13.6002C13.1667 13.9735 13.1667 14.1602 13.094 14.3028C13.0301 14.4283 12.9281 14.5303 12.8027 14.5942C12.6601 14.6668 12.4734 14.6668 12.1 14.6668H4.90004C4.52667 14.6668 4.33999 14.6668 4.19738 14.5942C4.07194 14.5303 3.96995 14.4283 3.90604 14.3028C3.83337 14.1602 3.83337 13.9735 3.83337 13.6002V12.2172C3.83337 11.891 3.83337 11.728 3.87021 11.5745C3.90288 11.4385 3.95675 11.3084 4.02985 11.1891C4.11231 11.0546 4.22761 10.9393 4.45821 10.7087L6.41246 8.75441C6.67647 8.4904 6.80848 8.35839 6.85794 8.20617C6.90144 8.07228 6.90144 7.92805 6.85794 7.79415C6.80848 7.64193 6.67647 7.50992 6.41246 7.24592L4.45821 5.29167C4.22761 5.06107 4.11231 4.94576 4.02985 4.81121C3.95675 4.69191 3.90288 4.56185 3.87021 4.4258C3.83337 4.27235 3.83337 4.10929 3.83337 3.78317V2.40016C3.83337 2.02679 3.83337 1.84011 3.90604 1.6975C3.96995 1.57206 4.07194 1.47007 4.19738 1.40616C4.33999 1.3335 4.52667 1.3335 4.90004 1.3335Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

import type { ComponentProps } from 'react';

export const TelegramIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='17'
    height='14'
    viewBox='0 0 17 14'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.59992 5.7082C5.89488 3.83696 8.75885 2.60332 10.1919 2.00728C14.2834 0.305486 15.1335 0.00986564 15.6877 0.00010408C15.8095 -0.00204287 16.0821 0.0281618 16.2586 0.171395C16.4076 0.292338 16.4486 0.455714 16.4683 0.570381C16.4879 0.685049 16.5123 0.946264 16.4929 1.15037C16.2712 3.48 15.3118 9.1334 14.8237 11.7426C14.6172 12.8467 14.2105 13.2169 13.8169 13.2531C12.9613 13.3318 12.3116 12.6877 11.4829 12.1445C10.1862 11.2945 9.4537 10.7654 8.19503 9.93591C6.74043 8.97735 7.68338 8.45051 8.51236 7.5895C8.72931 7.36417 12.499 3.93537 12.5719 3.62432C12.5811 3.58542 12.5895 3.44041 12.5034 3.36384C12.4172 3.28727 12.2901 3.31346 12.1983 3.33428C12.0683 3.3638 9.9968 4.73297 5.98389 7.4418C5.39591 7.84556 4.86333 8.04228 4.38616 8.03197C3.86012 8.02061 2.84822 7.73454 2.09598 7.49001C1.17333 7.1901 0.440022 7.03153 0.503876 6.52217C0.537136 6.25687 0.902482 5.98555 1.59992 5.7082Z'
    />
  </svg>
);

import { useFormatter } from 'utils/formatNumbers';

import ArrowIcon from 'components/Icons/ArrowIcon';
import { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';

import type { InterfaceTrade } from 'state/routing/types';

export const TradeSummary = ({
  trade
}: {
  trade: Pick<InterfaceTrade, 'inputAmount' | 'outputAmount'>;
}) => {
  const { formatReviewSwapCurrencyAmount } = useFormatter();

  return (
    <div className='flex w-full flex-row items-center justify-center gap-4 text-nowrap'>
      <div className='flex flex-1 flex-row items-center justify-end gap-3'>
        <CurrencyLogo
          currency={trade.inputAmount.currency}
          size={AssetLogoSize.sm}
        />

        <span>
          {formatReviewSwapCurrencyAmount(trade.inputAmount)}{' '}
          {trade.inputAmount.currency.symbol}
        </span>
      </div>

      <ArrowIcon className='fill-gray-600' />

      <div className='flex flex-1 flex-row items-center justify-start gap-3'>
        <CurrencyLogo
          currency={trade.outputAmount.currency}
          size={AssetLogoSize.sm}
        />

        <span>
          {formatReviewSwapCurrencyAmount(trade.outputAmount)}{' '}
          {trade.outputAmount.currency.symbol}
        </span>
      </div>
    </div>
  );
};

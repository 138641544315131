import {
  EthereumMainnet,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';

const BLOCK_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: OasisSapphireMainnet.explorer,
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.explorer,
  [ChainId.MAINNET]: EthereumMainnet.explorer,
  [ChainId.SEPOLIA]: Sepolia.explorer
};

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
  NATIVE = 'native',
  EXPLORER = 'explorer'
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export const getExplorerLink = ({
  chainId,
  data,
  type
}: {
  chainId: number;
  data?: string;
  type: ExplorerDataType;
}): string => {
  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? 'https://etherscan.io';

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;

    case ExplorerDataType.EXPLORER:
    default:
      return `${prefix}`;
  }
};

import ConnectionErrorView from './ConnectionErrorView';
import { useOrderedConnections } from './useOrderedConnections';
import {
  deprecatedInjectedConnection,
  deprecatedNetworkConnection,
  networkConnection
} from 'connection';
import { ActivationStatus, useActivationState } from 'connection/activate';
import { useEffect } from 'react';

import { isSupportedChain } from 'utils/chains';

import { useWeb3React } from 'hooks/useWeb3React';

import { PrimaryLargeText, Text } from 'components/ui/Text';

import { useToggleAccountDrawer } from 'components/AccountDrawer';
import IconButton from 'components/AccountDrawer/IconButton';
import { SettingsIcon } from 'components/Icons/SettingsIcon';

const DeprecatedInjectorMessage = () => {
  const { tryActivation } = useActivationState();
  const toggleAccountDrawer = useToggleAccountDrawer();
  const { chainId } = useWeb3React();

  return (
    <button
      className='transitions-colors m-0 w-full cursor-pointer items-center border-none bg-none p-0 outline-none duration-300 hover:opacity-70'
      onClick={() =>
        tryActivation(
          deprecatedInjectedConnection,
          toggleAccountDrawer,
          chainId
        )
      }
    >
      <Text color='blue' weight='medium' size='sm'>
        Don&apos;t see your wallet?
      </Text>
    </button>
  );
};

const WalletModal = ({ openSettings }: { openSettings: () => void }) => {
  const { connector, chainId } = useWeb3React();

  const { activationState } = useActivationState();

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (
      chainId &&
      isSupportedChain(chainId) &&
      connector !== networkConnection.connector
    ) {
      deprecatedNetworkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);

  const { orderedConnections, showDeprecatedMessage } = useOrderedConnections();

  return (
    <div className='flex w-full flex-1 flex-col bg-blue-900 p-4'>
      <div className='mb-4 flex w-full flex-wrap items-center justify-between'>
        <PrimaryLargeText weight='bold'>Connect a wallet</PrimaryLargeText>

        <IconButton Icon={SettingsIcon} onClick={openSettings} />
      </div>
      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView />
      ) : (
        <div className='grid auto-rows-auto gap-y-4'>
          <div className='flex flex-col gap-1 overflow-hidden rounded-xl'>
            {orderedConnections}
          </div>

          <div className='px-1'>
            {showDeprecatedMessage && <DeprecatedInjectorMessage />}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletModal;

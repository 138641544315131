import type { SVGProps } from 'react';

const CurvedSlopeShape = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='29'
    height='28'
    viewBox='0 0 29 28'
    fill='none'
    className={className}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.3137 28H29V0H0.00610352C0.0848389 2.00774 0.916992 3.91695 2.34314 5.34314L22.6569 25.6569C24.1571 27.1572 26.192 28 28.3137 28Z'
    />
  </svg>
);

export default CurvedSlopeShape;

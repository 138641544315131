import type { ComponentProps } from 'react';

export const TriangleIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M7.99994 6V8.66666M7.99994 11.3333H8.00661M6.8266 2.638L1.21193 12.0113C1.09625 12.2118 1.03505 12.4391 1.03443 12.6705C1.0338 12.902 1.09377 13.1295 1.20837 13.3306C1.32297 13.5317 1.48821 13.6993 1.68766 13.8167C1.88711 13.9342 2.11383 13.9974 2.34526 14H13.5753C13.8067 13.9974 14.0334 13.9342 14.2329 13.8167C14.4323 13.6993 14.5976 13.5317 14.7122 13.3306C14.8268 13.1295 14.8867 12.902 14.8861 12.6705C14.8855 12.4391 14.8243 12.2118 14.7086 12.0113L9.09327 2.638C8.97503 2.44332 8.80864 2.28239 8.61013 2.17071C8.41163 2.05902 8.1877 2.00035 7.95993 2.00035C7.73216 2.00035 7.50824 2.05902 7.30973 2.17071C7.11123 2.28239 6.94484 2.44332 6.8266 2.638Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

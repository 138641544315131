import type { Currency, Price } from '@uniswap/sdk-core';
import { useCallback, useMemo, useState } from 'react';

import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { useUSDPrice } from 'hooks/useUSDPrice';

interface TradePriceProps {
  price: Price<Currency, Currency>;
}

export const TradePrice = ({ price }: TradePriceProps) => {
  const { formatNumber, formatPrice } = useFormatter();

  const [showInverted, setShowInverted] = useState<boolean>(false);

  const { baseCurrency, quoteCurrency } = price;
  const { data: usdPrice } = useUSDPrice(
    tryParseCurrencyAmount('1', showInverted ? baseCurrency : quoteCurrency)
  );

  const formattedPrice = useMemo(() => {
    try {
      return formatPrice({
        price: showInverted ? price : price.invert(),
        type: NumberType.TokenTx
      });
    } catch {
      return '0';
    }
  }, [formatPrice, price, showInverted]);

  const label = showInverted
    ? `${price.quoteCurrency?.symbol}`
    : `${price.baseCurrency?.symbol} `;
  const labelInverted = showInverted
    ? `${price.baseCurrency?.symbol} `
    : `${price.quoteCurrency?.symbol}`;
  const flipPrice = useCallback(
    () => setShowInverted(!showInverted),
    [setShowInverted, showInverted]
  );

  const text = `${'1 ' + labelInverted + ' = ' + formattedPrice ?? '-'} ${label}`;

  return (
    <button
      className='user-select-text flex cursor-pointer flex-row flex-wrap items-center justify-start border-none bg-transparent p-0 text-left'
      onClick={(e) => {
        e.stopPropagation();
        flipPrice();
      }}
      title={text}
    >
      <span>{text}</span>{' '}
      {usdPrice && (
        <span className='text-gray-600'>
          {formatNumber({
            input: usdPrice,
            type: NumberType.FiatTokenPrice
          })}
        </span>
      )}
    </button>
  );
};

import { slideInAnimation, slideOutAnimation } from './animations';
import { useEffect, useRef, useState } from 'react';

import { useUnmountingAnimation } from 'hooks/useUnmountingAnimation';

import styled from 'styled-components';
import { AnimationType } from 'theme/components/FadePresence';

interface TransitionTextProps {
  initialText: React.ReactNode;
  transitionText: React.ReactNode;
  transitionTimeMs?: number;
  onTransition?: () => void;
}

const InitialTextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: display
    ${({ theme }) =>
      `${theme.transition.duration.fast} ${theme.transition.timing.inOut}`};
  ${slideInAnimation}
  &.${AnimationType.EXITING} {
    ${slideOutAnimation}
  }
`;

const TransitionTextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: display
    ${({ theme }) =>
      `${theme.transition.duration.fast} ${theme.transition.timing.inOut}`};
  ${slideInAnimation}
  &.${AnimationType.EXITING} {
    ${slideOutAnimation}
  }
`;

export const TransitionText = ({
  initialText,
  transitionText,
  transitionTimeMs = 1500,
  onTransition
}: TransitionTextProps) => {
  const [transitioned, setTransitioned] = useState(false);

  useEffect(() => {
    // Transition from initial text to transition text.
    const timeout = setTimeout(() => {
      if (!transitioned) {
        setTransitioned(true);
        onTransition?.();
      }
    }, transitionTimeMs);

    return () => clearTimeout(timeout);
  }, [onTransition, transitionTimeMs, transitioned]);

  const initialTextRef = useRef<HTMLDivElement>(null);
  useUnmountingAnimation(initialTextRef, () => AnimationType.EXITING);

  return (
    <div className='relative min-h-8 w-full'>
      {!transitioned && (
        <InitialTextContainer ref={initialTextRef}>
          {initialText}
        </InitialTextContainer>
      )}
      {transitioned && (
        <TransitionTextContainer>{transitionText}</TransitionTextContainer>
      )}
    </div>
  );
};

import AssetLogo, { type AssetLogoBaseProps } from './AssetLogo';
import type { Currency } from '@uniswap/sdk-core';
import type { TokenInfo } from '@uniswap/token-lists';

const CurrencyLogo = (
  props: AssetLogoBaseProps & {
    currency?: Currency | null;
  }
) => (
  <AssetLogo
    isNative={props.currency?.isNative}
    chainId={props.currency?.chainId}
    address={props.currency?.wrapped.address}
    symbol={props.symbol ?? props.currency?.symbol}
    backupImg={(props.currency as TokenInfo)?.logoURI}
    {...props}
  />
);

export default CurrencyLogo;

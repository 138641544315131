import type { ComponentProps } from 'react';

export const IncentivizeIcon = (props: ComponentProps<'svg'>) => (
  <svg
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M10.0606 5.1383L12.2089 4.60089C12.3882 4.55609 12.5524 4.46452 12.6847 4.33551C12.817 4.20651 12.9128 4.0447 12.9621 3.8666C13.0114 3.6885 13.0126 3.50051 12.9655 3.3218C12.9185 3.1431 12.8248 2.98009 12.6941 2.84942L11.1515 1.3061C11.0308 1.18534 10.8824 1.09602 10.7192 1.04594C10.556 0.995861 10.383 0.98656 10.2154 1.01885C10.0477 1.05114 9.89058 1.12403 9.75766 1.23115C9.62474 1.33827 9.52011 1.47634 9.45293 1.63328L8.58415 3.66111M5.1383 10.0606L4.60089 12.2089C4.55609 12.3882 4.46452 12.5524 4.33551 12.6847C4.20651 12.817 4.0447 12.9128 3.8666 12.9621C3.6885 13.0114 3.50051 13.0126 3.3218 12.9655C3.1431 12.9185 2.98009 12.8248 2.84942 12.6941L1.3061 11.1515C1.18534 11.0308 1.09602 10.8824 1.04594 10.7192C0.995861 10.556 0.98656 10.383 1.01885 10.2154C1.05114 10.0477 1.12403 9.89058 1.23115 9.75766C1.33827 9.62473 1.47634 9.5201 1.63328 9.45292L3.66111 8.58415M3.6611 6.6148L6.61478 3.66111C6.87587 3.4001 7.22994 3.25347 7.59911 3.25347C7.96829 3.25347 8.32236 3.4001 8.58344 3.66111L10.5528 5.63116C10.8138 5.89225 10.9604 6.24631 10.9604 6.61549C10.9604 6.98467 10.8138 7.33873 10.5528 7.59982L7.59911 10.5535C7.33803 10.8145 6.98396 10.9611 6.61478 10.9611C6.24561 10.9611 5.89154 10.8145 5.63045 10.5535L3.6611 8.58276C3.40009 8.32167 3.25346 7.96761 3.25346 7.59843C3.25346 7.22925 3.40009 6.87519 3.6611 6.6141V6.6148Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

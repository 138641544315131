import { type Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import type { Connector } from '@web3-react/types';
import { connections } from 'connection';
import { type ReactNode } from 'react';

export default function Web3Provider({ children }: { children: ReactNode }) {
  const connectors = connections.map<[Connector, Web3ReactHooks]>(
    ({ hooks, connector }) => [connector, hooks]
  );

  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  );
}

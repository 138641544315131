import type { DesignBaseProps } from './Container.types';
import { Container } from './constants';

import { cn } from 'utils/cn';

import { SlopeShape } from 'components/ui/shapes';

const InnerInwardSmallDesign = ({ className }: DesignBaseProps) => (
  <div className={cn('flex flex-row', className)}>
    <div className='h-2.5 w-2.5 rounded-bl-full bg-blue-900' />

    <div className='flex h-2.5 flex-1 bg-blue-900' />

    <SlopeShape
      container={Container.INNER}
      size='sm'
      className='h-2.5 w-auto fill-blue-900'
    />

    <div className='flex w-6.5' />

    <SlopeShape
      container={Container.INNER}
      size='sm'
      className='h-2.5 w-auto scale-x-mirror fill-blue-900'
    />

    <div className='flex h-2.5 flex-1 bg-blue-900' />

    <div className='h-2.5 w-2.5 rounded-br-full bg-blue-900' />
  </div>
);

export default InnerInwardSmallDesign;

import { Currency } from 'graphql/data/__generated__/types-and-hooks';

export const SUPPORTED_LOCAL_CURRENCIES = [
  Currency.Usd,
  Currency.Aud,
  Currency.Brl,
  Currency.Cad,
  Currency.Eur,
  Currency.Gbp,
  Currency.Hkd,
  Currency.Idr,
  Currency.Inr,
  Currency.Jpy,
  Currency.Ngn,
  Currency.Pkr,
  Currency.Rub,
  Currency.Sgd,
  Currency.Thb,
  Currency.Try,
  Currency.Uah,
  Currency.Vnd
] as const;

export type SupportedLocalCurrency =
  (typeof SUPPORTED_LOCAL_CURRENCIES)[number];

export const DEFAULT_LOCAL_CURRENCY: SupportedLocalCurrency = Currency.Usd;

// some currencies need to be forced to use the narrow symbol and others need to be forced to use symbol
// for example: when CAD is set to narrowSymbol it is displayed as $ which offers no differentiation from USD
// but when set to symbol it is displayed as CA$ which is correct
// On the other hand when TBH is set to symbol it is displayed as THB, but when set to narrowSymbol it is ฿ which is correct
export const LOCAL_CURRENCY_SYMBOL_DISPLAY_TYPE: Record<
  SupportedLocalCurrency,
  'narrowSymbol' | 'symbol'
> = {
  USD: 'narrowSymbol',
  EUR: 'narrowSymbol',
  RUB: 'narrowSymbol',
  INR: 'narrowSymbol',
  GBP: 'narrowSymbol',
  JPY: 'narrowSymbol',
  VND: 'narrowSymbol',
  SGD: 'symbol',
  BRL: 'symbol',
  HKD: 'symbol',
  CAD: 'symbol',
  IDR: 'narrowSymbol',
  TRY: 'narrowSymbol',
  NGN: 'narrowSymbol',
  UAH: 'narrowSymbol',
  PKR: 'narrowSymbol',
  AUD: 'symbol',
  THB: 'narrowSymbol'
};

import { Input as NumericalInput } from '../NumericalInput';
import type { Currency, Percent } from '@uniswap/sdk-core';
import ms from 'ms';
import {
  type HTMLProps,
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react';

import { isSupportedChain } from 'utils/chains';
import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { useCurrencyBalance } from '../../state/connection/hooks';
import { useWeb3React } from 'hooks/useWeb3React';

import Button from 'components/ui/Button';

import {
  PortfolioLogo,
  PortfolioLogoSize
} from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo';
import { FiatValue } from 'components/CurrencyInputPanel/FiatValue';
import { DropdownIcon } from 'components/Icons/DropdownIcon';
import {
  LoadingOpacityContainer,
  loadingOpacityMixin
} from 'components/Loader/styled';
import CurrencyLogo from 'components/Logo/CurrencyLogo';
import PrefetchBalancesWrapper from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import Tooltip from 'components/Tooltip';
import { Field } from 'components/composed/Swap/constants';
import styled from 'styled-components';

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
`;

interface WrapperProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

const FieldWrapper = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const LabelRow = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const InputRow = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const DetailsRow = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const InputWrapper = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const FiatValueWrapper = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const TokenSelectWrapper = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const TokenSelect = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const BalanceText = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

const SelectTokenButton = () => (
  <Button variant='secondary' size='xs' className='w-auto capitalize'>
    Select Token{' '}
    <DropdownIcon width={16} height={16} className='ml-2 stroke-blue-900' />
  </Button>
);

const SelectedToken = ({ symbol }: { symbol?: string }) => {
  if (symbol) {
    return (
      <div className='flex flex-row items-center text-white'>
        {symbol}{' '}
        <DropdownIcon width={16} height={16} className='ml-2 stroke-blue-200' />
      </div>
    );
  }

  return null;
};

type CurrencyInputPanel = InputPanelProps & {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
};

export type InputPanelProps = {
  value: string;
  inputLabel?: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  allowMaxClick: boolean;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  otherCurrency?: Currency | null;
  fiatValue?: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
  id: string;
  ignoreAllowedChainIds?: boolean;
  loading?: boolean;
  disabled?: boolean;
  fieldType?: Field;
  showChainLogo?: boolean;
  numericalInputSettings?: {
    disabled?: boolean;
    onDisabledClick?: () => void;
    disabledTooltipBody?: ReactNode;
  };
};

const CurrencyInputPanel = forwardRef<HTMLInputElement, CurrencyInputPanel>(
  (
    {
      value,
      onUserInput,
      onMax,
      allowMaxClick,
      onCurrencySelect,
      currency,
      otherCurrency,
      id,
      fiatValue,
      priceImpact,
      loading = false,
      disabled = false,
      numericalInputSettings,
      fieldType,
      modalOpen,
      setModalOpen,
      inputLabel,
      showChainLogo,
      ignoreAllowedChainIds,
      ...rest
    },
    ref
  ) => {
    const { account, chainId } = useWeb3React();
    const selectedCurrencyBalance = useCurrencyBalance(
      account ?? undefined,
      currency ?? undefined
    );
    const { formatCurrencyAmount } = useFormatter();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const handleDisabledNumericalInputClick = useCallback(() => {
      if (numericalInputSettings?.disabled && !tooltipVisible) {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), ms('4s')); // reset shake animation state after 4s
        numericalInputSettings.onDisabledClick?.();
      }
    }, [tooltipVisible, numericalInputSettings]);

    const chainAllowed = ignoreAllowedChainIds || isSupportedChain(chainId);

    // reset tooltip state when currency changes
    useEffect(() => setTooltipVisible(false), [currency]);

    return (
      <FieldWrapper
        id={id}
        {...rest}
        className={cn('mx-4 flex flex-col', {
          'mb-3 mt-auto': fieldType === Field.INPUT,
          'mb-auto mt-3': fieldType === Field.OUTPUT
        })}
      >
        {!!inputLabel && (
          <LabelRow className='text-xs font-medium text-gray-600'>
            {inputLabel}
          </LabelRow>
        )}

        <InputRow className='flex flex-row items-center justify-between'>
          <InputWrapper
            className='flex grow'
            onClick={handleDisabledNumericalInputClick}
          >
            <StyledNumericalInput
              className='token-amount-input text-left text-5xl font-normal'
              value={value}
              onUserInput={onUserInput}
              disabled={
                !chainAllowed || disabled || numericalInputSettings?.disabled
              }
              $loading={loading}
              id={id}
              ref={ref}
            />
          </InputWrapper>

          <PrefetchBalancesWrapper shouldFetchOnAccountUpdate={modalOpen}>
            <Tooltip
              show={tooltipVisible && !modalOpen}
              placement='bottom'
              offsetY={14}
              text={numericalInputSettings?.disabledTooltipBody}
              className='flex justify-end'
            >
              <TokenSelectWrapper
                onClick={() => {
                  if (chainAllowed && !disabled && onCurrencySelect) {
                    setModalOpen(true);
                  }
                }}
                className={cn('cursor-pointer p-1 transition-opacity', {
                  'opacity-40': !chainAllowed || disabled,
                  'animate-horizontal-shaking': tooltipVisible,
                  'hover:opacity-80': onCurrencySelect
                })}
              >
                <TokenSelect className='flex flex-row items-center'>
                  {currency && (
                    <div className='mr-1.5'>
                      {showChainLogo && (
                        <PortfolioLogo
                          chainId={currency.chainId}
                          currencies={[currency]}
                          size={PortfolioLogoSize.sm}
                        />
                      )}
                      {!showChainLogo && <CurrencyLogo currency={currency} />}
                    </div>
                  )}

                  <span className='text-base font-medium'>
                    {currency &&
                    currency.symbol &&
                    currency.symbol.length > 20 ? (
                      <SelectedToken
                        symbol={
                          currency.symbol.slice(0, 4) +
                          '...' +
                          currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )
                        }
                      />
                    ) : currency?.symbol ? (
                      <SelectedToken symbol={currency?.symbol} />
                    ) : (
                      <SelectTokenButton />
                    )}
                  </span>
                </TokenSelect>
              </TokenSelectWrapper>
            </Tooltip>
          </PrefetchBalancesWrapper>
        </InputRow>

        <DetailsRow className='flex h-5 flex-row items-center justify-between'>
          <FiatValueWrapper className='cursor-pointer text-xs text-gray-600'>
            <LoadingOpacityContainer $loading={loading}>
              {fiatValue && (
                <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />
              )}
            </LoadingOpacityContainer>
          </FiatValueWrapper>

          {account && currency && selectedCurrencyBalance && (
            <BalanceText
              className={cn(
                'flex justify-end text-xs font-medium leading-5 text-gray-600',
                {
                  'cursor-pointer hover:opacity-80 focus:outline-none':
                    allowMaxClick
                }
              )}
              onClick={() => allowMaxClick && onMax && onMax()}
            >
              Balance:{' '}
              {formatCurrencyAmount({
                amount: selectedCurrencyBalance,
                type: NumberType.TokenNonTx
              })}
            </BalanceText>
          )}
        </DetailsRow>
      </FieldWrapper>
    );
  }
);
CurrencyInputPanel.displayName = 'CurrencyInputPanel';

export default CurrencyInputPanel;

import Toggle from './Toggle';

import { PrimaryText } from 'components/ui/Text';

interface SettingsToggleProps {
  title: string;
  isActive: boolean;
  toggle: () => void;
}

const SettingsToggle = ({ title, isActive, toggle }: SettingsToggleProps) => (
  <div className='flex w-full items-center justify-start'>
    <div className='mr-2.5 flex w-full flex-col justify-start'>
      <div className='flex w-full items-center justify-start'>
        <PrimaryText>{title}</PrimaryText>
      </div>
    </div>

    <Toggle isActive={isActive} toggle={toggle} />
  </div>
);

export default SettingsToggle;

import type { SwapSectionWrapperProps } from './Swap.types';

import { cn } from 'utils/cn';

import { InnerInwardSmallDesign } from 'components/composed/ContainerShapes';
import { Field } from 'components/composed/Swap/constants';

const SwapSectionWrapper = ({
  field = Field.INPUT,
  children,
  className,
  ...rest
}: SwapSectionWrapperProps) => (
  <div
    className={cn(
      'relative box-border flex w-full max-w-113 flex-1 flex-col',
      className
    )}
    {...rest}
  >
    {field === Field.INPUT && (
      <div className='flex h-full flex-col'>
        <div className='relative left-0 top-0 flex h-full w-full flex-col bg-blue-900'>
          {children}
        </div>

        <InnerInwardSmallDesign />
      </div>
    )}
    {field === Field.OUTPUT && (
      <div className='flex h-full flex-col'>
        <InnerInwardSmallDesign className='rotate-180' />

        <div className='relative left-0 top-0 flex h-full w-full flex-col bg-blue-900'>
          {children}
        </div>
      </div>
    )}
  </div>
);

export default SwapSectionWrapper;

import type { SupportedChainId } from './chains';
import {
  EthereumMainnet,
  NetworkType,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';
import ms from 'ms';
import { commonTheme } from 'theme/colors';

export const AVERAGE_L1_BLOCK_TIME = ms('12s');

// The block number at which v3 was deployed on each chain, separate from the UNIVERSAL_ROUTER_CREATION_BLOCK
export const START_BLOCKS: { [key: number]: number } = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: 6270124,
  [ChainId.OASIS_SAPPHIRE_TESTNET]: 4873045,
  [ChainId.MAINNET]: 14292820
};

export interface BaseChainInfo {
  readonly networkType: NetworkType;
  readonly blockWaitMsBeforeWarning?: number;
  readonly docs: string;
  readonly bridge?: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
  readonly helpCenterUrl?: string;
  readonly nativeCurrency: {
    name: string; // e.g. 'Goerli ETH',
    symbol: string; // e.g. 'gorETH',
    decimals: number; // e.g. 18,
  };
  readonly color?: string;
  readonly backgroundColor?: string;
}

type ChainInfoMap = {
  readonly [chainId: number]: BaseChainInfo;
};

const CHAIN_INFO: ChainInfoMap = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: {
    networkType: NetworkType.L1,
    docs: OasisSapphireMainnet.docs,
    explorer: OasisSapphireMainnet.explorer,
    infoLink: OasisSapphireMainnet.infoLink,
    label: OasisSapphireMainnet.label,
    nativeCurrency: {
      name: OasisSapphireMainnet.nativeCurrency.name,
      symbol: OasisSapphireMainnet.nativeCurrency.symbol,
      decimals: OasisSapphireMainnet.nativeCurrency.decimals
    },
    color: commonTheme.chain_1
  },
  [ChainId.OASIS_SAPPHIRE_TESTNET]: {
    networkType: NetworkType.L1,
    docs: OasisSapphireTestnet.docs,
    explorer: OasisSapphireTestnet.explorer,
    infoLink: OasisSapphireTestnet.infoLink,
    label: OasisSapphireTestnet.label,
    nativeCurrency: {
      name: OasisSapphireTestnet.nativeCurrency.name,
      symbol: OasisSapphireTestnet.nativeCurrency.symbol,
      decimals: OasisSapphireTestnet.nativeCurrency.decimals
    },
    color: commonTheme.chain_1
  },
  [ChainId.MAINNET]: {
    networkType: NetworkType.L1,
    docs: EthereumMainnet.docs,
    explorer: EthereumMainnet.explorer,
    infoLink: EthereumMainnet.infoLink,
    label: EthereumMainnet.label,
    nativeCurrency: {
      name: EthereumMainnet.nativeCurrency.name,
      symbol: EthereumMainnet.nativeCurrency.symbol,
      decimals: EthereumMainnet.nativeCurrency.decimals
    },
    color: commonTheme.chain_1
  },
  [ChainId.SEPOLIA]: {
    networkType: NetworkType.L1,
    docs: Sepolia.docs,
    explorer: Sepolia.explorer,
    infoLink: Sepolia.infoLink,
    label: Sepolia.label,
    nativeCurrency: {
      name: Sepolia.nativeCurrency.name,
      symbol: Sepolia.nativeCurrency.symbol,
      decimals: Sepolia.nativeCurrency.decimals
    },
    color: commonTheme.chain_5
  }
} as const;

export function getChainInfo(chainId: SupportedChainId): BaseChainInfo;
export function getChainInfo(chainId: ChainId): BaseChainInfo;
export function getChainInfo(
  chainId: ChainId | SupportedChainId | number | undefined
): BaseChainInfo | undefined;
export function getChainInfo(
  chainId: SupportedChainId | ChainId | number | undefined
): ChainInfoMap[keyof ChainInfoMap] | undefined {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined;
  }
  return undefined;
}

const MAINNET_INFO = CHAIN_INFO[ChainId.MAINNET];
export function getChainInfoOrDefault(chainId: number | undefined) {
  return getChainInfo(chainId) ?? MAINNET_INFO;
}

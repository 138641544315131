import { ChainConfig, NetworkType } from './chain.type';

export const EthereumMainnet: ChainConfig = {
  chainId: 1,
  network: 'ethereum',
  label: 'Ethereum Mainnet',
  docs: 'https://ethereum.org/en/developers/docs/',
  infoLink: '',
  bridge: '',
  status: '',
  type: NetworkType.L1,
  explorer: 'https://etherscan.io/',
  rpcs: [
    'https://api.mycryptoapi.com/eth',
    'https://cloudflare-eth.com',
    'https://rpc.ankr.com/eth',
    'https://eth-mainnet.public.blastapi.io',
  ],
  subgraph: '',
  blockSubgraph: '',
  harvesterSubgraph: '',
  stakerSubgraph: '',
  nebyStakerSubgraph: '',
  deployedUnixTimestamp: 0,
  feeRecipient: '',
  feePercent: 15,
  addresses: {
    v3CoreFactory: '',
    multicall: '',
    quoter: '',
    v3Migrator: '',
    nonfungiblePositionManager: '',
    tickLens: '',
    swapRouter02: '',
    universalRouter: '',
    permit2: '',
    harvester: '',
    liquidityPositionStaker: '',
    nebyStaker: '',
  },
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  weth9: {
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
  },
};

import type { Currency } from '@uniswap/sdk-core';
import { nativeOnChain } from 'constants/tokens';
import type { ReactNode } from 'react';

import { isPreviewTrade } from 'state/routing/utils';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import type { InterfaceTrade } from 'state/routing/types';

import { chainIdToBackendName } from 'graphql/data/util';

type GasCostItemProps = {
  title: ReactNode;
  itemValue?: React.ReactNode;
  amount?: number;
};

const GasCostItem = ({ title, amount, itemValue }: GasCostItemProps) => {
  const { formatNumber } = useFormatter();

  if (!amount && !itemValue) return null;

  const value =
    itemValue ?? formatNumber({ input: amount, type: NumberType.FiatGasPrice });
  return (
    <div className='flex flex-row justify-between'>
      <span>{title}</span>
      <span className='text-gray-600'>{value}</span>
    </div>
  );
};

const NetworkCostDescription = ({ native }: { native: Currency }) => {
  const chainName = chainIdToBackendName(native.chainId);

  return (
    <span>
      Network cost is paid in {native.symbol} on the {chainName} network in
      order to transact.
    </span>
  );
};

type GasBreakdownTooltipProps = {
  trade: InterfaceTrade;
};

export const GasBreakdownTooltip = ({ trade }: GasBreakdownTooltipProps) => {
  const inputCurrency = trade.inputAmount.currency;
  const native = nativeOnChain(inputCurrency.chainId);

  if (isPreviewTrade(trade)) return <NetworkCostDescription native={native} />;

  const approvalEstimate = trade.approveInfo.needsApprove
    ? trade.approveInfo.approveGasEstimateUSD
    : undefined;
  // @ts-expect-error wrapInfo does not exist
  const wrapEstimate = trade.wrapInfo.needsWrap
    ? // @ts-expect-error wrapInfo does not exist
      trade.wrapInfo.wrapGasEstimateUSD
    : undefined;
  const showEstimateDetails = Boolean(wrapEstimate || approvalEstimate);

  const description = <NetworkCostDescription native={native} />;

  if (!showEstimateDetails) return description;

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col items-center justify-center'>
        <GasCostItem title={`Wrap ${native.symbol}`} amount={wrapEstimate} />
        <GasCostItem
          title={`Allow ${inputCurrency.symbol} (one time)`}
          amount={approvalEstimate}
        />

        <GasCostItem title='Swap' amount={trade.gasUseEstimateUSD} />
      </div>

      <div className='h-px w-full bg-gray-800' />
      {description}
    </div>
  );
};

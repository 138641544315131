import { css, keyframes } from 'styled-components';

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(40px) }
  to { opacity: 1; transform: translateX(0px) }
`;
export const slideInAnimation = css`
  animation: ${slideIn}
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;
const slideOut = keyframes`
  from { opacity: 1; transform: translateX(0px) }
  to { opacity: 0; transform: translateX(-40px) }
`;
export const slideOutAnimation = css`
  animation: ${slideOut}
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;

import CurrencyLogo from '../Logo/CurrencyLogo';
import type { Currency } from '@uniswap/sdk-core';

import { cn } from 'utils/cn';

import { AssetLogoSize } from 'components/Logo/AssetLogo';

interface DoubleCurrencyLogoProps {
  margin?: boolean;
  size?: AssetLogoSize;
  currency0?: Currency;
  currency1?: Currency;
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = AssetLogoSize.lg,
  margin = false
}: DoubleCurrencyLogoProps) {
  const left = {
    [AssetLogoSize.xs]: '-left-1.5',
    [AssetLogoSize.sm]: '-left-1.5',
    [AssetLogoSize.md]: '-left-1.5',
    [AssetLogoSize.lg]: '-left-3',
    [AssetLogoSize.xl]: '-left-4.5',
    [AssetLogoSize.xxl]: '-left-6',
    [AssetLogoSize.xxxl]: '-left-8'
  };

  const marginLeft = margin
    ? {
        [AssetLogoSize.xs]: 'ml-3',
        [AssetLogoSize.sm]: 'ml-3',
        [AssetLogoSize.md]: 'ml-3',
        [AssetLogoSize.lg]: 'ml-4',
        [AssetLogoSize.xl]: 'ml-5',
        [AssetLogoSize.xxl]: 'ml-6',
        [AssetLogoSize.xxxl]: 'ml-7'
      }
    : {};

  return (
    <div className={cn('relative flex flex-row', marginLeft[size])}>
      {currency0 && (
        <div className='z-1'>
          <CurrencyLogo currency={currency0} size={size} />
        </div>
      )}
      {currency1 && (
        <div className={cn('absolute', left)}>
          <CurrencyLogo currency={currency1} size={size} />
        </div>
      )}
    </div>
  );
}

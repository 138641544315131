import type { DesignSizeProps } from './Container.types';
import { Container } from './constants';

import { cn } from 'utils/cn';

import { CornerShape, SlopeShape } from 'components/ui/shapes';

const InnerInwardDesign = ({ className, size }: DesignSizeProps) => (
  <div className={cn('flex flex-row', className)}>
    <CornerShape container={Container.INNER} className='fill-blue-900' />

    <div className='h-6.75 flex flex-1 bg-blue-900' />

    <SlopeShape container={Container.INNER} className='fill-blue-900' />

    <div className={cn('flex', size)} />

    <SlopeShape
      container={Container.INNER}
      className='scale-x-mirror fill-blue-900'
    />

    <div className='h-6.75 flex flex-1 bg-blue-900' />

    <CornerShape
      container={Container.INNER}
      className='scale-x-mirror fill-blue-900'
    />
  </div>
);

export default InnerInwardDesign;

import {
  EthereumMainnet,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import { ChainId, Percent } from '@uniswap/sdk-core';
import { USDT as USDT_MAINNET } from 'constants/tokens';

export enum ConfirmModalState {
  REVIEWING,
  WRAPPING,
  RESETTING_TOKEN_ALLOWANCE,
  APPROVING_TOKEN,
  PERMITTING,
  PENDING_CONFIRMATION
}

export enum PendingModalError {
  TOKEN_APPROVAL_ERROR,
  PERMIT_ERROR,
  CONFIRMATION_ERROR,
  WRAP_ERROR
}

export enum SwapLineItemType {
  EXCHANGE_RATE,
  NETWORK_COST,
  INPUT_TOKEN_FEE_ON_TRANSFER,
  OUTPUT_TOKEN_FEE_ON_TRANSFER,
  PRICE_IMPACT,
  MAX_SLIPPAGE,
  SWAP_FEE,
  MAXIMUM_INPUT,
  MINIMUM_OUTPUT,
  ROUTING_INFO
}

// List of tokens that require existing allowance to be reset before approving the new amount (mainnet only).
// See the `approve` function here: https://etherscan.io/address/0xdAC17F958D2ee523a2206206994597C13D831ec7#code
export const RESET_APPROVAL_TOKENS = [USDT_MAINNET];

export const CHAIN_ID_TO_SWAP_FEE: Record<number, Percent> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: new Percent(
    OasisSapphireMainnet.feePercent,
    10000
  ),
  [ChainId.OASIS_SAPPHIRE_TESTNET]: new Percent(
    OasisSapphireTestnet.feePercent,
    10000
  ),
  [ChainId.MAINNET]: new Percent(EthereumMainnet.feePercent, 10000),
  [ChainId.SEPOLIA]: new Percent(Sepolia.feePercent, 10000)
};

import {
  ApplicationModal,
  type PopupContent,
  type PopupType,
  addPopup,
  addSuppressedPopups,
  removePopup,
  removeSuppressedPopups,
  setOpenModal
} from './reducer';
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc';
import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'state/hooks';

import type { AppState } from 'state/reducer';

export function useModalIsOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector(
    (state: AppState) => state.application.openModal
  );
  return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const isOpen = useModalIsOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(
    () => dispatch(setOpenModal(isOpen ? null : modal)),
    [dispatch, modal, isOpen]
  );
}

export function useCloseModal() {
  const dispatch = useAppDispatch();
  const currentlyOpenModal = useAppSelector(
    (state: AppState) => state.application.openModal
  );
  return useCallback(
    (modalToClose?: ApplicationModal) => {
      if (!modalToClose) {
        // Close any open modal if no modal is specified.
        dispatch(setOpenModal(null));
      } else if (currentlyOpenModal === modalToClose) {
        // Close the currently open modal if it is the one specified.
        dispatch(setOpenModal(null));
      }
    },
    [currentlyOpenModal, dispatch]
  );
}

export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS);
}

export function useToggleDelegateModal(): () => void {
  return useToggleModal(ApplicationModal.DELEGATE);
}

export function useToggleQueueModal(): () => void {
  return useToggleModal(ApplicationModal.QUEUE);
}

export function useToggleFeatureFlags(): () => void {
  return useToggleModal(ApplicationModal.FEATURE_FLAGS);
}

// returns a function that allows adding a popup
export function useAddPopup(): (
  content: PopupContent,
  key?: string,
  removeAfterMs?: number
) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number) => {
      dispatch(
        addPopup({
          content,
          key,
          removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS
        })
      );
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useAppSelector((state: AppState) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}

// returns functions to suppress and unsuppress popups by type
export function useSuppressPopups(popupTypes: PopupType[]): {
  suppressPopups: () => void;
  unsuppressPopups: () => void;
} {
  const dispatch = useAppDispatch();
  const suppressPopups = useCallback(
    () => dispatch(addSuppressedPopups({ popupTypes })),
    [dispatch, popupTypes]
  );
  const unsuppressPopups = useCallback(
    () => dispatch(removeSuppressedPopups({ popupTypes })),
    [dispatch, popupTypes]
  );

  return {
    suppressPopups,
    unsuppressPopups
  };
}

import { CloseIcon } from 'components/Icons';
import { LoadingIndicatorOverlay } from 'components/composed/Modal/Swap/Logos';

type ConfirmationPendingContentProps = {
  onDismiss: () => void;
  pendingText: React.ReactNode;
};

export const ConfirmationPendingContent = ({
  onDismiss,
  pendingText
}: ConfirmationPendingContentProps) => (
  <div className='relative flex flex-col items-center justify-center gap-2 p-3'>
    <CloseIcon
      onClick={onDismiss}
      className='absolute -top-2.5 right-4 cursor-pointer stroke-gray-600 transition-colors duration-300 hover:stroke-blue-200'
    />

    <LoadingIndicatorOverlay />

    <span className='mt-3 text-gray-600'>Waiting for confirmation</span>

    <span className='text-white'>{pendingText}</span>

    <span className='text-gray-600'>
      Confirm this transaction in your wallet
    </span>
  </div>
);

import { DEFAULT_LOCALE } from 'constants/locales';
import React, { forwardRef } from 'react';

import { escapeRegExp } from 'utils';
import { cn } from 'utils/cn';

function localeUsesComma(): boolean {
  const decimalSeparator = new Intl.NumberFormat(DEFAULT_LOCALE).format(1.1)[1];

  return decimalSeparator === ',';
}

const inputRegex = RegExp('^\\d*(?:\\\\[.])?\\d*$'); // match escaped "." characters via in a non-capturing group

interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  value: string | number;
  onUserInput: (input: string) => void;
  error?: boolean;
  disabled?: boolean;
  prependSymbol?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      error,
      disabled,
      onUserInput,
      placeholder,
      prependSymbol,
      className,
      ...rest
    }: InputProps,
    ref
  ) => {
    const enforcer = (nextUserInput: string) => {
      if (
        nextUserInput === '' ||
        inputRegex.test(escapeRegExp(nextUserInput))
      ) {
        onUserInput(nextUserInput);
      }
    };

    const formatValueWithLocale = (value: string | number) => {
      const [searchValue, replaceValue] = localeUsesComma()
        ? [/\./g, ',']
        : [/,/g, '.'];
      return value.toString().replace(searchValue, replaceValue);
    };

    const valueFormattedWithLocale = formatValueWithLocale(value);

    return (
      <input
        {...rest}
        ref={ref}
        className={cn(
          'text-overflow-ellipsis relative w-0 flex-auto overflow-hidden whitespace-nowrap border-none bg-transparent p-0 text-gray-800 outline-none placeholder:text-gray-800',
          className,
          error && 'text-red',
          disabled ? 'pointer-events-none' : 'pointer-events-auto'
        )}
        value={
          prependSymbol && value
            ? prependSymbol + valueFormattedWithLocale
            : valueFormattedWithLocale
        }
        onChange={(event) => {
          if (prependSymbol) {
            const value = event.target.value;

            // cut off prepended symbol
            const formattedValue = value.toString().includes(prependSymbol)
              ? value.toString().slice(1, value.toString().length + 1)
              : value;

            // replace commas with periods, because uniswap exclusively uses period as the decimal separator
            enforcer(formattedValue.replace(/,/g, '.'));
          } else {
            enforcer(event.target.value.replace(/,/g, '.'));
          }
        }}
        // universal input options
        inputMode='decimal'
        autoComplete='off'
        autoCorrect='off'
        // text-specific options
        type='text'
        pattern='^[0-9]*[.,]?[0-9]*$'
        placeholder={placeholder || '0'}
        minLength={1}
        maxLength={79}
        spellCheck='false'
      />
    );
  }
);

Input.displayName = 'Input';

const MemoizedInput = React.memo(Input);
export { MemoizedInput as Input };
// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

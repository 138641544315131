import { Percent } from '@uniswap/sdk-core';
import JSBI from 'jsbi';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

// TODO(WEB-1984): Convert the deadline to minutes and remove unecessary conversions from
// seconds to minutes in the codebase.
// 10 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 10;

// transaction popup dismissal amounts
export const DEFAULT_TXN_DISMISS_MS = 10000;

export const BIG_INT_ZERO = JSBI.BigInt(0);

export const BIPS_BASE = 10_000;

// one basis JSBI.BigInt
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(1, 100); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(3, 100); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(5, 100); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  10,
  100
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(15, 100); // 15%

export const ZERO_PERCENT = new Percent(0);
export const ONE_HUNDRED_PERCENT = new Percent(1);

export const TELEGRAM_URL = 'https://t.me/NEBYExchange';

import { useENSRegistrarContract, useENSResolverContract } from './useContract';
import useDebounce from './useDebounce';
import useENSName from './useENSName';
import { useMemo } from 'react';

import { isAddress } from '../utils';
import isZero from '../utils/isZero';
import uriToHttp from 'lib/utils/uriToHttp';
import { safeNamehash } from 'utils/safeNamehash';

import { NEVER_RELOAD, useMainnetSingleCallResult } from 'lib/hooks/multicall';

/**
 * Returns the ENS avatar URI, if available.
 * Spec: https://gist.github.com/Arachnid/9db60bd75277969ee1689c8742b75182.
 */
export default function useENSAvatar(address?: string): {
  avatar: string | null;
  loading: boolean;
} {
  const debouncedAddress = useDebounce(address, 200);
  const node = useMemo(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) return undefined;
    return safeNamehash(
      `${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`
    );
  }, [debouncedAddress]);

  const addressAvatar = useAvatarFromNode(node);
  const ENSName = useENSName(address).ENSName;
  const nameAvatar = useAvatarFromNode(
    ENSName === null ? undefined : safeNamehash(ENSName)
  );
  const avatar = addressAvatar.avatar || nameAvatar.avatar;

  const http = avatar && uriToHttp(avatar)[0];

  const changed = debouncedAddress !== address;
  return useMemo(
    () => ({
      avatar: changed ? null : http ?? null,
      loading: changed || addressAvatar.loading || nameAvatar.loading
    }),
    [addressAvatar.loading, changed, http, nameAvatar.loading]
  );
}

function useAvatarFromNode(node?: string): {
  avatar?: string;
  loading: boolean;
} {
  const nodeArgument = useMemo(() => [node], [node]);
  const textArgument = useMemo(() => [node, 'avatar'], [node]);
  const registrarContract = useENSRegistrarContract();
  const resolverAddress = useMainnetSingleCallResult(
    registrarContract,
    'resolver',
    nodeArgument,
    NEVER_RELOAD
  );
  const resolverAddressResult = resolverAddress.result?.[0];
  const resolverContract = useENSResolverContract(
    resolverAddressResult && !isZero(resolverAddressResult)
      ? resolverAddressResult
      : undefined
  );
  const avatar = useMainnetSingleCallResult(
    resolverContract,
    'text',
    textArgument,
    NEVER_RELOAD
  );

  return useMemo(
    () => ({
      avatar: avatar.result?.[0],
      loading: resolverAddress.loading || avatar.loading
    }),
    [avatar.loading, avatar.result, resolverAddress.loading]
  );
}

import { Alert, AlertDescription } from './Alert';
import { type AlertState, AlertType } from './constants';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from 'utils/cn';

import { InfoIcon, WarningIcon } from 'components/Icons';

const variants = {
  initial: {
    opacity: 0,
    y: 50,
    scale: 0.2,
    transition: { duration: 0.2 }
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1
  },
  exit: {
    opacity: 0,
    scale: 0.2,
    transition: { ease: 'easeOut', duration: 0.3 }
  }
};

type AlertStackProps = {
  alerts: AlertState[];
  position: 'center' | 'default';
  className?: string;
  type?: 'default' | 'responsive' | null | undefined;
  hideIcon?: boolean;
};

export const AlertStack = ({
  alerts,
  className,
  position = 'default',
  type = 'responsive',
  hideIcon = false
}: AlertStackProps) => (
  <div className={cn('z-1000 flex flex-col gap-2', className)}>
    <AnimatePresence initial={false}>
      {alerts
        .filter((alert) => alert.show)
        .map((alert: AlertState, index: number) => (
          <motion.div
            key={index}
            variants={variants}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            <Alert position={position} variant={alert.type} type={type}>
              {!hideIcon &&
                (alert.type === AlertType.Error ||
                  alert.type === AlertType.Warning) && <WarningIcon />}

              {!hideIcon && alert.type === AlertType.Info && <InfoIcon />}

              <AlertDescription>{alert.message}</AlertDescription>
            </Alert>
          </motion.div>
        ))}
    </AnimatePresence>
  </div>
);

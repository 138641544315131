// High-traffic pages (index and /swap) should not be lazy-loaded.
import Swap from './Swap';
import type { ReactNode } from 'react';
import { Suspense, lazy } from 'react';
import { Navigate, matchPath } from 'react-router-dom';

import { routes as routeList } from 'utils/routes';

const AddLiquidityWithTokenRedirects = lazy(
  () => import('pages/AddLiquidity/redirects')
);

const NotFound = lazy(() => import('pages/NotFound'));
const Pool = lazy(() => import('pages/Pool'));
const PoolDetails = lazy(() => import('pages/PoolDetails'));
const MyPosition = lazy(() => import('pages/MyPosition'));
const IncreaseLiquidity = lazy(() => import('pages/IncreaseLiquidity/index'));
const RemoveLiquidity = lazy(() => import('pages/RemoveLiquidity'));
const Bridge = lazy(() => import('pages/Bridge'));
const Stake = lazy(() => import('pages/Stake'));
const StakeCosmic = lazy(() => import('pages/StakeCosmic'));
const Cosmic = lazy(() => import('pages/Cosmic'));
const Incentivize = lazy(() => import('pages/Incentivize'));
const Whitelist = lazy(() => import('pages/Whitelist'));
const Sale = lazy(() => import('pages/Sale'));

interface RouterConfig {
  hash?: string;
}

export interface RouteDefinition {
  path: string;
  nestedPaths: string[];
  getTitle: (path?: string) => string;
  getElement: (args: RouterConfig) => ReactNode;
}

const defaultTabTitle = 'NEBY – Next-Gen DEX on the Oasis Network';

// Assigns the defaults to the route definition.
const createRouteDefinition = (
  route: Partial<RouteDefinition>
): RouteDefinition => ({
  getElement: () => null,
  getTitle: () => defaultTabTitle,
  path: '/',
  nestedPaths: [],
  // overwrite the defaults
  ...route
});

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: routeList.home,
    getTitle: () => defaultTabTitle,
    getElement: ({ hash }) =>
      hash ? <Navigate to={hash.replace('#', '')} replace /> : <Swap />
  }),
  createRouteDefinition({
    path: routeList.bridge,
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <Bridge />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/swap',
    getElement: () => <Swap />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({ path: '/pool', getElement: () => <Pool /> }),
  createRouteDefinition({
    path: '/pool/:address',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: routeList.pools,
    getElement: () => <Pool />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/pools/:address',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/add-liquidity',
    nestedPaths: [
      ':currencyIdA',
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:feeAmount',
      ':currencyIdA/:currencyIdB/:feeAmount/:tokenId'
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/increase-liquidity/:currencyIdA/:currencyIdB/:feeAmount/:tokenId',
    getElement: () => <IncreaseLiquidity />,
    getTitle: () => 'Increase Liquidity to Pools'
  }),
  createRouteDefinition({
    path: '/my-position/:tokenId',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <MyPosition />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/remove-liquidity/:tokenId',
    getElement: () => <RemoveLiquidity />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/stake-cosmic',
    nestedPaths: [
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:tokenId'
    ],
    getElement: () => <StakeCosmic />,
    getTitle: () => 'Stake in Cosmic'
  }),
  createRouteDefinition({
    path: '/stake',
    getElement: () => <Stake />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/cosmic',
    getElement: () => <Cosmic />,
    getTitle: () => 'Cosmic'
  }),
  createRouteDefinition({
    path: '/incentivize',
    getElement: () => <Incentivize />,
    getTitle: () => 'Incentivize'
  }),
  createRouteDefinition({
    path: '/whitelist',
    getElement: () => <Whitelist />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/sale',
    getElement: () => <Sale />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '*',
    getElement: () => <Navigate to='/not-found' replace />
  }),
  createRouteDefinition({ path: '/not-found', getElement: () => <NotFound /> })
];

export const findRouteByPath = (pathname: string) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return route;
    }
    const subPaths = route.nestedPaths.map(
      (nestedPath) => `${route.path}/${nestedPath}`
    );
    for (const subPath of subPaths) {
      const match = matchPath(subPath, pathname);
      if (match) {
        return route;
      }
    }
  }
  return undefined;
};

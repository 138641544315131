import type { FC } from 'react';

import { cn } from 'utils/cn';

import { SlopeShape } from 'components/ui/shapes';

import type { DesignSizeProps } from 'components/composed/ContainerShapes/Container.types';

const InnerOutwardDesign: FC<DesignSizeProps> = ({ className, size }) => (
  <div className={cn('flex flex-row', className)}>
    <div className='flex flex-grow' />

    <SlopeShape className='h-6.75 w-auto scale-x-mirror fill-blue-900' />

    <div className={cn('h-6.75 flex bg-blue-900', size)} />

    <SlopeShape className='h-6.75 w-auto fill-blue-900' />

    <div className='flex flex-grow' />
  </div>
);

export default InnerOutwardDesign;

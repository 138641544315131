import { ActivityTab } from './Activity';
import Pools from './Pools';
import Tokens from './Tokens';
import { useEffect, useState } from 'react';

import { cn } from 'utils/cn';

import { usePendingActivity } from './Activity/hooks';

import Spinner from 'components/Icons/Spinner';

interface Page {
  title: string;
  key: string;
  component: ({ account }: { account: string }) => JSX.Element;
}

const Pages: Array<Page> = [
  {
    title: 'Tokens',
    key: 'tokens',
    component: Tokens
  },
  {
    title: 'Pools',
    key: 'pools',
    component: Pools
  },
  {
    title: 'Activity',
    key: 'activity',
    component: ActivityTab
  }
];

const MiniPortfolio = ({ account }: { account: string }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const [activityUnread, setActivityUnread] = useState(false);

  const { component: Page, key: currentKey } = Pages[currentPage];

  const { hasPendingActivity } = usePendingActivity();

  useEffect(() => {
    if (hasPendingActivity && currentKey !== 'activity')
      setActivityUnread(true);
  }, [currentKey, hasPendingActivity]);

  return (
    <div className='flex h-full flex-col'>
      <div className='flex w-full flex-row gap-6'>
        {Pages.map(({ title, key }, index) => {
          const isUnselectedActivity =
            key === 'activity' && currentKey !== 'activity';
          const showActivityIndicator =
            isUnselectedActivity && (hasPendingActivity || activityUnread);
          const handleNavItemClick = () => {
            setCurrentPage(index);
            if (key === 'activity') setActivityUnread(false);
          };

          return (
            <div
              className={cn(
                'flex cursor-pointer items-center justify-between',
                'transition-colors duration-300',
                'text-base font-medium',
                'hover:text-gray-100',
                {
                  'text-gray-600': currentPage !== index,
                  'text-gray-100': currentPage === index
                }
              )}
              onClick={handleNavItemClick}
              key={key}
            >
              <span>{title}</span>
              {showActivityIndicator && (
                <>
                  &nbsp;
                  {hasPendingActivity ? (
                    <Spinner className='duration-2000 size-6 stroke-blue-200' />
                  ) : (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx='4' cy='4' r='4' fill='#AAC1FF' />
                    </svg>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>

      <div className='w-full flex-1 rounded-xl'>
        <Page account={account} />
      </div>
    </div>
  );
};

export default MiniPortfolio;

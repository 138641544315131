import type { ComponentProps } from 'react';

export const XCircleIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    {...props}
  >
    <g>
      <path
        d='M10.5 6.00016L6.50004 10.0002M6.50004 6.00016L10.5 10.0002M15.1667 8.00016C15.1667 11.6821 12.1819 14.6668 8.50004 14.6668C4.81814 14.6668 1.83337 11.6821 1.83337 8.00016C1.83337 4.31826 4.81814 1.3335 8.50004 1.3335C12.1819 1.3335 15.1667 4.31826 15.1667 8.00016Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

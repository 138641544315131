import type { Percent } from '@uniswap/sdk-core';

import { useFormatter } from 'utils/formatNumbers';

interface PriceImpactWarningProps {
  priceImpact: Percent;
}

export const PriceImpactWarning = ({
  priceImpact
}: PriceImpactWarningProps) => {
  const { formatPercent } = useFormatter();

  return (
    <div className='flex flex-row items-center justify-between gap-2'>
      <span>Price impact warning</span>
      <span>~{formatPercent(priceImpact)}</span>
    </div>
  );
};

import type { ChainId, Currency } from '@uniswap/sdk-core';
import { DEFAULT_CHAIN_ID } from 'constants/chains';
import { type ReactNode, useMemo, useState } from 'react';

import { asSupportedChain } from 'utils/chains';

import { useCurrency } from 'hooks/Tokens';
import useParsedQueryString from 'hooks/useParsedQueryString';
import { useWeb3React } from 'hooks/useWeb3React';
import { queryParametersToCurrencyState } from 'state/swap/hooks';

import { AlertStack } from 'components/ui/Alert/AlertStack';
import { type AlertState, AlertType } from 'components/ui/Alert/constants';

import { SwapForm } from 'components/composed/Swap/SwapForm';
import SwapWrapper from 'components/composed/Swap/SwapWrapper';

import {
  type CurrencyState,
  SwapAndLimitContext,
  SwapAndLimitContextProvider,
  SwapContextProvider
} from 'state/swap/SwapContext';

const PageWrapper = ({ children }: { children: ReactNode }) => (
  <div className='w-full max-w-120 px-5 pt-9'>{children}</div>
);

const SwapPage = () => {
  const { chainId: connectedChainId } = useWeb3React();
  const supportedChainId = asSupportedChain(connectedChainId);
  const chainId = supportedChainId || DEFAULT_CHAIN_ID;

  const parsedQs = useParsedQueryString();
  const parsedCurrencyState = useMemo(
    () => queryParametersToCurrencyState(parsedQs),
    [parsedQs]
  );

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId);
  const initialOutputCurrency = useCurrency(
    parsedCurrencyState.outputCurrencyId
  );

  return (
    <PageWrapper>
      <Swap
        chainId={chainId}
        disableTokenInputs={supportedChainId === undefined}
        initialInputCurrency={initialInputCurrency}
        initialOutputCurrency={initialOutputCurrency}
      />
    </PageWrapper>
  );
};

export default SwapPage;

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export const Swap = ({
  initialInputCurrency,
  initialOutputCurrency,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false
}: {
  className?: string;
  chainId?: ChainId;
  onCurrencyChange?: (selected: CurrencyState) => void;
  disableTokenInputs?: boolean;
  initialInputCurrency?: Currency;
  initialOutputCurrency?: Currency;
}) => {
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: '',
    type: AlertType.Info
  });

  const [priceImpactAlert, setPriceImpactAlert] = useState<AlertState>({
    show: false,
    message: '',
    type: AlertType.Info
  });

  return (
    <SwapAndLimitContextProvider
      chainId={chainId}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
    >
      {/* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */}
      <SwapAndLimitContext.Consumer>
        {() => (
          <SwapContextProvider>
            <SwapWrapper id='swap-page'>
              <SwapForm
                onCurrencyChange={onCurrencyChange}
                disableTokenInputs={disableTokenInputs}
                setAlert={setAlert}
                setPriceImpactAlert={setPriceImpactAlert}
              />
            </SwapWrapper>

            <AlertStack
              alerts={[alert, priceImpactAlert]}
              position='center'
              className='fixed inset-x-5 bottom-18.5 md:relative md:inset-0 md:bottom-0 md:mt-9'
            />
          </SwapContextProvider>
        )}
      </SwapAndLimitContext.Consumer>
    </SwapAndLimitContextProvider>
  );
};

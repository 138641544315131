import type { ComponentProps } from 'react';

const Logo = (props: ComponentProps<'svg'>) => (
  <svg
    width='88'
    height='20'
    viewBox='0 0 88 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2041_5925)'>
      <path
        d='M11.134 8.86049H7.10498C6.82609 8.86049 6.59929 9.08661 6.59929 9.36618V16.6719C6.59929 16.8062 6.54584 16.9344 6.45128 17.0296L3.65698 19.8239C3.45964 20.0212 3.13965 20.0212 2.94231 19.8239L0.148006 17.0296C0.0534466 16.935 0 16.8062 0 16.6719V3.31506C0 3.18076 0.0534466 3.05263 0.148006 2.95738L2.94231 0.163082C3.13965 -0.0342597 3.45964 -0.0342597 3.65698 0.163082L11.491 7.99712C11.8096 8.31575 11.5842 8.85981 11.1333 8.85981L11.134 8.86049Z'
        fill='#0C193D'
      />
      <path
        d='M10.0857 11.1265H14.1148C14.3937 11.1265 14.6205 10.9004 14.6205 10.6208V3.31506C14.6205 3.18076 14.6739 3.05263 14.7685 2.95738L17.5621 0.163082C17.7594 -0.0342597 18.0794 -0.0342597 18.2768 0.163082L21.0711 2.95738C21.1656 3.05194 21.2191 3.18076 21.2191 3.31506V16.6719C21.2191 16.8062 21.1656 16.9344 21.0711 17.0296L18.2768 19.8239C18.0794 20.0212 17.7594 20.0212 17.5621 19.8239L9.72806 11.9899C9.40943 11.6712 9.63487 11.1272 10.0857 11.1272V11.1265Z'
        fill='#0C193D'
      />
      <path
        d='M79.1052 3.9345L82.8903 0.149378C82.9849 0.0548185 83.1137 0.00137186 83.248 0.00137186H87.2017C87.4806 0.00137186 87.7074 0.227492 87.7074 0.507059V4.45868C87.7074 4.59298 87.6539 4.72112 87.5594 4.81636L82.1955 10.1782C82.101 10.2727 82.0475 10.4015 82.0475 10.5358V16.7007C82.0475 16.835 81.9941 16.9631 81.8995 17.0584L79.1052 19.852C78.9079 20.0493 78.5879 20.0493 78.3905 19.852L75.5969 17.0584C75.5024 16.9638 75.4489 16.835 75.4489 16.7007V10.5379C75.4489 10.4036 75.3955 10.2755 75.3009 10.1802L69.9371 4.81636C69.8425 4.72181 69.7891 4.59298 69.7891 4.45868V0.506374C69.7891 0.227492 70.0152 0.000686646 70.2948 0.000686646H74.2464C74.3807 0.000686646 74.5088 0.0541333 74.6041 0.148693L78.3919 3.9345C78.5893 4.13184 78.9092 4.13184 79.1066 3.9345H79.1052Z'
        fill='#0C193D'
      />
      <path
        d='M40.3008 13.5487L43.0944 16.3423C43.2918 16.5397 43.2918 16.8597 43.0944 17.057L40.2981 19.8513C40.2035 19.9459 40.0747 19.9993 39.9404 19.9993H28.7097C28.5761 19.9993 28.448 19.9466 28.3534 19.852L25.6235 17.1358C25.5283 17.0413 25.4749 16.9117 25.4749 16.7774V10.5112C25.4749 10.2323 25.701 10.0055 25.9805 10.0055H30.9593C31.4095 10.0055 31.6349 9.46074 31.317 9.1428L25.6962 3.52063C25.4988 3.32328 25.4988 3.00329 25.6962 2.80595L28.3534 0.148006C28.4487 0.0534466 28.5768 0 28.7111 0H39.9431C40.0774 0 40.2056 0.0534466 40.3008 0.148006L43.0944 2.94231C43.2918 3.13965 43.2918 3.45964 43.0944 3.65698L40.3008 6.4506C40.2063 6.54516 40.0774 6.5986 39.9431 6.5986H36.5931C36.1429 6.5986 35.9175 7.14266 36.2354 7.46129L38.4144 9.64232C38.6118 9.83966 38.6118 10.1597 38.4144 10.357L36.2354 12.538C35.9175 12.8567 36.1429 13.4007 36.5931 13.4007H39.9431C40.0774 13.4007 40.2056 13.4542 40.3008 13.5487Z'
        fill='#0C193D'
      />
      <path
        d='M62.0234 10.3563L66.3088 14.643C66.5061 14.8403 66.5061 15.1603 66.3088 15.3577L61.8151 19.8513C61.7206 19.9459 61.5918 19.9993 61.4575 19.9993H46.8878C46.4376 19.9993 46.2122 19.4546 46.5301 19.1366L52.118 13.5487C52.2126 13.4542 52.3414 13.4007 52.4757 13.4007H55.1165C55.2508 13.4007 55.3789 13.3473 55.4735 13.2527L58.3699 10.3591C58.5672 10.1617 58.5672 9.84172 58.3699 9.64438L55.4735 6.74729C55.3789 6.65274 55.2501 6.59929 55.1158 6.59929H52.475C52.3407 6.59929 52.2126 6.54584 52.1173 6.45128L46.5322 0.86337C46.2135 0.544746 46.4396 0.000686646 46.8898 0.000686646H61.4561C61.5904 0.000686646 61.7185 0.0541333 61.8138 0.148693L66.3081 4.64301C66.5054 4.84035 66.5054 5.16034 66.3081 5.35768L62.0234 9.64164C61.8261 9.83898 61.8261 10.159 62.0234 10.3563Z'
        fill='#0C193D'
      />
    </g>
    <defs>
      <clipPath id='clip0_2041_5925'>
        <rect width='87.7073' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;

import React from 'react';

import { cn } from 'utils/cn';

import * as SwitchPrimitives from '@radix-ui/react-switch';
import { EdgeShape } from 'components/ui/shapes';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <div className='relative flex h-5.75 w-9.5 flex-row'>
    <SwitchPrimitives.Root
      className={cn(
        'mx-3 h-5.75 w-auto',
        'transition-colors duration-500',
        'peer inline-flex shrink-0 cursor-pointer items-center focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        'data-[state=checked]:bg-blue-200 data-[state=unchecked]:bg-blue-800',
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none z-10 size-3.75 rounded-minor shadow-lg ring-0',
          'data-[state=checked]:bg-blue-900 data-[state=unchecked]:bg-blue-600',
          'transition duration-500',
          'data-[state=checked]:rotate-45 data-[state=unchecked]:-rotate-45',
          'data-[state=checked]:translate-x-1.75 data-[state=unchecked]:-translate-x-1.75',
          'data-[state=checked]:drop-shadow-softMedium data-[state=unchecked]:drop-shadow-softLarge'
        )}
      />
    </SwitchPrimitives.Root>

    <EdgeShape
      className={cn(
        'absolute left-0 h-5.75 w-3 transition-colors duration-500',
        'peer-data-[state=checked]:fill-blue-200 peer-data-[state=unchecked]:fill-blue-800'
      )}
      size='sm'
    />

    <EdgeShape
      className={cn(
        'absolute right-0 h-5.75 w-3 rotate-180 transition-colors duration-500',
        'peer-data-[state=checked]:fill-blue-200 peer-data-[state=unchecked]:fill-blue-800'
      )}
      size='sm'
    />

    <div
      className='absolute bottom-0 left-0 right-0 top-0 cursor-pointer'
      onClick={() => props.onCheckedChange?.(!props.checked)}
    />
  </div>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };

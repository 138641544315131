import { PortfolioSkeleton } from '../PortfolioRow';
import { ActivityRow } from './ActivityRow';
import { atom, useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

import { createGroups } from './utils';
import { useAtomValue } from 'jotai/utils';
import { isSupportedChain } from 'utils/chains';

import { useAllActivities } from './hooks';

import { SecondaryLargeText } from 'components/ui/Text';

import { useAccountDrawer } from 'components/AccountDrawer';
import { EmptyWalletModule } from 'components/AccountDrawer/MiniPortfolio/EmptyWalletModule';
import { hideSpamAtom } from 'components/AccountDrawer/SpamToggle';
import { LoadingBubble } from 'components/Tokens/loading';

import { PollingInterval } from 'graphql/data/util';

const lastFetchedAtom = atom<number | undefined>(0);

export const ActivityTab = ({ account }: { account: string }) => {
  const [drawerOpen, toggleWalletDrawer] = useAccountDrawer();
  const [lastFetched, setLastFetched] = useAtom(lastFetchedAtom);
  const hideSpam = useAtomValue(hideSpamAtom);

  const { activities, loading, refetch } = useAllActivities(account);

  // We only refetch remote activity if the user renavigates to the activity tab by changing tabs or opening the drawer
  useEffect(() => {
    const currentTime = Date.now();
    if (!lastFetched) {
      setLastFetched(currentTime);
    } else if (
      drawerOpen &&
      lastFetched &&
      currentTime - lastFetched > PollingInterval.Slow
    ) {
      refetch();
      setLastFetched(currentTime);
    }
  }, [drawerOpen, lastFetched, refetch, setLastFetched]);

  const activityGroups = useMemo(() => createGroups(activities), [activities]);

  const supportedActivityGroups = useMemo(
    () =>
      activityGroups?.filter((group) => {
        if (group.transactions.length > 0) {
          const chainIds = group.transactions.map(
            (transaction) => transaction.chainId
          );
          return chainIds.every((chainId) => isSupportedChain(chainId));
        }
        return true;
      }),
    [activityGroups]
  );

  if (!supportedActivityGroups && loading) {
    return (
      <>
        <LoadingBubble height='16px' width='80px' margin='16px 16px 8px' />
        <PortfolioSkeleton shrinkRight />
      </>
    );
  } else if (
    !supportedActivityGroups ||
    supportedActivityGroups?.length === 0
  ) {
    return (
      <EmptyWalletModule type='activity' onNavigateClick={toggleWalletDrawer} />
    );
  } else {
    return (
      <div className='animate-fade-in transition duration-300'>
        {supportedActivityGroups.map((activityGroup) => (
          <div
            className='mt-4 flex flex-col justify-start gap-2'
            key={activityGroup.title}
          >
            <SecondaryLargeText>{activityGroup.title}</SecondaryLargeText>

            <div className='flex flex-col justify-start'>
              {activityGroup.transactions.map(
                (activity) =>
                  !(hideSpam && activity.isSpam) && (
                    <ActivityRow key={activity.hash} activity={activity} />
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

import {
  type RouteDefinition,
  findRouteByPath,
  routes
} from './RouteDefinitions';
import { type ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { isPathBlocked } from 'utils/blockedPaths';
import { cn } from 'utils/cn';

import { useAppSelector } from 'state/hooks';

import Background from 'components/ui/Background';

import Loader from 'components/Icons/LoadingSpinner';
import { Header } from 'components/composed/Header';
import { MobileBottomNavigation } from 'components/composed/navigation';

import type { AppState } from 'state/reducer';

const AppChrome = lazy(() => import('./AppChrome'));

const BodyWrapper = ({ children }: { children: ReactNode }) => (
  <div
    className={cn(
      'relative z-10 flex w-full flex-1 flex-col items-center',
      'min-h-screen px-0 py-20'
    )}
  >
    {children}
  </div>
);

export default function App() {
  const [scrollY, setScrollY] = useState(0);
  const scrolledState = scrollY > 0;

  const location = useLocation();
  const { pathname, hash } = location;

  const originCountry = useAppSelector(
    (state: AppState) => state.user.originCountry
  );
  const renderUkBannner = Boolean(originCountry) && originCountry === 'GB';

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollY(0);
  }, [pathname]);

  useEffect(() => {
    const scrollListener = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  const isHeaderTransparent = !scrolledState;

  // #region Whitelist
  const shouldRedirectToWhitelist =
    process.env.REACT_APP_WHITELIST_REDIRECT == 'true' &&
    !pathname.startsWith('/whitelist') &&
    !pathname.startsWith('/sale');

  if (shouldRedirectToWhitelist) {
    return <Navigate to='/whitelist' replace />;
  }

  // const shouldRedirectFromWhitelist =
  //   process.env.REACT_APP_WHITELIST_REDIRECT != 'true' &&
  //   pathname.startsWith('/whitelist');

  // if (shouldRedirectFromWhitelist) {
  //   return <Navigate to='/' replace />;
  // }
  // #endregion Whitelist

  const shouldBlockPath = isPathBlocked(pathname);
  if (shouldBlockPath && pathname !== '/swap') {
    return <Navigate to='/swap' replace />;
  }
  return (
    <>
      <Helmet>
        <title>
          {findRouteByPath(pathname)?.getTitle(pathname) ?? 'Interface'}
        </title>
      </Helmet>

      <Header
        transparent={isHeaderTransparent}
        bannerIsVisible={renderUkBannner}
      />

      <Background />

      <BodyWrapper>
        <Suspense>
          <AppChrome />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map((route: RouteDefinition) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.getElement({ hash })}
              >
                {route.nestedPaths.map((nestedPath) => (
                  <Route
                    path={nestedPath}
                    key={`${route.path}/${nestedPath}`}
                  />
                ))}
              </Route>
            ))}
          </Routes>
        </Suspense>
      </BodyWrapper>

      <MobileBottomNavigation />
    </>
  );
}

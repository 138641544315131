import type { ExtendedCurrency } from './types';
import type { Currency } from '@uniswap/sdk-core';
import { checkWarning } from 'constants/tokenSafety';
import type { MouseEvent } from 'react';

import { getIsMetaMaskWallet } from 'connection/utils';
import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';

import type { TokenFromList } from 'state/lists/tokenFromList';

type CurrencyItemProps = {
  currency: ExtendedCurrency;
  onSelect: (currency: Currency, hasWarning: boolean) => void;
};

export const CurrencyItem = ({ currency, onSelect }: CurrencyItemProps) => {
  const { token, balance } = currency;
  const { name, symbol } = token;

  const warning = token.isNative ? null : checkWarning(token.address);
  const isBlockedToken = !!warning && !warning.canProceed;

  const isMetaMask = getIsMetaMaskWallet();

  const { formatCurrencyAmount } = useFormatter();

  const formattedBalance = formatCurrencyAmount({
    amount: balance,
    type: NumberType.TokenNonTx
  });

  const handleSelect = () => currency && onSelect(token, !!warning);

  const handleImport = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (window.ethereum && isMetaMask) {
      try {
        const tokenFromList = token.wrapped as TokenFromList;

        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenFromList.address,
              symbol: tokenFromList.symbol,
              decimals: tokenFromList.decimals,
              image: tokenFromList.logoURI
            }
          }
        });
      } catch (error) {
        console.error('Failed to add token to MetaMask:', error);
      }
    }
  };

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-between text-white',
        'cursor-pointer'
      )}
      onClick={handleSelect}
    >
      <div className='flex flex-row items-center gap-3'>
        <CurrencyLogo
          currency={token}
          size={AssetLogoSize.xl}
          className={cn({
            'opacity-60': isBlockedToken
          })}
        />

        <div className='flex flex-col gap-0.5'>
          <span className='text-base font-medium'>{name}</span>

          <span className='text-xs font-normal text-gray-600'>{symbol}</span>
        </div>

        {isMetaMask && (
          <button
            className={cn(
              'rounded bg-blue-800 p-2 text-xs font-normal text-gray-600',
              'transition-colors duration-300',
              'hover:text-blue-200'
            )}
            onClick={handleImport}
          >
            Import
          </button>
        )}
      </div>

      <div className='flex flex-row'>
        <span className='text-base font-medium'>{formattedBalance}</span>
      </div>
    </div>
  );
};

import DefaultMenu from './DefaultMenu';
import { atom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGesture } from 'react-use-gesture';

import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { cn } from 'utils/cn';

import useDisableScrolling from 'hooks/useDisableScrolling';
import { useIsMobile } from 'hooks/useIsMobile';
import usePrevious from 'hooks/usePrevious';
import { useWindowSize } from 'hooks/useWindowSize';

import { ChevronIcon } from 'components/Icons';

const accountDrawerOpenAtom = atom(false);

export const useToggleAccountDrawer = () => {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom);
  return useCallback(() => {
    updateAccountDrawerOpen((open) => !open);
  }, [updateAccountDrawerOpen]);
};

export const useCloseAccountDrawer = () => {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom);
  return useCallback(
    () => updateAccountDrawerOpen(false),
    [updateAccountDrawerOpen]
  );
};

export const useAccountDrawer = (): [boolean, () => void] => {
  const accountDrawerOpen = useAtomValue(accountDrawerOpenAtom);
  return [accountDrawerOpen, useToggleAccountDrawer()];
};

type ScrimProps = {
  onClick: () => void;
  isOpen: boolean;
};

const Scrim = ({ onClick, isOpen }: ScrimProps) => {
  const { width } = useWindowSize();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (width && width < 640 && isOpen) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen, width]);

  return (
    <div
      className={cn(
        'pointer-events-none fixed left-0 top-0 z-backdrop h-full w-full overflow-hidden bg-blue-900/40 opacity-0',
        {
          'opacity-1 pointer-events-auto transition-opacity duration-300 ease-in-out':
            isMobile && isOpen
        }
      )}
      onClick={onClick}
    />
  );
};

const AccountDrawer = () => {
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer();
  const wasWalletDrawerOpen = usePrevious(walletDrawerOpen);

  const isMobile = useIsMobile();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wasWalletDrawerOpen && !walletDrawerOpen) {
      scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [walletDrawerOpen, wasWalletDrawerOpen]);

  // close on escape keypress
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && walletDrawerOpen) {
        event.preventDefault();
        toggleWalletDrawer();
      }
    };

    document.addEventListener('keydown', escapeKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', escapeKeyDownHandler);
    };
  }, [walletDrawerOpen, toggleWalletDrawer]);

  // useStates for detecting swipe gestures
  const [yPosition, setYPosition] = useState(0);
  const [dragStartTop, setDragStartTop] = useState(true);
  useDisableScrolling(walletDrawerOpen);

  // useGesture hook for detecting swipe gestures
  const bind = useGesture({
    // if the drawer is open and the user is dragging down, close the drawer
    onDrag: (state) => {
      // if the user is dragging up, set dragStartTop to false
      if (state.movement[1] < 0) {
        setDragStartTop(false);
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'auto';
        }
      } else if (
        (state.movement[1] > 300 ||
          (state.velocity > 3 && state.direction[1] > 0)) &&
        walletDrawerOpen &&
        dragStartTop
      ) {
        toggleWalletDrawer();
      } else if (walletDrawerOpen && dragStartTop && state.movement[1] > 0) {
        setYPosition(state.movement[1]);
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'hidden';
        }
      }
    },
    // reset the yPosition when the user stops dragging
    onDragEnd: () => {
      setYPosition(0);
      if (scrollRef.current) {
        scrollRef.current.style.overflowY = 'auto';
      }
    },
    // set dragStartTop to true if the user starts dragging from the top of the drawer
    onDragStart: () => {
      if (!scrollRef.current?.scrollTop || scrollRef.current?.scrollTop < 30) {
        setDragStartTop(true);
      } else {
        setDragStartTop(false);
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'auto';
        }
      }
    }
  });

  return (
    <div
      className={cn(
        'fixed z-fixed flex flex-row',
        'overflow-visible lg:overflow-hidden',
        'top-lvh right-0 lg:right-2 lg:top-2',
        'w-full lg:w-auto'
      )}
      style={{
        height: 'calc(100% - 16px)'
      }}
    >
      {walletDrawerOpen && (
        <div
          className={cn(
            'hidden lg:flex',
            'h-full cursor-pointer',
            'rounded-l-xl',
            '-mr-2 py-6 pl-3 pr-5',
            'transition-colors duration-300',
            'hover:bg-blue-800/40 active:bg-blue-800/20',
            'hover:-z-1'
          )}
          onClick={toggleWalletDrawer}
        >
          <ChevronIcon className='size-6 -rotate-90 stroke-gray-600' />
        </div>
      )}

      <Scrim onClick={toggleWalletDrawer} isOpen={walletDrawerOpen} />

      <div
        className={cn(
          'z-drawer h-full overflow-hidden bg-blue-900',
          'absolute lg:relative',
          'rounded-t-xl lg:rounded-xl',
          'text-base',
          'shadow-lg',
          'transition-top transition-margin duration-300 ease-in-out',
          'w-full lg:w-80 2xl:w-100',
          {
            'mr-0': walletDrawerOpen,
            'mr-0 lg:-mr-80 2xl:-mr-100': !walletDrawerOpen
          }
        )}
        style={{
          top: isMobile && walletDrawerOpen ? 'calc(-1 * (100% - 72px))' : 0,
          transform: isMobile ? `translateY(${yPosition}px)` : undefined
        }}
        {...(isMobile
          ? {
              ...bind()
            }
          : {})}
      >
        {/* id used for child InfiniteScrolls to reference when it has reached the bottom of the component */}
        <div
          ref={scrollRef}
          className='custom-scrollbar h-full overflow-hidden'
        >
          <DefaultMenu drawerOpen={walletDrawerOpen} />
        </div>
      </div>
    </div>
  );
};

export default AccountDrawer;

import {
  type ListenerOptions,
  createMulticall
} from '@uniswap/redux-multicall';
import { ChainId } from '@uniswap/sdk-core';
import { useMemo } from 'react';

import {
  useInterfaceMulticall,
  useMainnetInterfaceMulticall
} from 'hooks/useContract';
import { useWeb3React } from 'hooks/useWeb3React';
import useBlockNumber, {
  useMainnetBlockNumber
} from 'lib/hooks/useBlockNumber';

const multicall = createMulticall();

export default multicall;

export function MulticallUpdater() {
  const { chainId } = useWeb3React();
  const latestBlockNumber = useBlockNumber();
  const latestMainnetBlockNumber = useMainnetBlockNumber();
  const contract = useInterfaceMulticall();
  const mainnetContract = useMainnetInterfaceMulticall();
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: 1
    }),
    []
  );
  const mainnetListener: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: 1
    }),
    []
  );

  return (
    <>
      <multicall.Updater
        chainId={ChainId.MAINNET}
        latestBlockNumber={latestMainnetBlockNumber}
        contract={mainnetContract}
        listenerOptions={mainnetListener}
      />
      {chainId !== ChainId.MAINNET && (
        <multicall.Updater
          chainId={chainId}
          latestBlockNumber={latestBlockNumber}
          contract={contract}
          listenerOptions={listenerOptions}
        />
      )}
    </>
  );
}

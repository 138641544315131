import { type Percent, TradeType } from '@uniswap/sdk-core';

import { NumberType, useFormatter } from 'utils/formatNumbers';

import { ExternalLink } from 'components/composed/ExternalLink';

import type { InterfaceTrade } from 'state/routing/types';

const ExactInMessage = ({ amount }: { amount: string }) => (
  <>
    If the price moves so that you will receive less than {amount}, your
    transaction will be reverted. This is the minimum amount you are guaranteed
    to receive.
  </>
);

const ExactOutMessage = ({ amount }: { amount: string }) => (
  <>
    If the price moves so that you will pay more than {amount}, your transaction
    will be reverted. This is the maximum amount you are guaranteed to pay.
  </>
);

const SlippageHeader = ({
  amount,
  isExactIn
}: {
  amount: string;
  isExactIn: boolean;
}) => (
  <div className='flex flex-row justify-between'>
    <span className='text-gray-600'>
      {isExactIn ? 'Receive at least' : 'Pay at most'}
    </span>

    <span className='text-gray-600'>{amount}</span>
  </div>
);

export const MaxSlippageTooltip = ({
  trade,
  allowedSlippage
}: {
  trade: InterfaceTrade;
  allowedSlippage: Percent;
}) => {
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const amount = isExactIn
    ? trade.minimumAmountOut(allowedSlippage)
    : trade.maximumAmountIn(allowedSlippage);

  const formattedAmount = useFormatter().formatCurrencyAmount({
    amount,
    type: NumberType.SwapDetailsAmount
  });
  const displayAmount = `${formattedAmount} ${amount.currency.symbol}`;

  return (
    <div className='flex flex-col gap-1'>
      <SlippageHeader amount={displayAmount} isExactIn={isExactIn} />
      <div className='h-px w-full bg-gray-600' />
      <div>
        {isExactIn ? (
          <ExactInMessage amount={displayAmount} />
        ) : (
          <ExactOutMessage amount={displayAmount} />
        )}{' '}
        <ExternalLink href='https://support.uniswap.org/hc/en-us/articles/8643879653261-What-is-Price-Slippage-'>
          Learn more
        </ExternalLink>
      </div>
    </div>
  );
};

import type { PositionInfo } from './cache';
import useMultiChainPositions from './useMultiChainPositions';
import type { Position } from '@uniswap/v3-sdk';
import { BIPS_BASE } from 'constants/misc';
import { useCallback, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { useFeeValues } from './hooks';
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions';
import { useSwitchChain } from 'hooks/useSwitchChain';
import { useWeb3React } from 'hooks/useWeb3React';

import {
  PrimaryLargeText,
  PrimaryText,
  SecondaryText
} from 'components/ui/Text';

import { useToggleAccountDrawer } from 'components/AccountDrawer';
import { EmptyWalletModule } from 'components/AccountDrawer/MiniPortfolio/EmptyWalletModule';
import { ExpandoRow } from 'components/AccountDrawer/MiniPortfolio/ExpandoRow';
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo';
import PortfolioRow, {
  PortfolioSkeleton
} from 'components/AccountDrawer/MiniPortfolio/PortfolioRow';
import { MouseoverTooltip } from 'components/Tooltip';

/**
 * Takes an array of PositionInfo objects (format used by the Uniswap Labs gql API).
 * The hook access PositionInfo.details (format used by the NFT position contract),
 * filters the PositionDetails data for malicious content,
 * and then returns the original data in its original format.
 */
const useFilterPossiblyMaliciousPositionInfo = (
  positions: PositionInfo[] | undefined
): PositionInfo[] => {
  const tokenIdsToPositionInfo: Record<string, PositionInfo> = useMemo(
    () =>
      positions
        ? positions.reduce(
            (acc, position) => ({
              ...acc,
              [position.details.tokenId.toString()]: position
            }),
            {}
          )
        : {},
    [positions]
  );
  const positionDetails = useMemo(
    () => positions?.map((position) => position.details) ?? [],
    [positions]
  );
  const filteredPositionDetails =
    useFilterPossiblyMaliciousPositions(positionDetails);

  return useMemo(
    () =>
      filteredPositionDetails.map(
        (positionDetails) =>
          tokenIdsToPositionInfo[positionDetails.tokenId.toString()]
      ),
    [filteredPositionDetails, tokenIdsToPositionInfo]
  );
};

const Pools = ({ account }: { account: string }) => {
  const { positions, loading } = useMultiChainPositions(account);
  const filteredPositions = useFilterPossiblyMaliciousPositionInfo(positions);
  const [showClosed, toggleShowClosed] = useReducer(
    (showClosed) => !showClosed,
    false
  );

  const [openPositions, closedPositions] = useMemo(() => {
    const openPositions: PositionInfo[] = [];
    const closedPositions: PositionInfo[] = [];
    for (let i = 0; i < filteredPositions.length; i++) {
      const position = filteredPositions[i];
      if (position.closed) {
        closedPositions.push(position);
      } else {
        openPositions.push(position);
      }
    }
    return [openPositions, closedPositions];
  }, [filteredPositions]);

  const toggleWalletDrawer = useToggleAccountDrawer();

  if (!filteredPositions || loading) {
    return <PortfolioSkeleton />;
  }

  if (filteredPositions.length === 0) {
    return (
      <EmptyWalletModule type='pool' onNavigateClick={toggleWalletDrawer} />
    );
  }

  return (
    <div className='animate-fade-in transition duration-300'>
      {openPositions.map((positionInfo) => (
        <PositionListItem
          key={positionInfo.details.tokenId.toString() + positionInfo.chainId}
          positionInfo={positionInfo}
        />
      ))}
      <ExpandoRow
        title='Closed Positions'
        isExpanded={showClosed}
        toggle={toggleShowClosed}
        numItems={closedPositions.length}
      >
        {closedPositions.map((positionInfo) => (
          <PositionListItem
            key={positionInfo.details.tokenId.toString() + positionInfo.chainId}
            positionInfo={positionInfo}
          />
        ))}
      </ExpandoRow>
    </div>
  );
};

export default Pools;

const calculateLiquidityValue = (
  price0: number | undefined,
  price1: number | undefined,
  position: Position
) => {
  if (!price0 || !price1) return undefined;

  const value0 = parseFloat(position.amount0.toExact()) * price0;
  const value1 = parseFloat(position.amount1.toExact()) * price1;
  return value0 + value1;
};

const PositionListItem = ({ positionInfo }: { positionInfo: PositionInfo }) => {
  const { formatNumber } = useFormatter();

  const { chainId, position, pool, details, inRange, closed } = positionInfo;

  const { priceA, priceB, fees: feeValue } = useFeeValues(positionInfo);
  const liquidityValue = calculateLiquidityValue(priceA, priceB, position);

  const navigate = useNavigate();
  const toggleWalletDrawer = useToggleAccountDrawer();
  const { chainId: walletChainId, connector } = useWeb3React();
  const switchChain = useSwitchChain();
  const onClick = useCallback(async () => {
    if (walletChainId !== chainId) await switchChain(connector, chainId);
    toggleWalletDrawer();
    navigate('/pool/' + details.tokenId);
  }, [
    walletChainId,
    chainId,
    switchChain,
    connector,
    toggleWalletDrawer,
    navigate,
    details.tokenId
  ]);

  return (
    <PortfolioRow
      onClick={onClick}
      left={
        <PortfolioLogo
          chainId={chainId}
          currencies={[pool.token0, pool.token1]}
        />
      }
      title={
        <div className='flex w-full items-center justify-start'>
          <PrimaryLargeText>
            {pool.token0.symbol} / {pool.token1?.symbol}
          </PrimaryLargeText>
        </div>
      }
      descriptor={<PrimaryText>{`${pool.fee / BIPS_BASE}%`}</PrimaryText>}
      right={
        <>
          <MouseoverTooltip
            placement='left'
            text={
              <div style={{ padding: '4px 0px' }}>
                <PrimaryText>{`${formatNumber({
                  input: liquidityValue,
                  type: NumberType.PortfolioBalance
                })} (liquidity) + ${formatNumber({
                  input: feeValue,
                  type: NumberType.PortfolioBalance
                })} (fees)`}</PrimaryText>
              </div>
            }
          >
            <PrimaryLargeText>
              {formatNumber({
                input: (liquidityValue ?? 0) + (feeValue ?? 0),
                type: NumberType.PortfolioBalance
              })}
            </PrimaryLargeText>
          </MouseoverTooltip>

          <div className='flex w-full items-center justify-end'>
            <SecondaryText>
              {closed ? 'Closed' : inRange ? 'In range' : 'Out of range'}
            </SecondaryText>

            <span
              className={cn('ml-1 mt-px size-2 rounded-full', {
                'text-gray-600': closed,
                'text-green': !closed && inRange,
                'text-yellow': !closed && !inRange
              })}
            />
          </div>
        </>
      }
    />
  );
};

export const UNSUPPORTED_LIST_URLS: string[] = [];

const ACTIVE_TOKEN_LIST = process.env.REACT_APP_ACTIVE_TOKEN_LIST;
if (!ACTIVE_TOKEN_LIST) {
  throw new Error('REACT_APP_ACTIVE_TOKEN_LIST is not defined');
}

export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ACTIVE_TOKEN_LIST];
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_ACTIVE_LIST_URLS,
  ...DEFAULT_INACTIVE_LIST_URLS
];

import type { ComponentProps } from 'react';

export const DropdownIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.06787 6.03394L7.99999 9.96606L11.9321 6.03394'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import type { ComponentProps } from 'react';

export const ClockIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M8 5.22222V8L9.66667 9.66667M3 8C3 8.65661 3.12933 9.30679 3.3806 9.91342C3.63188 10.52 4.00017 11.0712 4.46447 11.5355C4.92876 11.9998 5.47996 12.3681 6.08658 12.6194C6.69321 12.8707 7.34339 13 8 13C8.65661 13 9.30679 12.8707 9.91342 12.6194C10.52 12.3681 11.0712 11.9998 11.5355 11.5355C11.9998 11.0712 12.3681 10.52 12.6194 9.91342C12.8707 9.30679 13 8.65661 13 8C13 6.67392 12.4732 5.40215 11.5355 4.46447C10.5979 3.52678 9.32608 3 8 3C6.67392 3 5.40215 3.52678 4.46447 4.46447C3.52678 5.40215 3 6.67392 3 8Z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

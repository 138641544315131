import { ChainId } from '@uniswap/sdk-core';

import { cn } from 'utils/cn';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';

import { CloseIcon, ConfirmIcon } from 'components/Icons';
import { ExternalLink } from 'components/composed/ExternalLink';

type TransactionSubmittedContentProps = {
  onDismiss: () => void;
  hash?: string;
  chainId: number;
};

export const TransactionSubmittedContent = ({
  onDismiss,
  chainId,
  hash
}: TransactionSubmittedContentProps) => {
  const explorerText =
    chainId === ChainId.MAINNET
      ? 'View on Etherscan'
      : 'View on Block Explorer';

  return (
    <div className='relative flex flex-col items-center justify-center gap-6 px-3 py-7.5'>
      <CloseIcon
        onClick={onDismiss}
        className='absolute -top-2.5 right-4 cursor-pointer stroke-gray-600 transition-colors duration-300 hover:stroke-blue-200'
      />

      <ConfirmIcon color='stroke-blue-200' />

      <div className='mt-1.5 flex flex-col gap-8'>
        <span className='text-base font-medium text-white'>
          Transaction submitted
        </span>

        {chainId && hash && (
          <ExternalLink
            href={getExplorerLink({
              chainId,
              data: hash,
              type: ExplorerDataType.TRANSACTION
            })}
            className={cn(
              'flex cursor-pointer items-center justify-center transition-colors duration-300',
              'text-blue-200',
              'hover:text-blue-200/80'
            )}
          >
            {explorerText}
          </ExternalLink>
        )}
      </div>
    </div>
  );
};

import type { CornerShapeProps } from './CornerShape.types';

import { Container } from 'components/composed/ContainerShapes/constants';

const CornerShape = ({ className, container, ...props }: CornerShapeProps) => {
  if (container === Container.INNER) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='11'
        height='27'
        viewBox='0 0 11 27'
        fill='none'
        className={className}
        {...props}
      >
        <path d='M11 0V26.9999H0V6C0 2.68629 2.68629 0 6 0H11Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='27'
      viewBox='0 0 17 27'
      fill='none'
      className={className}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 27H17V0L11.9998 6.10352e-05C5.37246 0.000183105 0 5.37274 0 12.0001V27Z'
      />
    </svg>
  );
};

export default CornerShape;

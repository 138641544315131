import { ChevronDiagonalIcon } from './ChevronDiagonalIcon';
import { SwapIcon } from './SwapIcon';

import { cn } from 'utils/cn';

export enum ConfirmIconSize {
  sm
}

const ChevronSize = {
  [ConfirmIconSize.sm]: 'size-3'
};

const MiddleIconSize = {
  [ConfirmIconSize.sm]: 'size-12.5'
};

const MiddleIconPadding = {
  [ConfirmIconSize.sm]: 'p-2.5'
};

type ConfirmIconProps = {
  color?: string;
  size?: ConfirmIconSize;
  className?: string;
};

export const ConfirmIcon = ({
  color = 'stroke-gray-600',
  size = ConfirmIconSize.sm,
  className
}: ConfirmIconProps) => (
  <div className='relative'>
    <ChevronDiagonalIcon
      className={cn('absolute left-0 top-0', color, ChevronSize[size])}
    />
    <ChevronDiagonalIcon
      className={cn(
        'absolute right-0 top-0 rotate-90',
        color,
        ChevronSize[size]
      )}
    />

    <SwapIcon
      className={cn(
        'rotate-180',
        className,
        color,
        MiddleIconSize[size],
        MiddleIconPadding[size]
      )}
    />

    <ChevronDiagonalIcon
      className={cn(
        'absolute bottom-0 left-0 -rotate-90',
        color,
        ChevronSize[size]
      )}
    />
    <ChevronDiagonalIcon
      className={cn(
        'absolute bottom-0 right-0 rotate-180',
        color,
        ChevronSize[size]
      )}
    />
  </div>
);

import StatusIcon from '../Identicon/StatusIcon';
import { useCachedPortfolioBalancesQuery } from '../PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import IconButton, {
  IconHoverText,
  IconWithConfirmTextButton
} from './IconButton';
import MiniPortfolio from './MiniPortfolio';
import { getConnection } from 'connection';
import { useCallback, useState } from 'react';

import { shortenAddress } from 'utils';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import useENSName from 'hooks/useENSName';
import { useWeb3React } from 'hooks/useWeb3React';
import { useAppDispatch } from 'state/hooks';

import {
  Primary4XLText,
  PrimaryLargeText,
  SecondaryLargeText,
  SecondaryText
} from 'components/ui/Text';

import { Power } from 'components/Icons/Power';
import { SettingsIcon } from 'components/Icons/SettingsIcon';
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta';
import { LoadingBubble } from 'components/Tokens/loading';
import styled from 'styled-components';
import { CopyHelper } from 'theme/components';

import { setRecentConnectionDisconnected } from 'state/user/reducer';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`;

const CopyText = styled(CopyHelper).attrs({
  iconSize: 14,
  iconPosition: 'right'
})``;

const AuthenticatedHeader = ({
  account,
  openSettings
}: {
  account: string;
  openSettings: () => void;
}) => {
  const { connector } = useWeb3React();
  const { ENSName } = useENSName(account);
  const dispatch = useAppDispatch();

  const { formatNumber, formatDelta } = useFormatter();

  const connection = getConnection(connector);
  const disconnect = useCallback(() => {
    connector.deactivate?.();
    connector.resetState();
    dispatch(setRecentConnectionDisconnected());
  }, [connector, dispatch]);

  const { data: portfolioBalances } = useCachedPortfolioBalancesQuery({
    account
  });
  const portfolio = portfolioBalances?.portfolios?.[0];
  const totalBalance = portfolio?.tokensTotalDenominatedValue?.value;
  const absoluteChange =
    portfolio?.tokensTotalDenominatedValueChange?.absolute?.value;
  const percentChange =
    portfolio?.tokensTotalDenominatedValueChange?.percentage?.value;
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  return (
    <div className='flex h-full flex-1 flex-col gap-5 px-4 py-5'>
      <div className='flex items-start justify-between gap-1'>
        <div className='flex flex-row gap-1'>
          <StatusIcon account={account} connection={connection} size={40} />

          {account && (
            <div className='flex w-full flex-col justify-center overflow-hidden text-nowrap'>
              <PrimaryLargeText>
                <CopyText toCopy={ENSName ?? account}>
                  {ENSName ?? shortenAddress(account)}
                </CopyText>
              </PrimaryLargeText>

              {ENSName && (
                <SecondaryText>
                  <CopyText toCopy={account}>
                    {shortenAddress(account)}
                  </CopyText>
                </SecondaryText>
              )}
            </div>
          )}
        </div>

        <IconContainer>
          <IconButton
            hideHorizontal={showDisconnectConfirm}
            onClick={openSettings}
            Icon={SettingsIcon}
            iconClassName='size-6 stroke-gray-600'
          />
          <IconWithConfirmTextButton
            onConfirm={disconnect}
            onShowConfirm={setShowDisconnectConfirm}
            Icon={Power}
            text='Disconnect'
            dismissOnHoverOut
          />
        </IconContainer>
      </div>

      <div className='flex flex-1 flex-col justify-start'>
        {totalBalance !== undefined ? (
          <div className='animate-fade-in flex flex-col justify-start gap-8 transition duration-300'>
            <Primary4XLText>
              {formatNumber({
                input: totalBalance,
                type: NumberType.PortfolioBalance
              })}
            </Primary4XLText>

            <div className='flex w-full flex-wrap items-center justify-start'>
              {absoluteChange !== 0 && percentChange && (
                <>
                  <DeltaArrow delta={absoluteChange} />
                  <SecondaryLargeText>
                    {`${formatNumber({
                      input: Math.abs(absoluteChange as number),
                      type: NumberType.PortfolioBalance
                    })} (${formatDelta(percentChange)})`}
                  </SecondaryLargeText>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col justify-start gap-1'>
            <LoadingBubble height='44px' width='170px' />
            <LoadingBubble height='16px' width='100px' margin='4px 0 20px 0' />
          </div>
        )}

        <MiniPortfolio account={account} />
      </div>
    </div>
  );
};

export default AuthenticatedHeader;

import { ChainId, type Currency, Token } from '@uniswap/sdk-core';
import { AVERAGE_L1_BLOCK_TIME } from 'constants/chainInfo';
import { NATIVE_CHAIN_ID, nativeOnChain } from 'constants/tokens';
import ms from 'ms';

import { getNativeTokenDBAddress } from 'utils/nativeTokens';

import {
  Chain,
  type ContractInput,
  TokenStandard
} from './__generated__/types-and-hooks';

export enum PollingInterval {
  Slow = ms('5m'),
  Normal = ms('1m'),
  Fast = AVERAGE_L1_BLOCK_TIME,
  LightningMcQueen = ms('3s') // approx block interval for polygon
}

export const GQL_MAINNET_CHAINS = [Chain.Ethereum] as const;

const GQL_TESTNET_CHAINS = [Chain.EthereumSepolia] as const;

const UX_SUPPORTED_GQL_CHAINS = [
  ...GQL_MAINNET_CHAINS,
  ...GQL_TESTNET_CHAINS
] as const;
export type InterfaceGqlChain = (typeof UX_SUPPORTED_GQL_CHAINS)[number];

export const CHAIN_ID_TO_BACKEND_NAME: { [key: number]: InterfaceGqlChain } = {
  [ChainId.MAINNET]: Chain.Ethereum,
  [ChainId.SEPOLIA]: Chain.EthereumSepolia
};

export function chainIdToBackendName(chainId: number | undefined) {
  return chainId && CHAIN_ID_TO_BACKEND_NAME[chainId]
    ? CHAIN_ID_TO_BACKEND_NAME[chainId]
    : CHAIN_ID_TO_BACKEND_NAME[ChainId.MAINNET];
}

const GQL_CHAINS = [ChainId.MAINNET] as const;
type GqlChainsType = (typeof GQL_CHAINS)[number];

export function isGqlSupportedChain(
  chainId: number | undefined
): chainId is GqlChainsType {
  return !!chainId && GQL_CHAINS.includes(chainId);
}
export function toContractInput(currency: Currency): ContractInput {
  const chain = chainIdToBackendName(currency.chainId);
  return {
    chain,
    address: currency.isToken
      ? currency.address
      : getNativeTokenDBAddress(chain)
  };
}

export function gqlToCurrency(token: {
  address?: string;
  chain: Chain;
  standard?: TokenStandard;
  decimals?: number;
  name?: string;
  symbol?: string;
}): Currency | undefined {
  const chainId = supportedChainIdFromGQLChain(token.chain);
  if (!chainId) return undefined;
  if (
    token.standard === TokenStandard.Native ||
    token.address === 'NATIVE' ||
    !token.address
  )
    return nativeOnChain(chainId);
  else
    return new Token(
      chainId,
      token.address,
      token.decimals ?? 18,
      token.symbol,
      token.name
    );
}

const CHAIN_NAME_TO_CHAIN_ID: { [key in InterfaceGqlChain]: ChainId } = {
  [Chain.Ethereum]: ChainId.MAINNET,
  [Chain.EthereumSepolia]: ChainId.SEPOLIA
};

export function isSupportedGQLChain(chain: Chain): chain is InterfaceGqlChain {
  return (UX_SUPPORTED_GQL_CHAINS as ReadonlyArray<Chain>).includes(chain);
}

export function supportedChainIdFromGQLChain(chain: InterfaceGqlChain): ChainId;
export function supportedChainIdFromGQLChain(chain: Chain): ChainId | undefined;
export function supportedChainIdFromGQLChain(
  chain: Chain
): ChainId | undefined {
  return isSupportedGQLChain(chain) ? CHAIN_NAME_TO_CHAIN_ID[chain] : undefined;
}

export function getTokenDetailsURL({
  address,
  chain,
  inputAddress
}: {
  address?: string | null;
  chain: Chain;
  inputAddress?: string | null;
}) {
  const chainName = chain.toLowerCase();
  const tokenAddress = address ?? NATIVE_CHAIN_ID;
  const inputAddressSuffix = inputAddress
    ? `?inputCurrency=${inputAddress}`
    : '';
  return `/tokens/${chainName}/${tokenAddress}${inputAddressSuffix}`;
}

import { useLocalActivities } from './parseLocal';
import { parseRemoteActivities } from './parseRemote';
import type { Activity, ActivityMap } from './types';
import ms from 'ms';
import { useEffect, useMemo, useState } from 'react';

import { useFormatter } from 'utils/formatNumbers';

import {
  TransactionStatus,
  useActivityQuery
} from 'graphql/data/__generated__/types-and-hooks';
import {
  usePendingTransactions,
  useTransactionCanceller
} from 'state/transactions/hooks';

import { GQL_MAINNET_CHAINS } from 'graphql/data/util';

/** Detects transactions from same account with the same nonce and different hash */
const findCancelTx = (
  localActivity: Activity,
  remoteMap: ActivityMap,
  account: string
): string | undefined => {
  // handles locally cached tx's that were stored before we started tracking nonces
  if (
    !localActivity.nonce ||
    localActivity.status !== TransactionStatus.Pending
  )
    return undefined;

  for (const remoteTx of Object.values(remoteMap)) {
    if (!remoteTx) continue;

    // A pending tx is 'cancelled' when another tx with the same account & nonce but different hash makes it on chain
    if (
      remoteTx.nonce === localActivity.nonce &&
      remoteTx.from.toLowerCase() === account.toLowerCase() &&
      remoteTx.hash.toLowerCase() !== localActivity.hash.toLowerCase() &&
      remoteTx.chainId === localActivity.chainId
    ) {
      return remoteTx.hash;
    }
  }

  return undefined;
};

/** Deduplicates local and remote activities */
const combineActivities = (
  localMap: ActivityMap = {},
  remoteMap: ActivityMap = {}
): Array<Activity> => {
  const txHashes = [
    ...new Set([...Object.keys(localMap), ...Object.keys(remoteMap)])
  ];

  return txHashes.reduce((acc: Array<Activity>, hash) => {
    const localActivity = (localMap?.[hash] ?? {}) as Activity;
    const remoteActivity = (remoteMap?.[hash] ?? {}) as Activity;

    if (localActivity.cancelled) {
      // Hides misleading activities caused by cross-chain nonce collisions previously being incorrectly labelled as cancelled txs in redux
      if (localActivity.chainId !== remoteActivity.chainId) {
        acc.push(remoteActivity);
        return acc;
      }
      // Remote data only contains data of the cancel tx, rather than the original tx, so we prefer local data here
      acc.push(localActivity);
    } else {
      // Generally prefer remote values to local value because i.e. remote swap amounts are on-chain rather than client-estimated
      acc.push({ ...localActivity, ...remoteActivity } as Activity);
    }

    return acc;
  }, []);
};

export const useAllActivities = (account: string) => {
  const { formatNumberOrString } = useFormatter();
  const { data, loading, refetch } = useActivityQuery({
    variables: { account, chains: GQL_MAINNET_CHAINS },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  const localMap = useLocalActivities(account);
  const remoteMap = useMemo(
    () =>
      parseRemoteActivities(
        data?.portfolios?.[0].assetActivities,
        account,
        formatNumberOrString
      ),
    [account, data?.portfolios, formatNumberOrString]
  );
  const updateCancelledTx = useTransactionCanceller();

  /* Updates locally stored pendings tx's when remote data contains a conflicting cancellation tx */
  useEffect(() => {
    if (!remoteMap) return;

    Object.values(localMap).forEach((localActivity) => {
      if (!localActivity) return;

      const cancelHash = findCancelTx(localActivity, remoteMap, account);

      if (cancelHash)
        updateCancelledTx(
          localActivity.hash,
          localActivity.chainId,
          cancelHash
        );
    });
  }, [account, localMap, remoteMap, updateCancelledTx]);

  const combinedActivities = useMemo(
    () => (remoteMap ? combineActivities(localMap, remoteMap) : undefined),
    [localMap, remoteMap]
  );

  return { loading, activities: combinedActivities, refetch };
};

export const usePendingActivity = () => {
  const pendingTransactions = usePendingTransactions();

  const hasPendingActivity = pendingTransactions.length > 0;
  const pendingActivityCount = pendingTransactions.length;

  return { hasPendingActivity, pendingActivityCount };
};

const getTimeSince = (timestamp: number) => {
  const seconds = Math.floor(Date.now() - timestamp * 1000);

  let interval;
  // TODO(cartcrom): use locale to determine date shorthands to use for non-english
  if ((interval = seconds / ms('1y')) > 1) return Math.floor(interval) + 'y';
  if ((interval = seconds / ms('30d')) > 1) return Math.floor(interval) + 'mo';
  if ((interval = seconds / ms('1d')) > 1) return Math.floor(interval) + 'd';
  if ((interval = seconds / ms('1h')) > 1) return Math.floor(interval) + 'h';
  if ((interval = seconds / ms('1m')) > 1) return Math.floor(interval) + 'm';
  else return Math.floor(seconds / ms('1s')) + 's';
};

/**
 * Keeps track of the time since a given timestamp, keeping it up to date every second when necessary
 * @param timestamp
 * @returns
 */
export const useTimeSince = (timestamp: number) => {
  const [timeSince, setTimeSince] = useState<string>(getTimeSince(timestamp));

  useEffect(() => {
    const refreshTime = () =>
      setTimeout(() => {
        if (Math.floor(Date.now() - timestamp * 1000) / ms('61s') <= 1) {
          setTimeSince(getTimeSince(timestamp));
          timeout = refreshTime();
        }
      }, ms('1s'));

    let timeout = refreshTime();

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [timestamp]);

  return timeSince;
};

import type { AnchorHTMLAttributes, FC, HTMLAttributes } from 'react';
import { useLocation } from 'react-router-dom';

import { cn } from 'utils/cn';
import { routes } from 'utils/routes';

import { EdgeShape } from 'components/ui/shapes';
import SlopeSteepShape from 'components/ui/shapes/SlopeSteepShape';

import { MenuDropdown } from 'components/NavBar/MenuDropdown';

const whitelistRedirect = process.env.REACT_APP_WHITELIST_REDIRECT == 'true';

const navigationItems = [
  {
    label: 'Swap',
    component: () => (
      <MobileStartButton href={whitelistRedirect ? '/whitelist' : routes.swap}>
        Swap
      </MobileStartButton>
    )
  },
  {
    label: 'Pools',
    component: () => (
      <NavigationLink href={whitelistRedirect ? '/whitelist' : routes.pools}>
        Pools
      </NavigationLink>
    )
  },
  {
    label: 'Sale',
    component: () => (
      <NavigationLink href={whitelistRedirect ? '/whitelist' : routes.sale}>
        Sale
      </NavigationLink>
    )
  },
  {
    label: 'Bridge',
    component: () => (
      <NavigationLink href={whitelistRedirect ? '/whitelist' : routes.bridge}>
        Bridge
      </NavigationLink>
    )
  },
  {
    label: 'Stake',
    component: () => (
      <NavigationLink href={whitelistRedirect ? '/whitelist' : routes.stake}>
        Stake
      </NavigationLink>
    )
  },
  {
    label: 'More',
    component: () => (
      <MobileEndButton asDiv className='pr-0'>
        <MenuDropdown />
      </MobileEndButton>
    )
  }
];

const MobileBottomNavigation = () => (
  <div className='fixed inset-x-0 bottom-0 z-sticky md:hidden'>
    <div className='relative flex flex-col'>
      <div className='flex flex-row'>
        <SlopeSteepShape className='h-6 w-auto scale-x-mirror fill-blue-900' />

        <div className='flex-auto bg-blue-900' />

        <SlopeSteepShape className='h-6 w-auto fill-blue-900' />
      </div>

      <div className='h-10 w-full bg-blue-900' />

      <div className='absolute inset-x-6 z-1 flex flex-grow items-center justify-between gap-1 p-1'>
        {navigationItems.map(({ label, component: Component }) => (
          <Component key={label} />
        ))}
      </div>
    </div>
  </div>
);

export default MobileBottomNavigation;

type LinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean;
  asDiv?: boolean;
};

const MobileStartButton: FC<LinkButtonProps> = ({
  children,
  disabled,
  href,
  ...props
}) => {
  const { pathname } = useLocation();

  const active = href && pathname.startsWith(href);

  return (
    <NavigationLink
      className='relative rounded-l-none pl-1.5'
      href={href}
      {...props}
    >
      {children}

      <EdgeShape
        className={cn('absolute inset-y-0 left-px h-8.75 -translate-x-9/10', {
          'fill-blue-800': active
        })}
      />
    </NavigationLink>
  );
};

const MobileEndButton: FC<LinkButtonProps> = ({
  children,
  className,
  disabled,
  href,
  ...props
}) => {
  const { pathname } = useLocation();

  const active = href && pathname.startsWith(href);

  return (
    <NavigationLink
      className={cn(
        'group relative rounded-r-none pr-1.5 transition-none',
        className
      )}
      href={href}
      {...props}
    >
      {children}

      <EdgeShape
        className={cn(
          'absolute inset-y-0 right-px h-8.75 translate-x-9/10 rotate-180',
          {
            'fill-blue-800': active,
            'group-hover:fill-blue-800': !active
          }
        )}
      />
    </NavigationLink>
  );
};

const NavigationLink: FC<LinkButtonProps> = ({
  href,
  disabled,
  asDiv = false,
  className,
  ...props
}) => {
  const { pathname } = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  const active = href && pathname.startsWith(href);

  const commonProps = {
    className: cn(
      'rounded-md bg-transparent p-2.5 text-xs leading-[15px] transition-colors duration-300',
      {
        'bg-blue-800 text-blue-200': active,
        'text-gray-600': !active,
        'cursor-not-allowed text-gray-800': disabled,
        'hover:bg-blue-800 hover:text-blue-200': !disabled
      },
      className
    ),
    ...props
  };

  if (asDiv) {
    return <div {...(commonProps as HTMLAttributes<HTMLDivElement>)} />;
  }

  return (
    <a
      href={!disabled ? href : undefined}
      onClick={handleClick}
      aria-disabled={disabled ? 'true' : undefined}
      {...commonProps}
    />
  );
};

import { isAddress } from './addresses';
import type { Signer } from '@ethersproject/abstract-signer';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import type { JsonRpcProvider, Provider } from '@ethersproject/providers';

export function getContract(
  address: string,
  ABI: any,
  provider: JsonRpcProvider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(provider, account) as any
  );
}

// account is optional
function getProviderOrSigner(
  provider: JsonRpcProvider,
  account?: string
): Provider | Signer {
  return account ? provider.getSigner(account).connectUnchecked() : provider;
}

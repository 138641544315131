import type { ComponentProps } from 'react';

export const SettingsIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='icon-settings'>
      <path
        d='M7.83431 1H4.16569C4.0596 1 3.95786 1.04214 3.88284 1.11716L1.11716 3.88284C1.04214 3.95786 1 4.0596 1 4.16569V7.83431C1 7.9404 1.04214 8.04214 1.11716 8.11716L3.88284 10.8828C3.95786 10.9579 4.0596 11 4.16569 11H7.83431C7.9404 11 8.04214 10.9579 8.11716 10.8828L10.8828 8.11716C10.9579 8.04214 11 7.9404 11 7.83431V4.16569C11 4.0596 10.9579 3.95786 10.8828 3.88284L8.11716 1.11716C8.04214 1.04214 7.9404 1 7.83431 1Z'
        className='fill-transparent'
      />
      <circle cx='6' cy='6' r='2' className='fill-transparent' />
    </g>
  </svg>
);

import {
  USDC_MAINNET,
  USDT,
  WRAPPED_NATIVE_CURRENCY,
  nativeOnChain
} from './tokens';
import { ChainId, type Currency, type Token } from '@uniswap/sdk-core';

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[];
};

export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: [
    nativeOnChain(ChainId.OASIS_SAPPHIRE_MAINNET),
    WRAPPED_NATIVE_CURRENCY[ChainId.OASIS_SAPPHIRE_MAINNET] as Token
  ],
  [ChainId.OASIS_SAPPHIRE_TESTNET]: [
    nativeOnChain(ChainId.OASIS_SAPPHIRE_TESTNET),
    WRAPPED_NATIVE_CURRENCY[ChainId.OASIS_SAPPHIRE_TESTNET] as Token
  ],
  [ChainId.MAINNET]: [
    nativeOnChain(ChainId.MAINNET),
    USDC_MAINNET,
    USDT,
    WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET] as Token
  ],
  [ChainId.SEPOLIA]: [
    nativeOnChain(ChainId.SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA] as Token
  ]
};

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/ui/Button';
import { PrimaryLargeText, SecondaryText } from 'components/ui/Text';

import {
  EmptyActivityIcon,
  EmptyPoolsIcon,
  EmptyTokensIcon
} from 'components/Icons';

type EmptyWalletContent = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  actionText?: React.ReactNode;
  urlPath?: string;
  icon: React.ReactNode;
};
type EmptyWalletContentType = 'token' | 'activity' | 'pool';
const EMPTY_WALLET_CONTENT: {
  [key in EmptyWalletContentType]: EmptyWalletContent;
} = {
  token: {
    title: 'No tokens yet',
    subtitle: 'Buy or transfer tokens to this wallet to get started.',
    icon: <EmptyTokensIcon />
  },
  activity: {
    title: 'No activity yet',
    subtitle:
      'Your onchain transactions and crypto purchases will appear here.',
    icon: <EmptyActivityIcon />
  },
  pool: {
    title: 'No pools yet',
    subtitle: 'Open a new position or create a pool to get started.',
    actionText: '+ New position',
    urlPath: '/pool?tabId=my-pools',
    icon: <EmptyPoolsIcon />
  }
};

interface EmptyWalletContentProps {
  type?: EmptyWalletContentType;
  onNavigateClick?: () => void;
}

const EmptyWalletContent = ({
  type = 'activity',
  onNavigateClick
}: EmptyWalletContentProps) => {
  const navigate = useNavigate();

  const content = EMPTY_WALLET_CONTENT[type];

  const actionButtonClick = useCallback(() => {
    if (content.urlPath) {
      onNavigateClick?.();
      navigate(content.urlPath);
    }
  }, [content.urlPath, navigate, onNavigateClick]);

  return (
    <div className='flex flex-col items-center justify-center gap-4 p-4'>
      {content.icon}

      <PrimaryLargeText>{content.title}</PrimaryLargeText>

      <SecondaryText className='text-center'>{content.subtitle}</SecondaryText>

      {content.actionText && (
        <Button onClick={actionButtonClick} variant='primary-light'>
          {content.actionText}
        </Button>
      )}
    </div>
  );
};

export const EmptyWalletModule = (props?: EmptyWalletContentProps) => (
  <div className='flex h-full w-full flex-col flex-wrap items-center justify-center'>
    <EmptyWalletContent {...props} />
  </div>
);

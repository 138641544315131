import type { HTMLProps } from 'react';

import { cn } from 'utils/cn';

export const ExternalLink = ({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  className,
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & {
  href: string;
}) => (
  <a
    target={target}
    rel={rel}
    href={href}
    className={cn(
      'cursor-pointer font-medium no-underline transition-opacity duration-300',
      className
    )}
    {...rest}
  />
);

import { acceptListUpdate } from './actions';
import { VersionUpgrade, getVersionUpgrade } from '@uniswap/token-lists';
import { DEFAULT_LIST_OF_LISTS, UNSUPPORTED_LIST_URLS } from 'constants/lists';
import TokenSafetyLookupTable from 'constants/tokenSafetyLookup';
import ms from 'ms';
import { useCallback, useEffect } from 'react';

import { useFetchListCallback } from '../../hooks/useFetchListCallback';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { useStateRehydrated } from 'hooks/useStateRehydrated';
import { useWeb3React } from 'hooks/useWeb3React';
import useInterval from 'lib/hooks/useInterval';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useAllLists } from 'state/lists/hooks';

export default function Updater(): null {
  const { provider } = useWeb3React();
  const dispatch = useAppDispatch();
  const isWindowVisible = useIsWindowVisible();

  // get all loaded lists, and the active urls
  const lists = useAllLists();
  const listsState = useAppSelector((state) => state.lists);
  const rehydrated = useStateRehydrated();

  useEffect(() => {
    if (rehydrated) TokenSafetyLookupTable.update(listsState);
  }, [listsState, rehydrated]);

  const fetchList = useFetchListCallback();
  const fetchAllListsCallback = useCallback(() => {
    if (!isWindowVisible) return;
    DEFAULT_LIST_OF_LISTS.forEach((url) => {
      // Don't validate token list
      fetchList(url, true).catch((error) =>
        console.debug('interval list fetching error', error)
      );
    });
  }, [fetchList, isWindowVisible]);

  // fetch all lists every 10 minutes, but only after we initialize provider
  useInterval(fetchAllListsCallback, provider ? ms('10m') : null);

  useEffect(() => {
    if (!rehydrated) return; // loaded lists will not be available until state is rehydrated

    // whenever a list is not loaded and not loading, try again to load it
    Object.keys(lists).forEach((listUrl) => {
      const list = lists[listUrl];
      if (!list.current && !list.loadingRequestId && !list.error) {
        fetchList(listUrl).catch((error) =>
          console.debug('list added fetching error', error)
        );
      }
    });
    UNSUPPORTED_LIST_URLS.forEach((listUrl) => {
      const list = lists[listUrl];
      if (!list || (!list.current && !list.loadingRequestId && !list.error)) {
        fetchList(listUrl, /* isUnsupportedList= */ true).catch((error) =>
          console.debug('list added fetching error', error)
        );
      }
    });
  }, [dispatch, fetchList, lists, rehydrated]);

  // automatically update lists for every version update
  useEffect(() => {
    Object.keys(lists).forEach((listUrl) => {
      const list = lists[listUrl];
      if (list.current && list.pendingUpdate) {
        const bump = getVersionUpgrade(
          list.current.version,
          list.pendingUpdate.version
        );
        switch (bump) {
          case VersionUpgrade.NONE:
            throw new Error('unexpected no version bump');
          case VersionUpgrade.PATCH:
          case VersionUpgrade.MINOR:
          case VersionUpgrade.MAJOR:
            dispatch(acceptListUpdate(listUrl));
        }
      }
    });
  }, [dispatch, lists]);

  return null;
}

import type store from './index';
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux';

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;

import { Token } from './token';
import {
  EthereumMainnet,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia,
} from '@neby/chains';

/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  [EthereumMainnet.chainId]: new Token(
    EthereumMainnet.chainId,
    EthereumMainnet.weth9.address,
    EthereumMainnet.weth9.decimals,
    EthereumMainnet.weth9.symbol,
    EthereumMainnet.weth9.name
  ),
  [OasisSapphireMainnet.chainId]: new Token(
    OasisSapphireMainnet.chainId,
    OasisSapphireMainnet.weth9.address,
    OasisSapphireMainnet.weth9.decimals,
    OasisSapphireMainnet.weth9.symbol,
    OasisSapphireMainnet.weth9.name
  ),
  [OasisSapphireTestnet.chainId]: new Token(
    OasisSapphireTestnet.chainId,
    OasisSapphireTestnet.weth9.address,
    OasisSapphireTestnet.weth9.decimals,
    OasisSapphireTestnet.weth9.symbol,
    OasisSapphireTestnet.weth9.name
  ),
  [Sepolia.chainId]: new Token(
    Sepolia.chainId,
    Sepolia.weth9.address,
    Sepolia.weth9.decimals,
    Sepolia.weth9.symbol,
    Sepolia.weth9.name
  ),
};

import {
  type ChainConfig,
  EthereumMainnet,
  OasisSapphireMainnet,
  OasisSapphireTestnet,
  Sepolia
} from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';

import { getChainFromChainId, isSupportedChain } from 'utils/chains';

export const CHAIN_IDS_TO_NAMES: Record<number, string> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: 'oasis_sapphire_mainnet',
  [ChainId.OASIS_SAPPHIRE_TESTNET]: 'oasis_sapphire_testnet',
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.SEPOLIA]: 'sepolia'
} as const;

export const UI_SUPPORTED_CHAINS = [
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.MAINNET,
  ChainId.SEPOLIA
] as const;

export type SupportedChainId = (typeof UI_SUPPORTED_CHAINS)[number];

export const NETWORK_SELECTOR_CHAINS = UI_SUPPORTED_CHAINS.filter((chainId) =>
  isSupportedChain(chainId)
);

export const SWAP_SUPPORTED_CHAIN_IDS = [
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.OASIS_SAPPHIRE_MAINNET
] as const;

export const BRIDGE_SUPPORTED_CHAINS = [
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET
];

export const MY_POSITION_SUPPORTED_CHAINS = [
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET
];

export const STAKE_SUPPORTED_CHAINS = [
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET
];

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [ChainId.MAINNET] as const;

export const TESTNET_CHAIN_IDS = [
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.SEPOLIA
] as const;

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.MAINNET,
  ChainId.SEPOLIA
] as const;

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

export const L2_CHAIN_IDS = [] as const;

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number];

export const CHAIN_IDS_TO_CHAIN: Record<number, ChainConfig> = {
  [ChainId.OASIS_SAPPHIRE_MAINNET]: OasisSapphireMainnet,
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet,
  [ChainId.MAINNET]: EthereumMainnet,
  [ChainId.SEPOLIA]: Sepolia
};

export const DEFAULT_TESTNET_CHAIN_ID = ChainId.OASIS_SAPPHIRE_TESTNET;
export const DEFAULT_MAINNET_CHAIN_ID = ChainId.OASIS_SAPPHIRE_MAINNET;

const DEFAULT_CHAIN_ID_STRING = process.env.REACT_APP_DEFAULT_CHAIN_ID;
if (!DEFAULT_CHAIN_ID_STRING) {
  throw new Error('REACT_APP_DEFAULT_CHAIN_ID not set');
}
export const DEFAULT_CHAIN_ID = Number(DEFAULT_CHAIN_ID_STRING);
if (isNaN(DEFAULT_CHAIN_ID)) {
  throw new Error('REACT_APP_DEFAULT_CHAIN_ID is not a number');
}

export const DEFAULT_CHAIN = getChainFromChainId(
  DEFAULT_CHAIN_ID
) as ChainConfig;

import type { ComponentProps } from 'react';

export const WarningIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M8.00018 6.00001V8.66668M8.00018 11.3333H8.00685M6.82684 2.63801L1.21218 12.0113C1.09649 12.2118 1.0353 12.4391 1.03467 12.6705C1.03405 12.902 1.09402 13.1296 1.20862 13.3306C1.32322 13.5317 1.48846 13.6993 1.68791 13.8168C1.88736 13.9342 2.11407 13.9974 2.34551 14H13.5755C13.8069 13.9974 14.0337 13.9342 14.2331 13.8168C14.4326 13.6993 14.5978 13.5317 14.7124 13.3306C14.827 13.1296 14.887 12.902 14.8863 12.6705C14.8857 12.4391 14.8245 12.2118 14.7088 12.0113L9.09351 2.63801C8.97527 2.44334 8.80888 2.28241 8.61038 2.17072C8.41187 2.05904 8.18794 2.00037 7.96018 2.00037C7.73241 2.00037 7.50848 2.05904 7.30998 2.17072C7.11147 2.28241 6.94508 2.44334 6.82684 2.63801Z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import type { ComponentProps } from 'react';

export const TransactionSubmittedIcon = (props: ComponentProps<'svg'>) => (
  <svg
    viewBox='0 0 52 52'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.1211 2H2.12109V11'
      stroke='#AAC1FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M41.1211 2H50.1211V11'
      stroke='#AAC1FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.1211 50H2.12109V41'
      stroke='#AAC1FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M41.1211 50H50.1211V41'
      stroke='#AAC1FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26.1211 11L15.1211 22V26M26.1211 11L37.1211 22V26M26.1211 11V41'
      stroke='#AAC1FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

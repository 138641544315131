import { ChainId, type NativeCurrency, type Token } from '@uniswap/sdk-core';
import { nativeOnChain } from 'constants/tokens';
import { useMemo } from 'react';

export default function useNativeCurrency(
  chainId: ChainId | null | undefined
): NativeCurrency | Token {
  return useMemo(
    () =>
      chainId
        ? nativeOnChain(chainId)
        : nativeOnChain(ChainId.OASIS_SAPPHIRE_MAINNET),
    [chainId]
  );
}

import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from 'utils/cn';

import { Search } from 'components/Icons/Search';

const SearchInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => (
  <div className='group relative w-full'>
    <div className='absolute left-2 flex h-full items-center'>
      <Search className='h-5 w-5 fill-gray-600 group-focus-within:fill-blue-200' />
    </div>

    <input
      ref={ref}
      className={cn(
        'flex h-10 w-full items-center rounded-md bg-blue-800 bg-no-repeat py-2.5 pl-10 pr-4 text-blue-200 outline-none placeholder:text-gray-600',
        'border border-transparent focus-within:border-blue-200/50',
        className
      )}
      {...props}
    />
  </div>
));

SearchInput.displayName = 'SearchInput';

export default SearchInput;

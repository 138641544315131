import type { ComponentProps } from 'react';

import { cn } from 'utils/cn';

const Spinner = ({ className, ...props }: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
    className={cn('animate-spin', className)}
    {...props}
  >
    <circle
      cx='50'
      cy='50'
      fill='none'
      strokeWidth='6'
      r='35'
      strokeDasharray='164.93361431346415 56.97787143782138'
      transform='matrix(1,0,0,1,0,0)'
    />
  </svg>
);

export default Spinner;

import { SwapModalHeaderAmount } from './SwapModalHeaderAmount';
import { type Currency, type Percent, TradeType } from '@uniswap/sdk-core';

import { isPreviewTrade } from 'state/routing/utils';

import { useUSDPrice } from 'hooks/useUSDPrice';

import type { InterfaceTrade } from 'state/routing/types';

export const SwapModalHeader = ({
  trade,
  inputCurrency,
  allowedSlippage
}: {
  trade: InterfaceTrade;
  inputCurrency?: Currency;
  allowedSlippage: Percent;
}) => {
  const fiatValueInput = useUSDPrice(trade.inputAmount);
  const fiatValueOutput = useUSDPrice(trade.outputAmount);

  return (
    <div className='mt-4 flex flex-col items-center gap-2'>
      <div className='flex w-full flex-col items-center gap-4'>
        <SwapModalHeaderAmount
          label='You pay'
          amount={trade.inputAmount}
          currency={inputCurrency ?? trade.inputAmount.currency}
          usdAmount={fiatValueInput.data}
          isLoading={
            isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_OUTPUT
          }
        />

        <SwapModalHeaderAmount
          label='You receive'
          amount={trade.outputAmount}
          currency={trade.outputAmount.currency}
          usdAmount={fiatValueOutput.data}
          isLoading={
            isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_INPUT
          }
          tooltipText={
            trade.tradeType === TradeType.EXACT_INPUT ? (
              <span>
                Output is estimated. You will receive at least{' '}
                <b>
                  {trade.minimumAmountOut(allowedSlippage).toSignificant(6)}{' '}
                  {trade.outputAmount.currency.symbol}
                </b>{' '}
                or the transaction will revert.
              </span>
            ) : (
              <span>
                Input is estimated. You will sell at most{' '}
                <b>
                  {trade.maximumAmountIn(allowedSlippage).toSignificant(6)}{' '}
                  {trade.inputAmount.currency.symbol}
                </b>{' '}
                or the transaction will revert.
              </span>
            )
          }
        />
      </div>
    </div>
  );
};

import EthereumLogo from '../../assets/images/ethereum-logo.png';
import { ChainId } from '@uniswap/sdk-core';

import OasisLogo from '../../components/Logo/ChainSymbols/oasis.svg';

export function getNativeLogoURI(
  chainId: ChainId = ChainId.OASIS_SAPPHIRE_MAINNET
): string {
  switch (chainId) {
    case ChainId.OASIS_SAPPHIRE_MAINNET:
    case ChainId.OASIS_SAPPHIRE_TESTNET:
      return OasisLogo;
    default:
      return EthereumLogo;
  }
}

import type { ComponentProps } from 'react';

export const PlusIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.99969 1.85706C7.99969 1.30477 7.55197 0.857056 6.99969 0.857056C6.4474 0.857056 5.99969 1.30477 5.99969 1.85706V6.00004H1.85712C1.30483 6.00004 0.857117 6.44776 0.857117 7.00004C0.857117 7.55233 1.30483 8.00004 1.85712 8.00004H5.99969V12.1429C5.99969 12.6952 6.4474 13.1429 6.99969 13.1429C7.55197 13.1429 7.99969 12.6952 7.99969 12.1429V8.00004H12.1429C12.6952 8.00004 13.1429 7.55233 13.1429 7.00004C13.1429 6.44776 12.6952 6.00004 12.1429 6.00004H7.99969V1.85706Z'
    />
  </svg>
);

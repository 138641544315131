import type { SVGProps } from 'react';

export interface EdgeShapeProps extends SVGProps<SVGSVGElement> {
  size?: string;
}

const EdgeShape = ({ className, size, ...props }: EdgeShapeProps) => {
  if (size === 'sm') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='23'
        viewBox='0 0 12 23'
        className={className}
        {...props}
      >
        <path d='M12 0H11.5C10.7043 0 9.94128 0.31607 9.37868 0.878678L0.878682 9.37866C-0.292893 10.5502 -0.292895 12.4497 0.878681 13.6213L9.37867 22.1213C9.94128 22.6839 10.7043 23 11.5 23H12V0Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='46'
      viewBox='0 0 24 46'
      fill='none'
      className={className}
      {...props}
    >
      <path d='M24 0H23C21.4087 0 19.8826 0.63214 18.7574 1.75736L1.75736 18.7573C-0.585786 21.1005 -0.585789 24.8995 1.75736 27.2426L18.7573 44.2426C19.8826 45.3678 21.4087 45.9999 23 45.9999H24V0Z' />
    </svg>
  );
};

export default EdgeShape;

import type { ComponentProps } from 'react';

export const CrossIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.91591 11.3282C10.3064 11.7187 10.9396 11.7187 11.3301 11.3282C11.7206 10.9377 11.7206 10.3045 11.3301 9.91399L7.30188 5.88574L11.3301 1.8575C11.7206 1.46697 11.7206 0.833808 11.3301 0.443284C10.9396 0.0527594 10.3064 0.0527597 9.91591 0.443284L5.88767 4.47153L1.85817 0.442031C1.46764 0.0515061 0.83448 0.0515061 0.443956 0.44203C0.0534316 0.832555 0.0534316 1.46572 0.443956 1.85624L4.47345 5.88574L0.443955 9.91524C0.0534309 10.3058 0.0534309 10.9389 0.443955 11.3295C0.834479 11.72 1.46764 11.72 1.85817 11.3295L5.88767 7.29996L9.91591 11.3282Z'
    />
  </svg>
);

import { WARNING_LEVEL } from 'constants/tokenSafety';

export const useTokenWarningTextColor = (level: WARNING_LEVEL) => {
  switch (level) {
    case WARNING_LEVEL.MEDIUM:
      return 'text-yellow';
    case WARNING_LEVEL.UNKNOWN:
      return 'text-red';
    case WARNING_LEVEL.BLOCKED:
      return 'text-gray-600';
  }
};

export const useTokenWarningColor = (level: WARNING_LEVEL) => {
  switch (level) {
    case WARNING_LEVEL.MEDIUM:
    case WARNING_LEVEL.BLOCKED:
      return 'bg-yellow/40';
    case WARNING_LEVEL.UNKNOWN:
      return 'bg-red/40';
  }
};

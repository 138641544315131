import { type ChainConfig, NetworkType } from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';
import {
  BRIDGE_SUPPORTED_CHAINS,
  CHAIN_IDS_TO_CHAIN,
  MY_POSITION_SUPPORTED_CHAINS,
  STAKE_SUPPORTED_CHAINS,
  type SupportedChainId,
  type SupportedL2ChainId,
  TESTNET_CHAIN_IDS,
  UI_SUPPORTED_CHAINS
} from 'constants/chains';
import {
  USDC_MAINNET,
  USDC_OASIS_SAPPHIRE_MAINNET,
  USDC_OASIS_SAPPHIRE_TESTNET,
  USDC_SEPOLIA
} from 'constants/tokens';

import { useAtomValue } from 'jotai/utils';

import { showTestnetsAtom } from 'components/AccountDrawer/TestnetsToggle';

export const isL2ChainId = (
  chainId: number | undefined
): chainId is SupportedL2ChainId => {
  const chainInfo = getChainInfo(chainId);
  return chainInfo?.networkType === NetworkType.L2;
};

export const isSupportedChain = (
  chainId: number | null | undefined | ChainId
): chainId is SupportedChainId =>
  !!chainId && UI_SUPPORTED_CHAINS.indexOf(chainId) !== -1;

export const asSupportedChain = (
  chainId: number | null | undefined | ChainId
): SupportedChainId | undefined => {
  if (!chainId) {
    return undefined;
  }

  return isSupportedChain(chainId) ? chainId : undefined;
};

export const isTestnetChain = (chainId?: number | ChainId): boolean => {
  if (!chainId) {
    return false;
  }

  return TESTNET_CHAIN_IDS.includes(chainId);
};

export const isBridgeSupportedOnChain = (chainId: ChainId) =>
  BRIDGE_SUPPORTED_CHAINS.includes(chainId);

export const useBridgeSupportedChains = () => {
  const showTestnets = useAtomValue(showTestnetsAtom);

  return BRIDGE_SUPPORTED_CHAINS.filter(
    (chainId) => showTestnets || !isTestnetChain(chainId)
  );
};

export const isMyPositionSupportedChain = (chainId?: ChainId) => {
  if (!chainId) {
    return false;
  }

  return MY_POSITION_SUPPORTED_CHAINS.includes(chainId);
};

export const isStakeSupportedChain = (chainId?: ChainId) => {
  if (!chainId) {
    return false;
  }

  return STAKE_SUPPORTED_CHAINS.includes(chainId);
};

/**
 * Get the priority of a chainId based on its relevance to the user.
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export const getChainPriority = (chainId: ChainId): number => {
  switch (chainId) {
    case ChainId.OASIS_SAPPHIRE_MAINNET:
    case ChainId.OASIS_SAPPHIRE_TESTNET:
      return 0;
    case ChainId.MAINNET:
    case ChainId.SEPOLIA:
      return 1;
    default:
      return 2;
  }
};

export const getChainFromChainId = (
  chainId?: ChainId
): ChainConfig | undefined => {
  if (!chainId) {
    return undefined;
  }

  const isSupported = isSupportedChain(chainId);
  if (!isSupported) {
    return undefined;
  }

  const chain: ChainConfig | undefined = CHAIN_IDS_TO_CHAIN[chainId];
  return chain;
};

export const getUSDCFromChainId = (chainId?: ChainId) => {
  if (!chainId) {
    return undefined;
  }

  switch (chainId) {
    case ChainId.OASIS_SAPPHIRE_MAINNET:
      return USDC_OASIS_SAPPHIRE_MAINNET;
    case ChainId.OASIS_SAPPHIRE_TESTNET:
      return USDC_OASIS_SAPPHIRE_TESTNET;
    case ChainId.MAINNET:
      return USDC_MAINNET;
    case ChainId.SEPOLIA:
      return USDC_SEPOLIA;
    default:
      return undefined;
  }
};
